import { useState, useEffect, useRef } from 'react';
import { collection, query, orderBy, limit, getDocs, where } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { StoryViewer } from '../components/StoryViewer';
import { CreateStory } from '../components/CreateStory';
import { Loader2, LogOut, Eye } from 'lucide-react';
import { auth } from '../lib/firebase';

interface Story {
  id: string;
  mediaUrl: string;
  mediaType: 'image' | 'video';
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL: string;
  timestamp: Date;
  views: string[];
}

const STORIES_LIMIT = 8; // Limit to 8 recent stories
const PREVIEW_DURATION = 0.1; // 0.1 seconds preview duration

export function Stories() {
  const [stories, setStories] = useState<Story[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedStoryIndex, setSelectedStoryIndex] = useState<number | null>(null);
  const { user } = useAuth();
  const videoPreviewRefs = useRef<{ [key: string]: HTMLVideoElement }>({});
  const previewTimersRef = useRef<{ [key: string]: number }>({});

  useEffect(() => {
    const fetchStories = async () => {
      try {
        const twentyFourHoursAgo = new Date();
        twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);

        const storiesRef = collection(db, 'stories');
        const q = query(
          storiesRef,
          where('timestamp', '>', twentyFourHoursAgo),
          orderBy('timestamp', 'desc'),
          limit(STORIES_LIMIT)
        );

        const snapshot = await getDocs(q);
        const storiesData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate() || new Date()
        })) as Story[];

        setStories(storiesData);
      } catch (error) {
        console.error('Error fetching stories:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStories();
  }, []);

  // Handle video preview loading with optimization
  useEffect(() => {
    const loadVideoPreview = (video: HTMLVideoElement, storyId: string) => {
      video.preload = 'metadata';
      video.playsInline = true;
      video.muted = true;
      video.currentTime = 0;

      const playPreview = async () => {
        try {
          await video.play();
          
          // Stop after PREVIEW_DURATION seconds
          previewTimersRef.current[storyId] = window.setTimeout(() => {
            video.pause();
            video.currentTime = 0;
          }, PREVIEW_DURATION * 1000);
        } catch (error) {
          console.error('Error playing preview:', error);
        }
      };

      // Play preview when video metadata is loaded
      video.addEventListener('loadedmetadata', () => {
        if (video.videoHeight > 480) {
          video.style.objectFit = 'cover';
          video.style.height = '100%';
          video.style.width = '100%';
        }
        playPreview();
      });

      // Reset preview when video ends
      video.addEventListener('ended', () => {
        video.currentTime = 0;
      });

      // Load the video
      video.load();
    };

    stories.forEach(story => {
      if (story.mediaType === 'video' && videoPreviewRefs.current[story.id]) {
        loadVideoPreview(videoPreviewRefs.current[story.id], story.id);
      }
    });

    // Cleanup function
    return () => {
      // Clear all preview timers
      Object.values(previewTimersRef.current).forEach(timerId => {
        clearTimeout(timerId);
      });

      // Clean up video elements
      Object.values(videoPreviewRefs.current).forEach(video => {
        video.pause();
        video.src = '';
        video.load();
      });
    };
  }, [stories]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <h1 className="text-xl font-bold">Poosting</h1>
          <button
            onClick={() => auth.signOut()}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <LogOut className="h-5 w-5" />
          </button>
        </div>
      </header>

      <div className="max-w-2xl mx-auto bg-white min-h-screen">
        {selectedStoryIndex !== null ? (
          <StoryViewer
            stories={stories}
            initialIndex={selectedStoryIndex}
            onClose={() => setSelectedStoryIndex(null)}
          />
        ) : (
          <div className="p-4">
            <CreateStory onStoryCreated={(newStory) => {
              // Add new story and maintain limit
              setStories(prev => [newStory, ...prev].slice(0, STORIES_LIMIT));
            }} />
            
            <div className="mt-6 mb-16">
              <h2 className="text-lg font-semibold mb-4">Recent Stories</h2>
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
                {stories.length > 0 ? (
                  stories.map((story, index) => (
                    <button
                      key={story.id}
                      onClick={() => setSelectedStoryIndex(index)}
                      className="relative aspect-[9/16] rounded-lg overflow-hidden focus:outline-none group"
                    >
                      {story.mediaType === 'image' ? (
                        <img
                          src={story.mediaUrl}
                          alt={`Story by ${story.authorName}`}
                          className="absolute inset-0 w-full h-full object-cover"
                          loading="lazy"
                        />
                      ) : (
                        <video
                          ref={el => {
                            if (el) videoPreviewRefs.current[story.id] = el;
                          }}
                          src={story.mediaUrl}
                          className="absolute inset-0 w-full h-full object-cover"
                          muted
                          playsInline
                          preload="metadata"
                        />
                      )}
                      <div className="absolute inset-0 bg-gradient-to-b from-black/20 to-black/60 group-hover:from-black/30 group-hover:to-black/70 transition-colors" />
                      <div className="absolute bottom-2 left-2 right-2">
                        <div className="flex items-center space-x-2">
                          <img
                            src={story.authorPhotoURL}
                            alt={story.authorName}
                            className="w-6 h-6 rounded-full border border-white"
                          />
                          <div className="flex-1 min-w-0">
                            <p className="text-white text-sm font-medium truncate">
                              {story.authorName}
                            </p>
                            <p className="text-white/80 text-xs truncate">
                              {formatTimeAgo(story.timestamp)}
                            </p>
                          </div>
                        </div>
                        <div className="mt-1 flex items-center space-x-2">
                          <div className="flex items-center space-x-1">
                            <Eye className="h-3 w-3 text-white/80" />
                            <span className="text-xs text-white/80">
                              {story.views.length}
                            </span>
                          </div>
                          <span className="text-xs text-white/80">•</span>
                          <span className="text-xs text-white/80">
                            {story.mediaType === 'image' ? 'Photo' : 'Video'}
                          </span>
                        </div>
                      </div>
                    </button>
                  ))
                ) : (
                  <div className="col-span-full text-center py-8 text-gray-500">
                    <p className="text-lg">No stories yet</p>
                    <p className="text-sm mt-2">Be the first to share a story!</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function formatTimeAgo(date: Date): string {
  const now = new Date();
  const diffInHours = Math.floor((now.getTime() - date.getTime()) / (1000 * 60 * 60));
  
  if (diffInHours === 0) {
    const diffInMinutes = Math.floor((now.getTime() - date.getTime()) / (1000 * 60));
    return `${diffInMinutes}m ago`;
  }
  
  return `${diffInHours}h ago`;
}