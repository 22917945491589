import { X, Star, MapPin, Phone, Globe, Navigation, Clock } from 'lucide-react';

interface PlaceDetailsProps {
  place: google.maps.places.PlaceResult;
  onClose: () => void;
}

export function PlaceDetails({ place, onClose }: PlaceDetailsProps) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="sticky top-0 bg-white p-4 border-b flex justify-between items-center">
          <h2 className="text-xl font-semibold">{place.name}</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="p-4">
          {place.photos && place.photos[0] && (
            <div className="mb-6">
              <img
                src={place.photos[0].getUrl()}
                alt={place.name}
                className="w-full h-64 object-cover rounded-lg"
              />
            </div>
          )}

          <div className="space-y-4">
            {place.rating && (
              <div className="flex items-center text-yellow-500">
                <Star className="h-5 w-5 fill-current mr-2" />
                <span className="text-lg">{place.rating}</span>
                <span className="text-gray-600 ml-2">
                  ({place.user_ratings_total} reviews)
                </span>
              </div>
            )}

            {place.formatted_address && (
              <div className="flex items-center text-gray-700">
                <MapPin className="h-5 w-5 mr-2" />
                <span>{place.formatted_address}</span>
              </div>
            )}

            {place.formatted_phone_number && (
              <div className="flex items-center text-gray-700">
                <Phone className="h-5 w-5 mr-2" />
                <a 
                  href={`tel:${place.formatted_phone_number}`}
                  className="hover:text-blue-500"
                >
                  {place.formatted_phone_number}
                </a>
              </div>
            )}

            {place.website && (
              <div className="flex items-center text-gray-700">
                <Globe className="h-5 w-5 mr-2" />
                <a
                  href={place.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  Visit Website
                </a>
              </div>
            )}

            {place.geometry?.location && (
              <div className="flex items-center text-gray-700">
                <Navigation className="h-5 w-5 mr-2" />
                <a
                  href={`https://www.google.com/maps/dir/?api=1&destination=${place.geometry.location.lat()},${place.geometry.location.lng()}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  Get Directions
                </a>
              </div>
            )}

            {place.opening_hours && (
              <div className="mt-6">
                <div className="flex items-center text-gray-700 mb-2">
                  <Clock className="h-5 w-5 mr-2" />
                  <span className="font-medium">Opening Hours</span>
                </div>
                <ul className="space-y-1 text-gray-600">
                  {place.opening_hours.weekday_text?.map((hours, index) => (
                    <li key={index} className="text-sm">{hours}</li>
                  ))}
                </ul>
              </div>
            )}

            {place.reviews && (
              <div className="mt-6">
                <h3 className="text-lg font-semibold mb-4">Reviews</h3>
                <div className="space-y-4">
                  {place.reviews.map((review, index) => (
                    <div key={index} className="border-b pb-4">
                      <div className="flex items-center mb-2">
                        <img
                          src={review.profile_photo_url}
                          alt={review.author_name}
                          className="w-8 h-8 rounded-full mr-2"
                        />
                        <div>
                          <p className="font-medium">{review.author_name}</p>
                          <div className="flex items-center">
                            <Star className="h-4 w-4 text-yellow-500 fill-current" />
                            <span className="text-sm text-gray-600 ml-1">
                              {review.rating} • {new Date(review.time * 1000).toLocaleDateString()}
                            </span>
                          </div>
                        </div>
                      </div>
                      <p className="text-gray-700 text-sm">{review.text}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}