import { useState, useEffect } from 'react';
import { auth, storage, db } from '../lib/firebase';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  fetchSignInMethodsForEmail,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence
} from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, query, where, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { Loader2 } from 'lucide-react';
import toast from 'react-hot-toast';
import { debounce } from 'lodash';
import { ImageUpload } from '../components/ImageUpload';
import { AuthInput } from '../components/AuthInput';
import { isValidEmail, isValidUsername, isValidPassword } from '../utils/validation';

export function Auth() {
  const [isLogin, setIsLogin] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('@');
  const [bio, setBio] = useState('');
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [isCheckingUsername, setIsCheckingUsername] = useState(false);
  const [isUsernameAvailable, setIsUsernameAvailable] = useState<boolean | null>(null);
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);
  const [isEmailAvailable, setIsEmailAvailable] = useState<boolean | null>(null);
  const [rememberMe, setRememberMe] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isAdult, setIsAdult] = useState(false);
  const navigate = useNavigate();

  const handleUsernameChange = (value: string) => {
    if (!value.startsWith('@')) {
      setUsername('@' + value);
    } else {
      setUsername(value);
    }
  };

  const handleImageSelect = (file: File) => {
    setProfileImage(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const checkUsername = async (username: string) => {
    if (!username || !isValidUsername(username.slice(1))) {
      setIsUsernameAvailable(null);
      return;
    }

    setIsCheckingUsername(true);
    try {
      const cleanUsername = username.slice(1).toLowerCase();
      const q = query(
        collection(db, 'users'),
        where('username', '==', cleanUsername)
      );
      const snapshot = await getDocs(q);
      setIsUsernameAvailable(snapshot.empty);
    } catch (error) {
      console.error('Error checking username:', error);
      setIsUsernameAvailable(false);
    } finally {
      setIsCheckingUsername(false);
    }
  };

  const checkEmail = async (email: string) => {
    if (!email || !isValidEmail(email)) {
      setIsEmailAvailable(null);
      return;
    }

    setIsCheckingEmail(true);
    try {
      const methods = await fetchSignInMethodsForEmail(auth, email);
      setIsEmailAvailable(methods.length === 0);
    } catch (error) {
      console.error('Error checking email:', error);
      setIsEmailAvailable(false);
    } finally {
      setIsCheckingEmail(false);
    }
  };

  const debouncedCheckUsername = debounce(checkUsername, 500);
  const debouncedCheckEmail = debounce(checkEmail, 500);

  useEffect(() => {
    if (!isLogin && username.length > 1) {
      debouncedCheckUsername(username);
    }
    return () => debouncedCheckUsername.cancel();
  }, [username, isLogin]);

  useEffect(() => {
    if (!isLogin && email) {
      debouncedCheckEmail(email);
    }
    return () => debouncedCheckEmail.cancel();
  }, [email, isLogin]);

  const validateForm = () => {
    if (!isLogin) {
      if (!name.trim()) {
        toast.error('Please enter your name');
        return false;
      }
      if (!isValidUsername(username.slice(1))) {
        toast.error('Username must be 3-20 characters and contain only letters, numbers, and underscores');
        return false;
      }
      if (!isUsernameAvailable) {
        toast.error('Username is not available');
        return false;
      }
      if (!termsAccepted) {
        toast.error('Please accept the terms of use and cookie policy');
        return false;
      }
      if (!isAdult) {
        toast.error('You must be 18 years or older to create an account');
        return false;
      }
      if (!profileImage) {
        toast.error('Please upload a profile photo');
        return false;
      }
    }
    
    if (!isValidEmail(email)) {
      toast.error('Please enter a valid email address');
      return false;
    }
    
    if (!isLogin && !isEmailAvailable) {
      toast.error('Email is already in use');
      return false;
    }
    
    if (!isValidPassword(password)) {
      toast.error('Password must be at least 6 characters long');
      return false;
    }

    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setLoading(true);
    try {
      await setPersistence(auth, rememberMe ? browserLocalPersistence : browserSessionPersistence);

      if (isLogin) {
        await signInWithEmailAndPassword(auth, email, password);
        toast.success('Welcome back!');
        navigate('/');
      } else {
        const { user } = await createUserWithEmailAndPassword(auth, email, password);
        
        let photoURL = '';
        
        if (profileImage) {
          const storageRef = ref(storage, `avatars/${user.uid}`);
          await uploadBytes(storageRef, profileImage);
          photoURL = await getDownloadURL(storageRef);
        }

        const cleanUsername = username.slice(1);

        await addDoc(collection(db, 'users'), {
          uid: user.uid,
          name: name.trim(),
          username: cleanUsername.toLowerCase(),
          displayName: cleanUsername,
          photoURL: photoURL,
          bio: bio.trim(),
          createdAt: serverTimestamp(),
          allies: [],
          likes: [],
          stars: [],
          trash: [],
          coins: 10, // Initial coins for new users
          previousAlliesCount: 0
        });

        await updateProfile(user, {
          displayName: username,
          photoURL,
        });

        toast.success('Account created successfully!');
        navigate('/');
      }
    } catch (error: any) {
      console.error('Auth error:', error);
      toast.error(error.message || 'Authentication failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h1 className="text-center text-4xl font-bold text-black">Poosting</h1>
          <p className="mt-2 text-center text-lg text-gray-600">
            Post Anonymous or not for World!
          </p>
          <h2 className="mt-4 text-center text-xl text-gray-900">
            {isLogin ? 'Sign in to your account' : 'Create a new account'}
          </h2>
        </div>

        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div className="rounded-md shadow-sm -space-y-px">
            {!isLogin && (
              <>
                <div className="text-center mb-6">
                  <p className="text-sm font-medium text-gray-700 mb-2">Profile Photo</p>
                  <ImageUpload
                    imagePreview={imagePreview}
                    onImageSelect={handleImageSelect}
                  />
                </div>
                <AuthInput
                  id="name"
                  type="text"
                  label="Name"
                  value={name}
                  onChange={setName}
                  placeholder="Full name"
                />
                <div>
                  <p className="text-sm font-medium text-gray-700 mb-2">Username (This will be your unique identifier)</p>
                  <AuthInput
                    id="username"
                    type="text"
                    label="Username"
                    value={username}
                    onChange={handleUsernameChange}
                    placeholder="@username"
                    isChecking={isCheckingUsername}
                    isValid={isUsernameAvailable}
                  />
                </div>
                <AuthInput
                  id="bio"
                  type="text"
                  label="Bio"
                  value={bio}
                  onChange={setBio}
                  placeholder="Tell us about yourself"
                  maxLength={120}
                  isTextarea={true}
                />
              </>
            )}
            <AuthInput
              id="email"
              type="email"
              label="Email address"
              value={email}
              onChange={setEmail}
              placeholder="Email address"
              isChecking={!isLogin && isCheckingEmail}
              isValid={!isLogin ? isEmailAvailable : null}
            />
            <AuthInput
              id="password"
              type="password"
              label="Password"
              value={password}
              onChange={setPassword}
              placeholder="Password"
            />
          </div>

          {!isLogin && (
            <div className="space-y-4">
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="age-verification"
                    name="age-verification"
                    type="checkbox"
                    checked={isAdult}
                    onChange={(e) => setIsAdult(e.target.checked)}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded cursor-pointer"
                    required
                  />
                </div>
                <div className="ml-3">
                  <label htmlFor="age-verification" className="text-sm text-gray-600">
                    I have more +18 years
                  </label>
                </div>
              </div>

              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="terms"
                    name="terms"
                    type="checkbox"
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded cursor-pointer"
                    required
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="terms" className="text-gray-600">
                    I agree with Poosting's{' '}
                    <a
                      href="http://poosting.webnginx15f01.uni5.net/docs/poosting-terms-of-use.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-500"
                    >
                      terms of use
                    </a>
                    {' '}and{' '}
                    <a
                      href="http://poosting.webnginx15f01.uni5.net/docs/poosting-cookie-policy.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-500"
                    >
                      cookie policy
                    </a>
                  </label>
                </div>
              </div>
            </div>
          )}

          {isLogin && (
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded cursor-pointer"
              />
              <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900 cursor-pointer">
                Remember me
              </label>
            </div>
          )}

          <div>
            <button
              type="submit"
              disabled={loading}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? (
                <Loader2 className="h-5 w-5 animate-spin" />
              ) : (
                isLogin ? 'Sign in' : 'Sign up'
              )}
            </button>
          </div>

          <div className="text-center space-y-2">
            <button
              type="button"
              onClick={() => setIsLogin(!isLogin)}
              className="text-base font-medium text-blue-600 hover:text-blue-500"
            >
              {isLogin
                ? "Don't have an account? Sign up"
                : 'Already have an account? Sign in'}
            </button>
            {isLogin && (
              <p className="text-xs text-gray-500">
                Created by CEO - Afonso Alcântara
              </p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}