import { useState, useEffect } from 'react';
import { collection, query, where, orderBy, limit, startAfter, getDocs, onSnapshot, updateDoc, doc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { Loader2, LogOut, Bell } from 'lucide-react';
import { auth } from '../lib/firebase';
import { Link } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { MainMenu } from '../components/MainMenu';
import toast from 'react-hot-toast';

interface Notification {
  id: string;
  type: 'like' | 'star' | 'trash' | 'comment' | 'new_ally' | 'ally_removed' | 'profile_like' | 'profile_star' | 'profile_trash';
  postId?: string;
  recipientId: string;
  senderId: string;
  senderName: string;
  senderUsername: string;
  senderPhotoURL?: string;
  timestamp: Date;
  read: boolean;
}

const NOTIFICATIONS_PER_PAGE = 10;

export function Notifications() {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [lastVisible, setLastVisible] = useState<any>(null);
  const { user } = useAuth();

  useEffect(() => {
    if (!user) return;

    const q = query(
      collection(db, 'notifications'),
      where('recipientId', '==', user.uid),
      orderBy('timestamp', 'desc'),
      limit(NOTIFICATIONS_PER_PAGE)
    );

    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const notifs = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate() || new Date(),
      })) as Notification[];

      setNotifications(notifs);
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      setHasMore(snapshot.docs.length === NOTIFICATIONS_PER_PAGE);
      setLoading(false);

      // Mark all as read
      const unreadDocs = snapshot.docs.filter(doc => !doc.data().read);
      await Promise.all(
        unreadDocs.map(doc => 
          updateDoc(doc.ref, { read: true })
        )
      );
    });

    return () => unsubscribe();
  }, [user]);

  const loadMore = async () => {
    if (!user || !lastVisible || loadingMore) return;

    try {
      setLoadingMore(true);

      const q = query(
        collection(db, 'notifications'),
        where('recipientId', '==', user.uid),
        orderBy('timestamp', 'desc'),
        startAfter(lastVisible),
        limit(NOTIFICATIONS_PER_PAGE)
      );

      const snapshot = await getDocs(q);
      const newNotifications = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate() || new Date(),
      })) as Notification[];

      setNotifications(prev => [...prev, ...newNotifications]);
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
      setHasMore(snapshot.docs.length === NOTIFICATIONS_PER_PAGE);

      // Mark new notifications as read
      await Promise.all(
        snapshot.docs.filter(doc => !doc.data().read)
          .map(doc => updateDoc(doc.ref, { read: true }))
      );
    } catch (error) {
      console.error('Error loading more notifications:', error);
      toast.error('Failed to load more notifications');
    } finally {
      setLoadingMore(false);
    }
  };

  const getNotificationText = (notification: Notification) => {
    switch (notification.type) {
      case 'like':
        return 'liked your post';
      case 'star':
        return 'starred your post';
      case 'trash':
        return 'marked your post as trash';
      case 'comment':
        return 'commented on your post';
      case 'new_ally':
        return 'became your ally';
      case 'ally_removed':
        return 'is no longer your ally';
      case 'profile_like':
        return 'liked your profile';
      case 'profile_star':
        return 'starred your profile';
      case 'profile_trash':
        return 'marked your profile as trash';
      default:
        return 'interacted with your content';
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50">
        <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
          <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
            <h1 className="text-xl font-bold">Poosting</h1>
            <button
              onClick={() => auth.signOut()}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <LogOut className="h-5 w-5" />
            </button>
          </div>
          <MainMenu />
        </header>
        <div className="flex justify-center items-center p-8">
          <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <h1 className="text-xl font-bold">Poosting</h1>
          <button
            onClick={() => auth.signOut()}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <LogOut className="h-5 w-5" />
          </button>
        </div>
        <MainMenu />
      </header>

      <main className="max-w-2xl mx-auto bg-white border-x border-gray-200 min-h-screen">
        {notifications.length === 0 ? (
          <div className="p-8 text-center text-gray-500">
            <Bell className="h-12 w-12 mx-auto mb-4 text-gray-400" />
            <p className="text-lg">No notifications yet</p>
            <p className="text-sm mt-2">When someone interacts with your content, you'll see it here.</p>
          </div>
        ) : (
          <div className="divide-y divide-gray-200">
            {notifications.map((notification) => (
              <Link
                key={notification.id}
                to={`/profile/${notification.senderUsername}`}
                className={`block p-4 hover:bg-gray-50 ${!notification.read ? 'bg-blue-50' : ''}`}
              >
                <div className="flex items-start space-x-3">
                  <img
                    src={notification.senderPhotoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${notification.senderId}`}
                    alt={notification.senderName}
                    className="h-10 w-10 rounded-full"
                  />
                  <div className="flex-1 min-w-0">
                    <div className="flex items-baseline">
                      <span className="font-medium text-gray-900">{notification.senderName}</span>
                    </div>
                    <p className="text-sm text-gray-600 mt-1">
                      {getNotificationText(notification)}
                    </p>
                    <p className="text-xs text-gray-500 mt-1">
                      {formatDistanceToNow(notification.timestamp, { addSuffix: true })}
                    </p>
                  </div>
                </div>
              </Link>
            ))}

            {hasMore && (
              <div className="p-4 flex justify-center">
                <button
                  onClick={loadMore}
                  disabled={loadingMore}
                  className="px-4 py-2 bg-black text-white rounded-full hover:bg-gray-800 disabled:opacity-50 flex items-center space-x-2"
                >
                  {loadingMore ? (
                    <>
                      <Loader2 className="h-4 w-4 animate-spin" />
                      <span>Loading...</span>
                    </>
                  ) : (
                    <span>Show More</span>
                  )}
                </button>
              </div>
            )}
          </div>
        )}
      </main>
    </div>
  );
}