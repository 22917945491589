import { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from '../components/AuthProvider';
import { onSnapshot, doc } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface PhotoContextType {
  userPhoto: string | null;
  updateUserPhoto: (photoURL: string) => void;
}

const PhotoContext = createContext<PhotoContextType | null>(null);

export function PhotoProvider({ children }: { children: React.ReactNode }) {
  const { user } = useAuth();
  const [userPhoto, setUserPhoto] = useState<string | null>(user?.photoURL || null);

  useEffect(() => {
    if (!user) {
      setUserPhoto(null);
      return;
    }

    // Listen for real-time updates to the user's photo
    const unsubscribe = onSnapshot(
      doc(db, 'users', user.uid),
      (doc) => {
        if (doc.exists()) {
          setUserPhoto(doc.data().photoURL || null);
        }
      },
      (error) => {
        console.error('Error listening to photo updates:', error);
      }
    );

    return () => unsubscribe();
  }, [user]);

  const updateUserPhoto = (photoURL: string) => {
    setUserPhoto(photoURL);
  };

  return (
    <PhotoContext.Provider value={{ userPhoto, updateUserPhoto }}>
      {children}
    </PhotoContext.Provider>
  );
}

export function usePhoto() {
  const context = useContext(PhotoContext);
  if (!context) {
    throw new Error('usePhoto must be used within a PhotoProvider');
  }
  return context;
}