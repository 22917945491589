// Chat support utility functions
export const openChatSupport = () => {
  const width = 700;
  const height = 900;
  const left = (window.screen.width - width) / 2;
  const top = (window.screen.height - height) / 2;

  const features = [
    `width=${width}`,
    `height=${height}`,
    `left=${left}`,
    `top=${top}`,
    'resizable=yes',
    'scrollbars=yes',
    'status=no',
    'location=no',
    'toolbar=no',
    'menubar=no'
  ].join(',');

  window.open(
    'https://wproo.com/ia/atendimento/index.html',
    'ChatSupport',
    features
  );
};