import { useEffect } from 'react';

export function RssCarousel() {
  useEffect(() => {
    // Load RSS widget script
    const script = document.createElement('script');
    script.src = 'https://widget.rss.app/v1/carousel.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup script when component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="w-full py-4 bg-white border-b border-gray-200">
      <div className="max-w-2xl mx-auto">
        <rssapp-carousel id="_Pctg7BGdikkgztMA"></rssapp-carousel>
      </div>
    </div>
  );
}