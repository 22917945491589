import { useState, useRef } from 'react';
import { Camera, Video, Upload, Loader2 } from 'lucide-react';
import { useAuth } from './AuthProvider';
import { db, storage, analyticsEvents } from '../lib/firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { optimizeVideo } from '../utils/video';
import toast from 'react-hot-toast';

interface CreateStoryProps {
  onStoryCreated: (story: any) => void;
}

export function CreateStory({ onStoryCreated }: CreateStoryProps) {
  const [uploading, setUploading] = useState(false);
  const { user } = useAuth();
  const photoInputRef = useRef<HTMLInputElement>(null);
  const videoInputRef = useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>, type: 'image' | 'video') => {
    const file = e.target.files?.[0];
    if (!file || !user) return;

    // Validate file type
    if (type === 'image' && !file.type.startsWith('image/')) {
      toast.error('Please select an image file');
      return;
    }

    if (type === 'video') {
      if (!file.type.startsWith('video/')) {
        toast.error('Please select a video file');
        return;
      }

      // Check video duration
      const video = document.createElement('video');
      video.preload = 'metadata';

      await new Promise((resolve, reject) => {
        video.onloadedmetadata = resolve;
        video.onerror = reject;
        video.src = URL.createObjectURL(file);
      });

      if (video.duration > 30) {
        toast.error('Video must be 30 seconds or less');
        URL.revokeObjectURL(video.src);
        return;
      }

      URL.revokeObjectURL(video.src);
    }

    try {
      setUploading(true);
      let processedFile = file;

      if (type === 'video') {
        const loadingToast = toast.loading('Optimizing video...');
        try {
          processedFile = await optimizeVideo(file);
          toast.dismiss(loadingToast);
        } catch (error) {
          console.error('Video optimization failed:', error);
          toast.dismiss(loadingToast);
          toast.error('Failed to optimize video, using original file');
        }
      }

      const storageRef = ref(storage, `stories/${user.uid}/${Date.now()}-${processedFile.name}`);
      await uploadBytes(storageRef, processedFile);
      const mediaUrl = await getDownloadURL(storageRef);

      const storyData = {
        mediaUrl,
        mediaType: type,
        authorId: user.uid,
        authorName: user.displayName || '',
        authorUsername: user.displayName?.startsWith('@') 
          ? user.displayName.slice(1) 
          : user.displayName || '',
        authorPhotoURL: user.photoURL,
        timestamp: serverTimestamp(),
        views: []
      };

      const docRef = await addDoc(collection(db, 'stories'), storyData);

      onStoryCreated({ 
        id: docRef.id, 
        ...storyData,
        timestamp: new Date() 
      });
      
      toast.success('Story created successfully!');
      analyticsEvents.createStory();
    } catch (error) {
      console.error('Error uploading story:', error);
      toast.error('Failed to create story. Please try again.');
    } finally {
      setUploading(false);
      if (photoInputRef.current) photoInputRef.current.value = '';
      if (videoInputRef.current) videoInputRef.current.value = '';
      if (fileInputRef.current) fileInputRef.current.value = '';
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex space-x-4">
        <button
          onClick={() => photoInputRef.current?.click()}
          disabled={uploading}
          className="flex items-center space-x-2 px-3 py-1.5 bg-black text-white text-xs rounded-full hover:bg-gray-900 disabled:opacity-50"
        >
          <Camera className="h-4 w-4" />
          <span>Photo</span>
        </button>
        
        <button
          onClick={() => videoInputRef.current?.click()}
          disabled={uploading}
          className="flex items-center space-x-2 px-3 py-1.5 bg-black text-white text-xs rounded-full hover:bg-gray-900 disabled:opacity-50"
        >
          <Video className="h-4 w-4" />
          <span>Video 30s</span>
        </button>

        <button
          onClick={() => fileInputRef.current?.click()}
          disabled={uploading}
          className="flex items-center space-x-2 px-3 py-1.5 bg-black text-white text-xs rounded-full hover:bg-gray-900 disabled:opacity-50"
        >
          <Upload className="h-4 w-4" />
          <span>Upload</span>
        </button>

        <input
          ref={photoInputRef}
          type="file"
          accept="image/*"
          capture="environment"
          onChange={(e) => handleFileSelect(e, 'image')}
          className="hidden"
        />
        <input
          ref={videoInputRef}
          type="file"
          accept="video/*"
          capture="environment"
          onChange={(e) => handleFileSelect(e, 'video')}
          className="hidden"
        />
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*,video/*"
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file?.type.startsWith('image/')) {
              handleFileSelect(e, 'image');
            } else if (file?.type.startsWith('video/')) {
              handleFileSelect(e, 'video');
            }
          }}
          className="hidden"
        />
      </div>
      {uploading && (
        <div className="flex items-center space-x-2">
          <Loader2 className="h-4 w-4 animate-spin text-blue-500" />
          <span className="text-sm text-blue-500">Processing...</span>
        </div>
      )}
    </div>
  );
}