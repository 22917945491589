import { useState, useRef, useEffect } from 'react';
import { X, Camera, Video, Square, RefreshCw, Loader2 } from 'lucide-react';
import toast from 'react-hot-toast';

interface WebcamCaptureProps {
  mode: 'photo' | 'video';
  onCapture: (file: File) => void;
  onClose: () => void;
  maxVideoDuration?: number;
}

export function WebcamCapture({ mode, onCapture, onClose, maxVideoDuration = 30 }: WebcamCaptureProps) {
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [timeLeft, setTimeLeft] = useState(maxVideoDuration);
  const [error, setError] = useState<string | null>(null);
  const [facingMode, setFacingMode] = useState<'user' | 'environment'>('user');
  const [switching, setSwitching] = useState(false);
  
  const videoRef = useRef<HTMLVideoElement>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chunksRef = useRef<Blob[]>([]);
  const timerRef = useRef<number | null>(null);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    startCamera();

    return () => {
      mounted.current = false;
      stopStream();
    };
  }, [facingMode]);

  const startCamera = async () => {
    try {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }

      const constraints = {
        video: {
          width: { ideal: 1920 },
          height: { ideal: 1080 },
          facingMode,
        },
        audio: mode === 'video'
      };

      const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
      
      if (!mounted.current) {
        mediaStream.getTracks().forEach(track => track.stop());
        return;
      }

      setStream(mediaStream);

      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
        try {
          await videoRef.current.play();
        } catch (err) {
          if (err.name !== 'AbortError' && mounted.current) {
            console.error('Error playing video:', err);
            setError('Could not start camera preview');
          }
        }
      }
    } catch (err) {
      console.error('Error accessing camera:', err);
      setError('Could not access camera or microphone');
    }
  };

  const stopStream = () => {
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };

  const capturePhoto = () => {
    if (!videoRef.current || !stream) return;

    const canvas = document.createElement('canvas');
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Flip horizontally if using front camera
    if (facingMode === 'user') {
      ctx.translate(canvas.width, 0);
      ctx.scale(-1, 1);
    }
    
    ctx.drawImage(videoRef.current, 0, 0);

    canvas.toBlob((blob) => {
      if (blob) {
        const file = new File([blob], `photo-${Date.now()}.jpg`, { type: 'image/jpeg' });
        onCapture(file);
      }
    }, 'image/jpeg', 0.95);
  };

  const startRecording = () => {
    if (!stream || !videoRef.current) return;

    chunksRef.current = [];
    const options = { mimeType: 'video/webm;codecs=vp8,opus' };
    
    try {
      mediaRecorderRef.current = new MediaRecorder(stream, options);
    } catch (e) {
      console.error('MediaRecorder error:', e);
      toast.error('Recording not supported in this browser');
      return;
    }

    mediaRecorderRef.current.ondataavailable = (e) => {
      if (e.data.size > 0) {
        chunksRef.current.push(e.data);
      }
    };

    mediaRecorderRef.current.onstop = () => {
      const blob = new Blob(chunksRef.current, { type: 'video/webm' });
      const file = new File([blob], `video-${Date.now()}.webm`, { type: 'video/webm' });
      onCapture(file);
    };

    mediaRecorderRef.current.start();
    setIsRecording(true);
    setTimeLeft(maxVideoDuration);

    timerRef.current = window.setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          stopRecording();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.stop();
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      setIsRecording(false);
      setTimeLeft(maxVideoDuration);
    }
  };

  const toggleCamera = async () => {
    if (isRecording) return;
    
    try {
      setSwitching(true);
      setFacingMode(prev => prev === 'user' ? 'environment' : 'user');
    } catch (err) {
      console.error('Error switching camera:', err);
      toast.error('Failed to switch camera');
    } finally {
      setSwitching(false);
    }
  };

  if (error) {
    return (
      <div className="fixed inset-0 bg-black z-50 flex flex-col items-center justify-center text-white p-4">
        <p className="text-xl mb-4">{error}</p>
        <button
          onClick={onClose}
          className="px-4 py-2 bg-white text-black rounded-full"
        >
          Close
        </button>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black z-50 flex flex-col">
      <div className="relative flex-1">
        <video
          ref={videoRef}
          autoPlay
          playsInline
          muted
          className={`absolute inset-0 w-full h-full object-cover ${
            facingMode === 'user' ? 'scale-x-[-1]' : ''
          }`}
        />
        
        {/* Countdown Timer for Video */}
        {mode === 'video' && isRecording && (
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-32 h-32 rounded-full bg-black bg-opacity-50 flex items-center justify-center">
              <span className="text-6xl font-bold text-white animate-pulse">
                {timeLeft}
              </span>
            </div>
          </div>
        )}
        
        <div className="absolute bottom-[100px] left-0 right-0">
          <div className="flex justify-center items-center space-x-8">
            <button
              onClick={onClose}
              className="p-4 bg-black/50 backdrop-blur-sm rounded-full text-white hover:bg-black/70 transition-colors"
              aria-label="Close camera"
            >
              <X className="h-8 w-8" />
            </button>
            
            <button
              onClick={mode === 'photo' ? capturePhoto : (isRecording ? stopRecording : startRecording)}
              className={`p-6 ${
                isRecording ? 'bg-red-500' : 'bg-white'
              } rounded-full ${
                isRecording ? 'text-white' : 'text-black'
              } hover:opacity-90 transition-colors`}
              aria-label={mode === 'photo' ? 'Take photo' : (isRecording ? 'Stop recording' : 'Start recording')}
            >
              {mode === 'photo' ? (
                <Camera className="h-10 w-10" />
              ) : isRecording ? (
                <Square className="h-10 w-10" />
              ) : (
                <Video className="h-10 w-10" />
              )}
            </button>

            <button
              onClick={toggleCamera}
              disabled={switching || isRecording}
              className="p-4 bg-black/50 backdrop-blur-sm rounded-full text-white hover:bg-black/70 transition-colors disabled:opacity-50"
              title="Switch camera"
            >
              {switching ? (
                <Loader2 className="h-8 w-8 animate-spin" />
              ) : (
                <RefreshCw className="h-8 w-8" />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}