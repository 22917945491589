import { useState, useEffect } from 'react';
import { LogOut, Trophy, Loader2 } from 'lucide-react';
import { auth } from '../lib/firebase';
import { MainMenu } from '../components/MainMenu';
import { RankingList } from '../components/ranking/RankingList';
import { RankingHeader } from '../components/ranking/RankingHeader';
import { useRankingData } from '../hooks/useRankingData';

export function Ranking() {
  const { rankings, loading, error } = useRankingData();

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <Trophy className="h-6 w-6 text-yellow-500" />
            <h1 className="text-xl font-bold">Poosting Ranking</h1>
          </div>
          <button
            onClick={() => auth.signOut()}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <LogOut className="h-5 w-5" />
          </button>
        </div>
        <MainMenu />
      </header>

      <main className="max-w-2xl mx-auto bg-white border-x border-gray-200 min-h-screen">
        <RankingHeader />
        
        {loading ? (
          <div className="flex justify-center items-center p-8">
            <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
          </div>
        ) : error ? (
          <div className="p-8 text-center text-red-500">
            {error}
          </div>
        ) : (
          <RankingList rankings={rankings} />
        )}
      </main>
    </div>
  );
}