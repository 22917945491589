import { Calendar } from 'lucide-react';

export function RankingHeader() {
  const startOfWeek = new Date();
  startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
  
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(endOfWeek.getDate() + 6);

  const formatDate = (date: Date) => {
    return date.toLocaleDateString('en-US', { 
      month: 'short', 
      day: 'numeric'
    });
  };

  return (
    <div className="p-4 bg-gradient-to-r from-yellow-500 to-yellow-600 text-white">
      <div className="flex items-center justify-between">
        <div>
          <h2 className="text-2xl font-bold mb-1">Weekly Top 100</h2>
          <div className="flex items-center text-yellow-100">
            <Calendar className="h-4 w-4 mr-2" />
            <span>
              {formatDate(startOfWeek)} - {formatDate(endOfWeek)}
            </span>
          </div>
        </div>
        <div className="text-right">
          <p className="text-sm text-yellow-100">Updated in real-time</p>
        </div>
      </div>
    </div>
  );
}