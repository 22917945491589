import { useState, useEffect } from 'react';
import { Search, X, Loader2, Youtube } from 'lucide-react';
import axios from 'axios';
import toast from 'react-hot-toast';

interface YoutubeSearchProps {
  onSelect: (video: {
    id: string;
    title: string;
    channelTitle: string;
    thumbnailUrl: string;
  }) => void;
  onClose: () => void;
}

export function YoutubeSearch({ onSelect, onClose }: YoutubeSearchProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [videos, setVideos] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const searchVideos = async () => {
      if (!searchQuery.trim()) {
        setVideos([]);
        return;
      }

      try {
        setLoading(true);
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/search`,
          {
            params: {
              part: 'snippet',
              maxResults: 10,
              q: searchQuery,
              type: 'video',
              key: 'AIzaSyBuJT7El4mSrV6GjcknUnKvHbjrsSXuyf8'
            }
          }
        );

        setVideos(response.data.items);
      } catch (error) {
        console.error('Error searching videos:', error);
        toast.error('Failed to search videos');
      } finally {
        setLoading(false);
      }
    };

    const debounceTimer = setTimeout(() => {
      if (searchQuery) {
        searchVideos();
      }
    }, 500);

    return () => clearTimeout(debounceTimer);
  }, [searchQuery]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-md">
        <div className="p-4 border-b flex justify-between items-center">
          <h2 className="text-lg font-semibold flex items-center">
            <Youtube className="h-5 w-5 mr-2 text-red-500" />
            Search Videos
          </h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="p-4">
          <div className="relative mb-4">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search for videos..."
              className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500"
              autoFocus
            />
          </div>

          {loading ? (
            <div className="flex justify-center py-8">
              <Loader2 className="h-8 w-8 animate-spin text-red-500" />
            </div>
          ) : videos.length > 0 ? (
            <div className="max-h-96 overflow-y-auto">
              {videos.map((video) => (
                <div
                  key={video.id.videoId}
                  className="flex items-center space-x-3 p-3 hover:bg-gray-50 rounded-lg transition-colors cursor-pointer"
                  onClick={() => onSelect({
                    id: video.id.videoId,
                    title: video.snippet.title,
                    channelTitle: video.snippet.channelTitle,
                    thumbnailUrl: video.snippet.thumbnails.high.url
                  })}
                >
                  <img
                    src={video.snippet.thumbnails.default.url}
                    alt={video.snippet.title}
                    className="w-24 h-16 object-cover rounded-lg"
                  />
                  <div className="flex-1 min-w-0">
                    <p className="font-medium text-sm line-clamp-2">
                      {video.snippet.title}
                    </p>
                    <p className="text-xs text-gray-500 mt-1">
                      {video.snippet.channelTitle}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : searchQuery ? (
            <p className="text-center text-gray-500 py-8">No videos found</p>
          ) : (
            <p className="text-center text-gray-500 py-8">
              Search for your favorite videos
            </p>
          )}
        </div>
      </div>
    </div>
  );
}