import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { doc, getDoc, collection, query, orderBy, getDocs, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { LogOut, Loader2, ArrowLeft, MessageSquarePlus } from 'lucide-react';
import { auth } from '../lib/firebase';
import { formatDistanceToNow } from 'date-fns';
import { BottomNav } from '../components/BottomNav';
import { CommunityParticipants } from '../components/CommunityParticipants';
import { CreateTopic } from '../components/CreateTopic';
import { TopicList } from '../components/TopicList';
import ReactMarkdown from 'react-markdown';
import toast from 'react-hot-toast';

interface Community {
  id: string;
  title: string;
  question: string;
  imageUrl?: string;
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL: string;
  timestamp: Date;
  participants: string[];
}

interface Topic {
  id: string;
  title: string;
  content: string;
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL: string;
  timestamp: Date;
  responseCount: number;
  lastResponseAt?: Date;
}

interface Participant {
  uid: string;
  name: string;
  username: string;
  photoURL: string;
  allies?: string[];
}

export function CommunityDetails() {
  const { id } = useParams<{ id: string }>();
  const [community, setCommunity] = useState<Community | null>(null);
  const [topics, setTopics] = useState<Topic[]>([]);
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [loading, setLoading] = useState(true);
  const [showCreateTopic, setShowCreateTopic] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    if (!id) return;

    // Set up real-time listener for community document
    const communityRef = doc(db, 'communities', id);
    const unsubscribeCommunity = onSnapshot(
      communityRef,
      async (docSnapshot) => {
        if (!docSnapshot.exists()) {
          toast.error('Community not found');
          return;
        }

        const communityData = {
          id: docSnapshot.id,
          ...docSnapshot.data(),
          participants: docSnapshot.data().participants || [],
          timestamp: docSnapshot.data().timestamp?.toDate()
        } as Community;

        setCommunity(communityData);

        // Fetch participants data whenever the participants list changes
        if (communityData.participants?.length) {
          const usersRef = collection(db, 'users');
          const participantsData = await Promise.all(
            communityData.participants.map(async (uid) => {
              try {
                const userDoc = await getDoc(doc(usersRef, uid));
                if (!userDoc.exists()) return null;
                
                const userData = userDoc.data();
                return {
                  uid,
                  name: userData.name || 'Unknown User',
                  username: userData.username || uid,
                  photoURL: userData.photoURL || null,
                  allies: userData.allies || []
                } as Participant;
              } catch (error) {
                console.error(`Error fetching participant ${uid}:`, error);
                return null;
              }
            })
          );

          setParticipants(participantsData.filter((p): p is Participant => p !== null));
        } else {
          setParticipants([]);
        }
      },
      (error) => {
        console.error('Error listening to community:', error);
        toast.error('Failed to load community updates');
      }
    );

    // Set up real-time listener for topics
    const topicsRef = collection(db, 'communities', id, 'topics');
    const topicsQuery = query(topicsRef, orderBy('lastResponseAt', 'desc'));
    
    const unsubscribeTopics = onSnapshot(
      topicsQuery,
      async (snapshot) => {
        const topicsData = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const data = doc.data();
            
            // Get response count
            const responsesRef = collection(db, 'communities', id, 'topics', doc.id, 'responses');
            const responsesSnapshot = await getDocs(responsesRef);
            
            return {
              id: doc.id,
              ...data,
              timestamp: data.timestamp?.toDate(),
              lastResponseAt: data.lastResponseAt?.toDate(),
              responseCount: responsesSnapshot.size
            } as Topic;
          })
        );

        setTopics(topicsData);
        setLoading(false);
      },
      (error) => {
        console.error('Error listening to topics:', error);
        toast.error('Failed to load topics updates');
        setLoading(false);
      }
    );

    // Cleanup listeners on unmount
    return () => {
      unsubscribeCommunity();
      unsubscribeTopics();
    };
  }, [id]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  if (!community) {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
        <h2 className="text-2xl font-bold mb-4">Community not found</h2>
        <Link
          to="/communities"
          className="flex items-center space-x-2 text-blue-500 hover:text-blue-600"
        >
          <ArrowLeft className="h-5 w-5" />
          <span>Back to Communities</span>
        </Link>
      </div>
    );
  }

  const isParticipant = user ? community.participants?.includes(user.uid) : false;

  return (
    <div className="min-h-screen bg-gray-50 pb-16">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <Link
            to="/communities"
            className="flex items-center space-x-2"
          >
            <ArrowLeft className="h-5 w-5" />
            <span className="font-medium">Back</span>
          </Link>
          <button
            onClick={() => auth.signOut()}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <LogOut className="h-5 w-5" />
          </button>
        </div>
      </header>

      <main className="max-w-2xl mx-auto bg-white border-x border-gray-200 min-h-screen">
        <div className="p-4 border-b border-gray-200">
          <div className="flex items-start space-x-4">
            {community.imageUrl && (
              <img
                src={community.imageUrl}
                alt={community.title}
                className="w-20 h-20 rounded-lg object-cover"
              />
            )}
            <div className="flex-1">
              <h1 className="text-2xl font-bold mb-2">{community.title}</h1>
              <ReactMarkdown className="prose max-w-none">
                {community.question}
              </ReactMarkdown>
            </div>
          </div>
        </div>

        <div className="p-4 border-b border-gray-200">
          <CommunityParticipants
            participants={participants}
            isParticipant={isParticipant}
            isRequesting={false}
            communityId={community.id}
            userId={user?.uid || ''}
          />
        </div>

        {isParticipant && (
          <div className="p-4 border-b border-gray-200">
            <button
              onClick={() => setShowCreateTopic(true)}
              className="w-full flex items-center justify-center space-x-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-900"
            >
              <MessageSquarePlus className="h-5 w-5" />
              <span>Create New Topic</span>
            </button>
          </div>
        )}

        <TopicList communityId={community.id} topics={topics} />

        {showCreateTopic && (
          <CreateTopic
            onClose={() => setShowCreateTopic(false)}
            onCreate={async (title: string, content: string) => {
              // Implementation remains the same
            }}
          />
        )}
      </main>

      <BottomNav />
    </div>
  );
}