import { useState, useEffect } from 'react';
import { collection, query, where, orderBy, onSnapshot, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { formatDistanceToNow } from 'date-fns';
import { Loader2, MessageCircle } from 'lucide-react';

interface ChatRoom {
  id: string;
  participants: string[];
  lastMessage?: string;
  lastMessageTimestamp?: Date;
  participantInfo?: { [key: string]: { name: string; username: string; photoURL: string } };
  unreadCount?: number;
}

interface ChatListProps {
  selectedChat: ChatRoom | null;
  onChatSelect: (chat: ChatRoom) => void;
}

export function ChatList({ selectedChat, onChatSelect }: ChatListProps) {
  const [chats, setChats] = useState<ChatRoom[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalUnread, setTotalUnread] = useState(0);
  const { user } = useAuth();

  useEffect(() => {
    if (!user) return;

    // Create a real-time listener for chats
    const chatsQuery = query(
      collection(db, 'chats'),
      where('participants', 'array-contains', user.uid)
    );

    const unsubscribe = onSnapshot(chatsQuery, async (snapshot) => {
      try {
        // Handle added/modified chats
        const chatPromises = snapshot.docs.map(async (doc) => {
          const chatData = doc.data();
          const chatRoom = {
            id: doc.id,
            ...chatData,
            lastMessageTimestamp: chatData.lastMessageTimestamp?.toDate(),
            participantInfo: {},
            unreadCount: 0
          } as ChatRoom;

          // Get unread messages count
          const messagesRef = collection(db, `chats/${doc.id}/messages`);
          const messagesSnapshot = await getDocs(messagesRef);
          const unreadCount = messagesSnapshot.docs.filter(doc => 
            !doc.data().readBy?.includes(user.uid)
          ).length;
          chatRoom.unreadCount = unreadCount;

          // Fetch participant information
          const otherParticipantId = chatRoom.participants.find(id => id !== user.uid);
          if (otherParticipantId) {
            const userRef = collection(db, 'users');
            const userQuery = query(userRef, where('uid', '==', otherParticipantId));
            const userSnapshot = await getDocs(userQuery);
            
            if (!userSnapshot.empty) {
              const userData = userSnapshot.docs[0].data();
              chatRoom.participantInfo = {
                [otherParticipantId]: {
                  name: userData.name || 'Unknown User',
                  username: userData.username || otherParticipantId,
                  photoURL: userData.photoURL || null
                }
              };
            }
          }

          return chatRoom;
        });

        const updatedChats = await Promise.all(chatPromises);

        // Calculate total unread messages
        const total = updatedChats.reduce((sum, chat) => sum + (chat.unreadCount || 0), 0);
        setTotalUnread(total);

        // Sort chats by last message timestamp
        updatedChats.sort((a, b) => {
          const timeA = a.lastMessageTimestamp?.getTime() || 0;
          const timeB = b.lastMessageTimestamp?.getTime() || 0;
          return timeB - timeA;
        });

        setChats(updatedChats);
        setLoading(false);

        // If selected chat was deleted, clear selection
        if (selectedChat && !updatedChats.find(chat => chat.id === selectedChat.id)) {
          onChatSelect(null);
        }
      } catch (error) {
        console.error('Error processing chat updates:', error);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [user, selectedChat]);

  if (loading) {
    return (
      <div className="w-64 border-r border-gray-200 p-4 flex justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="w-64 border-r border-gray-200 overflow-y-auto">
      <div className="p-4 border-b border-gray-200 bg-gray-50">
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-semibold">Messages</h2>
          {totalUnread > 0 && (
            <span className="bg-red-500 text-white text-xs rounded-full px-2 py-1 min-w-[1.5rem] text-center">
              {totalUnread}
            </span>
          )}
        </div>
      </div>

      {chats.length === 0 ? (
        <div className="p-4 text-center text-gray-500">
          <MessageCircle className="h-8 w-8 mx-auto mb-2 text-gray-400" />
          <p>No messages yet</p>
        </div>
      ) : (
        chats.map((chat) => {
          const otherParticipantId = chat.participants.find(id => id !== user?.uid);
          const participantInfo = otherParticipantId ? chat.participantInfo?.[otherParticipantId] : null;

          return (
            <button
              key={chat.id}
              onClick={() => onChatSelect(chat)}
              className={`w-full p-4 text-left hover:bg-gray-50 relative ${
                selectedChat?.id === chat.id ? 'bg-blue-50' : ''
              } ${chat.unreadCount ? 'bg-blue-50/50' : ''}`}
            >
              <div className="flex items-center space-x-3">
                <div className="relative">
                  <img
                    src={participantInfo?.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${otherParticipantId}`}
                    alt={participantInfo?.name}
                    className="w-10 h-10 rounded-full"
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.src = `https://api.dicebear.com/7.x/avataaars/svg?seed=${otherParticipantId}`;
                    }}
                  />
                  {chat.unreadCount > 0 && (
                    <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                      {chat.unreadCount}
                    </span>
                  )}
                </div>
                <div className="flex-1 min-w-0">
                  <p className="font-medium truncate">
                    @{participantInfo?.username || 'Unknown'}
                  </p>
                  {chat.lastMessage && (
                    <>
                      <p className="text-sm text-gray-500 truncate">
                        {chat.lastMessage}
                      </p>
                      {chat.lastMessageTimestamp && (
                        <p className="text-xs text-gray-400">
                          {formatDistanceToNow(chat.lastMessageTimestamp, { addSuffix: true })}
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </button>
          );
        })
      )}
    </div>
  );
}