import { useState, useEffect } from 'react';
import { collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from './AuthProvider';
import { StoryViewer } from './StoryViewer';
import { Plus, Loader2 } from 'lucide-react';
import { Link } from 'react-router-dom';

interface Story {
  id: string;
  mediaUrl: string;
  mediaType: 'image' | 'video';
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL: string;
  timestamp: Date;
  views: string[];
}

interface StoriesByUser {
  [userId: string]: {
    user: {
      id: string;
      name: string;
      username: string;
      photoURL: string;
    };
    stories: Story[];
  };
}

export function StoriesGallery() {
  const [storiesByUser, setStoriesByUser] = useState<StoriesByUser>({});
  const [loading, setLoading] = useState(true);
  const [selectedUserIndex, setSelectedUserIndex] = useState<number | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    const fetchStories = async () => {
      try {
        const twentyFourHoursAgo = new Date();
        twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);

        const storiesRef = collection(db, 'stories');
        const q = query(
          storiesRef,
          where('timestamp', '>', twentyFourHoursAgo),
          orderBy('timestamp', 'desc'),
          limit(100)
        );

        const snapshot = await getDocs(q);
        const stories = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate() || new Date()
        })) as Story[];

        // Group stories by user
        const grouped = stories.reduce((acc: StoriesByUser, story) => {
          if (!acc[story.authorId]) {
            acc[story.authorId] = {
              user: {
                id: story.authorId,
                name: story.authorName,
                username: story.authorUsername,
                photoURL: story.authorPhotoURL
              },
              stories: []
            };
          }
          acc[story.authorId].stories.push(story);
          return acc;
        }, {});

        setStoriesByUser(grouped);
      } catch (error) {
        console.error('Error fetching stories:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStories();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center p-4">
        <Loader2 className="h-6 w-6 animate-spin text-blue-500" />
      </div>
    );
  }

  const userIds = Object.keys(storiesByUser);

  if (userIds.length === 0) {
    return null;
  }

  const allStories = userIds.flatMap(userId => storiesByUser[userId].stories);

  return (
    <div className="border-b border-gray-200">
      <div className="max-w-2xl mx-auto px-4 py-4">
        <div className="flex space-x-4 overflow-x-auto pb-2 scrollbar-hide">
          <Link
            to="/stories"
            className="flex flex-col items-center space-y-1 flex-shrink-0"
          >
            <div className="w-16 h-16 rounded-full border-2 border-gray-200 flex items-center justify-center bg-gray-50">
              <Plus className="h-6 w-6 text-gray-400" />
            </div>
            <span className="text-xs text-gray-500">Add Story</span>
          </Link>

          {userIds.map((userId, index) => {
            const { user: storyUser, stories } = storiesByUser[userId];
            const hasUnviewed = stories.some(story => 
              !story.views.includes(user?.uid || '')
            );

            return (
              <div
                key={userId}
                className="flex flex-col items-center space-y-1 flex-shrink-0"
              >
                <button
                  onClick={() => setSelectedUserIndex(index)}
                  className={`p-1 rounded-full ${
                    hasUnviewed ? 'bg-gradient-to-tr from-yellow-400 to-pink-500' : 'bg-gray-200'
                  }`}
                >
                  <div className="w-14 h-14 rounded-full border-2 border-white overflow-hidden">
                    <img
                      src={storyUser.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${storyUser.id}`}
                      alt={storyUser.name}
                      className="w-full h-full object-cover"
                    />
                  </div>
                </button>
                <Link
                  to={`/profile/${storyUser.username}`}
                  className="text-xs text-gray-500 hover:text-gray-700 truncate w-16 text-center"
                >
                  {storyUser.name.split(' ')[0]}
                </Link>
              </div>
            );
          })}
        </div>
      </div>

      {selectedUserIndex !== null && (
        <StoryViewer
          stories={allStories}
          initialIndex={selectedUserIndex}
          onClose={() => setSelectedUserIndex(null)}
        />
      )}
    </div>
  );
}