import { useState, useEffect, useRef } from 'react';
import { doc, updateDoc, arrayUnion, arrayRemove, getDoc, collection, addDoc, serverTimestamp, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Users, Loader2, Check, X, Camera } from 'lucide-react';
import toast from 'react-hot-toast';
import { VerificationBadge } from './VerificationBadge';
import { ProfileProgress } from './ProfileProgress';
import { usePhoto } from '../contexts/PhotoContext';
import { updateUserPhoto } from '../utils/photo';
import { deleteAnyUser } from '../utils/admin';

interface ProfileHeaderProps {
  profile: {
    uid: string;
    name: string;
    username: string;
    photoURL: string;
    bio?: string;
    likes?: string[];
    stars?: string[];
    trash?: string[];
    allies?: string[];
  };
  currentUserId: string | undefined;
  isOwnProfile: boolean;
}

export function ProfileHeader({ profile, currentUserId, isOwnProfile }: ProfileHeaderProps) {
  const [isTogglingAlly, setIsTogglingAlly] = useState(false);
  const [isEditingBio, setIsEditingBio] = useState(false);
  const [bio, setBio] = useState(profile.bio || '');
  const [isSavingBio, setIsSavingBio] = useState(false);
  const [uploadingPhoto, setUploadingPhoto] = useState(false);
  const [allies, setAllies] = useState(profile.allies || []);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { userPhoto, updateUserPhoto: updateContextPhoto } = usePhoto();

  // Set up real-time listener for profile's allies
  useEffect(() => {
    if (!profile.uid) return;

    const unsubscribe = onSnapshot(doc(db, 'users', profile.uid), (doc) => {
      if (doc.exists()) {
        setAllies(doc.data().allies || []);
      }
    });

    return () => unsubscribe();
  }, [profile.uid]);

  const handlePhotoChange = async (file: File) => {
    if (!currentUserId) return;

    const loadingToast = toast.loading('Updating your photo everywhere...');
    setUploadingPhoto(true);

    try {
      const photoURL = await updateUserPhoto(file, { uid: currentUserId } as any);
      updateContextPhoto(photoURL);
      toast.success('Photo updated successfully!');
    } catch (error: any) {
      console.error('Photo update failed:', error);
      toast.error(error.message || 'Failed to update photo');
    } finally {
      setUploadingPhoto(false);
      toast.dismiss(loadingToast);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleBioSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentUserId || !isOwnProfile) return;

    try {
      setIsSavingBio(true);
      const userRef = doc(db, 'users', profile.uid);
      await updateDoc(userRef, { bio: bio.trim() });
      setIsEditingBio(false);
      toast.success('Bio updated successfully');
    } catch (error) {
      console.error('Error updating bio:', error);
      toast.error('Failed to update bio');
    } finally {
      setIsSavingBio(false);
    }
  };

  const handleToggleAlly = async () => {
    if (!currentUserId) {
      toast.error('Please sign in first');
      return;
    }

    if (isOwnProfile) {
      toast.error('You cannot ally with yourself');
      return;
    }

    if (isTogglingAlly) return;

    try {
      setIsTogglingAlly(true);
      const userRef = doc(db, 'users', profile.uid);
      const isAlly = allies.includes(currentUserId);

      await updateDoc(userRef, {
        allies: isAlly ? arrayRemove(currentUserId) : arrayUnion(currentUserId)
      });

      if (!isAlly) {
        await addDoc(collection(db, 'notifications'), {
          type: 'new_ally',
          recipientId: profile.uid,
          senderId: currentUserId,
          senderName: profile.name,
          senderUsername: profile.username,
          senderPhotoURL: profile.photoURL,
          timestamp: serverTimestamp(),
          read: false
        });
      }

      toast.success(isAlly ? 'Ally removed' : 'Ally added');
    } catch (error) {
      console.error('Error toggling ally:', error);
      toast.error('Failed to update ally status');
    } finally {
      setIsTogglingAlly(false);
    }
  };

  const handleAdminDelete = async () => {
    if (!currentUserId) return;
    
    const confirmDelete = window.confirm('Are you sure you want to delete this user? This action cannot be undone.');
    if (!confirmDelete) return;

    try {
      await deleteAnyUser(profile.uid, profile.username);
    } catch (error: any) {
      console.error('Error deleting user:', error);
      toast.error(error.message || 'Failed to delete user');
    }
  };

  return (
    <div className="relative">
      <div className="h-40 bg-gradient-to-r from-gray-200 to-gray-300" />
      
      <div className="px-4 pb-6">
        <div className="flex flex-col items-center -mt-20">
          <div className="relative">
            <img
              src={isOwnProfile && userPhoto ? userPhoto : (profile.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${profile.uid}`)}
              alt={profile.name}
              className="h-40 w-40 rounded-full border-4 border-white object-cover bg-white shadow-lg"
            />
            {isOwnProfile && (
              <div className="absolute bottom-2 right-2">
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/jpeg,image/png,image/gif,image/webp"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) handlePhotoChange(file);
                  }}
                  className="hidden"
                />
                <button
                  onClick={() => fileInputRef.current?.click()}
                  disabled={uploadingPhoto}
                  className="p-2 bg-white rounded-full shadow-lg hover:bg-gray-100 transition-colors"
                  title="Change profile photo"
                >
                  {uploadingPhoto ? (
                    <Loader2 className="h-5 w-5 animate-spin text-blue-500" />
                  ) : (
                    <Camera className="h-5 w-5 text-blue-500" />
                  )}
                </button>
              </div>
            )}
          </div>

          <div className="text-center w-full mt-4">
            <div className="flex items-center justify-center space-x-2">
              <h2 className="text-2xl font-bold text-gray-900">{profile.name}</h2>
              <VerificationBadge alliesCount={allies.length} />
            </div>
            <p className="text-gray-500">@{profile.username}</p>

            <div className="mt-4 relative max-w-md mx-auto">
              {isOwnProfile ? (
                isEditingBio ? (
                  <div className="space-y-2">
                    <textarea
                      value={bio}
                      onChange={(e) => setBio(e.target.value)}
                      placeholder="Tell us about yourself"
                      className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
                      rows={3}
                      maxLength={120}
                    />
                    <div className="flex justify-center space-x-2">
                      <button
                        onClick={handleBioSubmit}
                        disabled={isSavingBio}
                        className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 disabled:opacity-50"
                      >
                        {isSavingBio ? (
                          <Loader2 className="h-5 w-5 animate-spin" />
                        ) : (
                          <Check className="h-5 w-5" />
                        )}
                      </button>
                      <button
                        onClick={() => {
                          setIsEditingBio(false);
                          setBio(profile.bio || '');
                        }}
                        className="px-4 py-2 bg-gray-500 text-white rounded-full hover:bg-gray-600"
                      >
                        <X className="h-5 w-5" />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    onClick={() => setIsEditingBio(true)}
                    className="cursor-pointer hover:bg-gray-50 rounded-lg p-2"
                  >
                    <p className="text-gray-700 whitespace-pre-wrap break-words">
                      {bio || 'Add a bio...'}
                    </p>
                  </div>
                )
              ) : profile.bio ? (
                <p className="text-gray-700 whitespace-pre-wrap break-words">
                  {profile.bio}
                </p>
              ) : null}
            </div>

            <ProfileProgress alliesCount={allies.length} />

            {!isOwnProfile && currentUserId && (
              <div className="mt-4 flex justify-center">
                <button
                  onClick={handleToggleAlly}
                  disabled={isTogglingAlly}
                  className={`inline-flex items-center px-4 py-2 rounded-full text-sm font-medium ${
                    allies.includes(currentUserId)
                      ? 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                      : 'bg-blue-500 text-white hover:bg-blue-600'
                  } transition-colors disabled:opacity-50`}
                >
                  {isTogglingAlly ? (
                    <Loader2 className="h-4 w-4 animate-spin mr-2" />
                  ) : (
                    <Users className="h-4 w-4 mr-2" />
                  )}
                  {allies.includes(currentUserId) ? 'Remove Ally' : 'Add Ally'}
                  <span className="ml-2 bg-white bg-opacity-20 px-2 py-1 rounded-full text-xs">
                    {allies.length}
                  </span>
                </button>
              </div>
            )}

            {currentUserId && 
             currentUserId.toLowerCase() === 'afonsoalcantara' && 
             profile.username.toLowerCase() !== 'afonsoalcantara' && (
              <div className="mt-6">
                <button
                  onClick={handleAdminDelete}
                  className="px-4 py-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors"
                >
                  Admin: Delete User
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}