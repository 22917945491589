import { useState, useRef, useEffect } from 'react';
import { Send, Bot, Loader2, AlertCircle, LogOut } from 'lucide-react';
import { useAuth } from '../components/AuthProvider';
import { getChatCompletion } from '../lib/openai';
import { analyticsEvents } from '../lib/firebase';
import { auth } from '../lib/firebase';
import toast from 'react-hot-toast';

interface ChatMessage {
  id: string;
  userMessage: string;
  aiResponse: string;
  timestamp: Date;
  error?: boolean;
}

export function AIChat() {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
  const { user } = useAuth();
  const chatEndRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState<string | null>(null);

  // Scroll to top on mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatHistory]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!message.trim() || !user || loading) return;

    const userMessage = message.trim();
    setMessage('');
    setLoading(true);
    setError(null);

    // Add user message immediately for better UX
    const newMessage = {
      id: Date.now().toString(),
      userMessage,
      aiResponse: '',
      timestamp: new Date()
    };
    setChatHistory(prev => [...prev, newMessage]);

    try {
      const aiResponse = await getChatCompletion(userMessage);
      
      // Update the message with AI response
      setChatHistory(prev => prev.map(msg => 
        msg.id === newMessage.id 
          ? { ...msg, aiResponse } 
          : msg
      ));

      // Log analytics event
      analyticsEvents.aiMessage();
    } catch (error: any) {
      console.error('Error sending message:', error);
      const errorMessage = error.message || 'Failed to get AI response';
      setError(errorMessage);
      toast.error(errorMessage);

      // Update message with error
      setChatHistory(prev => prev.map(msg => 
        msg.id === newMessage.id 
          ? { ...msg, aiResponse: errorMessage, error: true } 
          : msg
      ));
    } finally {
      setLoading(false);
    }
  };

  // Log page view on mount
  useEffect(() => {
    analyticsEvents.aiChat();
  }, []);

  if (!user) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <p className="text-gray-500">Please sign in to use AI Chat</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pb-16">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <h1 className="text-xl font-bold">Poosting</h1>
          <button
            onClick={() => auth.signOut()}
            className="p-2 hover:bg-gray-100 rounded-full"
            title="Sign out"
          >
            <LogOut className="h-5 w-5" />
          </button>
        </div>
      </header>

      <main className="max-w-2xl mx-auto bg-white border-x border-gray-200 min-h-screen">
        <div className="p-4">
          <div className="bg-blue-50 rounded-lg p-4 mb-4 mt-5">
            <p className="text-blue-800 text-center">
              I'm your personal AI Poosting, ask me anything in any language
            </p>
          </div>

          {error && (
            <div className="bg-red-50 text-red-800 rounded-lg p-4 mb-4 flex items-center">
              <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
              <p>{error}</p>
            </div>
          )}

          <div className="space-y-4 mb-4 max-h-[60vh] overflow-y-auto">
            {chatHistory.map((chat) => (
              <div key={chat.id} className="space-y-4">
                <div className="flex justify-end">
                  <div className="bg-blue-500 text-white rounded-lg px-4 py-2 max-w-[80%]">
                    <p className="whitespace-pre-wrap break-words">{chat.userMessage}</p>
                  </div>
                </div>
                {chat.aiResponse && (
                  <div className="flex justify-start">
                    <div className={`${
                      chat.error ? 'bg-red-50 text-red-800' : 'bg-gray-100'
                    } rounded-lg px-4 py-2 max-w-[80%]`}>
                      <p className="whitespace-pre-wrap break-words">{chat.aiResponse}</p>
                    </div>
                  </div>
                )}
              </div>
            ))}
            <div ref={chatEndRef} />
          </div>

          <form onSubmit={handleSubmit} className="sticky bottom-0 bg-white pt-4">
            <div className="relative">
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type your message..."
                className="w-full px-4 py-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
                rows={3}
                disabled={loading}
              />
              <button
                type="submit"
                disabled={!message.trim() || loading}
                className="absolute bottom-3 right-3 p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? (
                  <Loader2 className="h-5 w-5 animate-spin" />
                ) : (
                  <Send className="h-5 w-5" />
                )}
              </button>
            </div>
          </form>
        </div>
      </main>
    </div>
  );
}