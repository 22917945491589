import { useState } from 'react';
import { doc, updateDoc, arrayUnion, arrayRemove, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from './AuthProvider';
import { Flame } from 'lucide-react';
import toast from 'react-hot-toast';

interface PostMatchProps {
  postId: string;
  authorId: string;
  matches: string[];
}

export function PostMatch({ postId, authorId, matches = [] }: PostMatchProps) {
  const [isMatching, setIsMatching] = useState(false);
  const { user } = useAuth();

  const handleMatch = async () => {
    if (!user) {
      toast.error('Please sign in to match');
      return;
    }

    if (isMatching) return;

    try {
      setIsMatching(true);
      const postRef = doc(db, 'posts', postId);
      const hasMatched = matches.includes(user.uid);

      await updateDoc(postRef, {
        matches: hasMatched ? arrayRemove(user.uid) : arrayUnion(user.uid)
      });

      if (!hasMatched && user.uid !== authorId) {
        // Create match notification
        await addDoc(collection(db, 'notifications'), {
          type: 'match',
          postId,
          recipientId: authorId,
          senderId: user.uid,
          senderName: user.displayName || '',
          senderUsername: user.displayName?.startsWith('@') 
            ? user.displayName.slice(1) 
            : user.displayName || '',
          senderPhotoURL: user.photoURL,
          message: 'I liked you',
          timestamp: serverTimestamp(),
          read: false
        });
      }

      toast.success(hasMatched ? 'Match removed' : 'Matched!');
    } catch (error) {
      console.error('Error matching post:', error);
      toast.error('Failed to match');
    } finally {
      setIsMatching(false);
    }
  };

  const hasMatched = matches.includes(user?.uid || '');

  return (
    <button
      onClick={handleMatch}
      disabled={isMatching}
      className={`flex items-center space-x-1 ${
        hasMatched ? 'text-red-500' : 'text-gray-500 hover:text-gray-700'
      } transition-colors disabled:opacity-50`}
      title={hasMatched ? 'Remove match' : 'Match'}
    >
      <Flame className="h-5 w-5" />
      <span className="text-sm">{matches.length}</span>
    </button>
  );
}