import { Music } from 'lucide-react';

export function MusicHeader() {
  return (
    <div className="p-4 bg-gradient-to-r from-green-500 to-green-600 text-white">
      <div className="flex items-center space-x-3">
        <Music className="h-8 w-8" />
        <div>
          <h2 className="text-xl font-bold">Global Top 50</h2>
          <p className="text-sm opacity-90">Most played tracks on Spotify</p>
        </div>
      </div>
    </div>
  );
}