import { ReactNode } from 'react';
import { MainMenu } from './MainMenu';

interface LayoutProps {
  children: ReactNode;
}

export function Layout({ children }: LayoutProps) {
  return (
    <div className="min-h-screen bg-gray-50">
      <MainMenu />
      <main>
        {children}
      </main>
    </div>
  );
}