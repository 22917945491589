import { MapPin, Star, Phone, Globe, Navigation } from 'lucide-react';

interface PlaceCardProps {
  place: google.maps.places.PlaceResult;
  onClick: () => void;
}

export function PlaceCard({ place, onClick }: PlaceCardProps) {
  return (
    <div 
      onClick={onClick}
      className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow p-4 cursor-pointer"
    >
      {place.photos && place.photos[0] && (
        <img
          src={place.photos[0].getUrl()}
          alt={place.name}
          className="w-full h-48 object-cover rounded-lg mb-4"
        />
      )}
      
      <h3 className="text-lg font-semibold mb-2">{place.name}</h3>
      
      <div className="space-y-2">
        {place.rating && (
          <div className="flex items-center text-yellow-500">
            <Star className="h-4 w-4 fill-current mr-1" />
            <span>{place.rating} ({place.user_ratings_total} reviews)</span>
          </div>
        )}
        
        {place.formatted_address && (
          <div className="flex items-center text-gray-600">
            <MapPin className="h-4 w-4 mr-1" />
            <span className="text-sm">{place.formatted_address}</span>
          </div>
        )}
        
        {place.formatted_phone_number && (
          <div className="flex items-center text-gray-600">
            <Phone className="h-4 w-4 mr-1" />
            <span className="text-sm">{place.formatted_phone_number}</span>
          </div>
        )}
        
        {place.website && (
          <div className="flex items-center text-blue-500">
            <Globe className="h-4 w-4 mr-1" />
            <a 
              href={place.website} 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-sm hover:underline"
              onClick={(e) => e.stopPropagation()}
            >
              Visit Website
            </a>
          </div>
        )}

        {place.geometry?.location && (
          <div className="flex items-center text-blue-500">
            <Navigation className="h-4 w-4 mr-1" />
            <a
              href={`https://www.google.com/maps/dir/?api=1&destination=${place.geometry.location.lat()},${place.geometry.location.lng()}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm hover:underline"
              onClick={(e) => e.stopPropagation()}
            >
              Get Directions
            </a>
          </div>
        )}
      </div>
    </div>
  );
}