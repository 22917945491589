import { Loader } from '@googlemaps/js-api-loader';
import toast from 'react-hot-toast';

const GOOGLE_MAPS_API_KEY = 'AIzaSyBuJT7El4mSrV6GjcknUnKvHbjrsSXuyf8';

// Initialize the loader with required libraries
const loader = new Loader({
  apiKey: GOOGLE_MAPS_API_KEY,
  version: 'weekly',
  libraries: ['places', 'geometry'],
  region: 'US',
  language: 'en',
  retries: 3,
  retry_interval: 1000,
  authReferrerPolicy: 'origin'
});

// Load Google Maps script with better error handling
let mapsPromise: Promise<typeof google>;
export async function loadGoogleMaps() {
  if (!mapsPromise) {
    mapsPromise = loader.load()
      .then(google => {
        if (!google.maps) {
          throw new Error('Google Maps failed to load properly');
        }
        return google;
      })
      .catch(error => {
        console.error('Error loading Google Maps:', error);
        
        if (error.message.includes('ApiNotActivatedMapError')) {
          toast.error('Please enable Maps JavaScript API in Google Cloud Console');
        } else if (error.message.includes('InvalidKeyMapError')) {
          toast.error('Invalid Google Maps API key');
        } else {
          toast.error('Failed to load Google Maps');
        }
        
        throw error;
      });
  }
  return mapsPromise;
}

// Initialize Places service with better error handling
export async function initializePlacesService(): Promise<google.maps.places.PlacesService> {
  try {
    const google = await loadGoogleMaps();
    
    // Create an invisible map element
    const mapDiv = document.createElement('div');
    mapDiv.style.visibility = 'hidden';
    mapDiv.style.position = 'absolute';
    mapDiv.style.left = '-9999px';
    document.body.appendChild(mapDiv);

    const map = new google.maps.Map(mapDiv, {
      center: { lat: 0, lng: 0 },
      zoom: 1,
      disableDefaultUI: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    });

    const service = new google.maps.places.PlacesService(map);

    // Verify service is working
    return new Promise((resolve, reject) => {
      service.nearbySearch({
        location: { lat: 0, lng: 0 },
        radius: 100,
      }, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.INVALID_REQUEST ||
            status === google.maps.places.PlacesServiceStatus.REQUEST_DENIED) {
          reject(new Error('Please enable Places API in Google Cloud Console'));
        } else {
          resolve(service);
        }
      });
    });
  } catch (error) {
    console.error('Error initializing Places service:', error);
    toast.error('Failed to initialize Places service. Please check API configuration.');
    throw error;
  }
}

// Get user's current location with better error handling
export async function getUserLocation(): Promise<{ lat: number; lng: number }> {
  return new Promise((resolve) => {
    if (!navigator.geolocation) {
      toast.error('Geolocation is not supported by your browser');
      resolve({ lat: 40.7128, lng: -74.0060 }); // Default to NYC
      return;
    }

    const timeoutId = setTimeout(() => {
      toast.error('Location request timed out');
      resolve({ lat: 40.7128, lng: -74.0060 });
    }, 10000);

    navigator.geolocation.getCurrentPosition(
      (position) => {
        clearTimeout(timeoutId);
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        });
      },
      (error) => {
        clearTimeout(timeoutId);
        console.error('Error getting location:', error);
        
        let errorMessage = 'Could not get your location';
        switch (error.code) {
          case error.PERMISSION_DENIED:
            errorMessage = 'Location access denied. Please enable location services.';
            break;
          case error.POSITION_UNAVAILABLE:
            errorMessage = 'Location information unavailable';
            break;
          case error.TIMEOUT:
            errorMessage = 'Location request timed out';
            break;
        }
        
        toast.error(errorMessage);
        resolve({ lat: 40.7128, lng: -74.0060 }); // Default to NYC
      },
      {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0
      }
    );
  });
}