import { useState, useEffect, useRef } from 'react';
import { collection, query, orderBy, onSnapshot, addDoc, serverTimestamp, doc, updateDoc, arrayUnion, getDocs, where, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { Send, Image, Eye, Plus, Menu, Trash2, ChevronRight, MessageCircle, MapPin, LogOut, ArrowLeft } from 'lucide-react';
import { auth } from '../lib/firebase';
import { ChatList } from '../components/ChatList';
import { ChatInvite } from '../components/ChatInvite';
import { LocationMessage } from '../components/LocationMessage';
import { MainMenu } from '../components/MainMenu';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';

interface Message {
  id: string;
  content?: string;
  imageUrl?: string;
  location?: {
    latitude: number;
    longitude: number;
  };
  senderId: string;
  senderName: string;
  timestamp: Date;
  readBy: string[];
}

interface ChatRoom {
  id: string;
  participants: string[];
  lastMessageTimestamp?: Date;
}

export function Chat() {
  const [selectedChat, setSelectedChat] = useState<ChatRoom | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [sending, setSending] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [showChatList, setShowChatList] = useState(window.innerWidth >= 768);
  const [selectedUsername, setSelectedUsername] = useState<string | null>(null);
  const [unreadCount, setUnreadCount] = useState(0);
  
  const { user } = useAuth();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messageContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!selectedChat || !user) return;

    const messagesRef = collection(db, `chats/${selectedChat.id}/messages`);
    const q = query(messagesRef, orderBy('timestamp', 'asc'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const messageData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate() || new Date()
      })) as Message[];

      setMessages(messageData);

      snapshot.docs.forEach(async (doc) => {
        const messageData = doc.data();
        if (!messageData.readBy?.includes(user.uid)) {
          await updateDoc(doc.ref, {
            readBy: arrayUnion(user.uid)
          });
        }
      });
    });

    return () => unsubscribe();
  }, [selectedChat, user]);

  useEffect(() => {
    if (messageContainerRef.current) {
      const { scrollHeight, clientHeight } = messageContainerRef.current;
      messageContainerRef.current.scrollTop = scrollHeight - clientHeight;
    }
  }, [messages]);

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if ((!newMessage.trim() && !fileInputRef.current?.files?.length) || !selectedChat || !user || sending) return;

    setSending(true);
    try {
      let imageUrl = '';

      if (fileInputRef.current?.files?.length) {
        const file = fileInputRef.current.files[0];
        const storageRef = ref(storage, `chats/${selectedChat.id}/${uuidv4()}-${file.name}`);
        await uploadBytes(storageRef, file);
        imageUrl = await getDownloadURL(storageRef);
        fileInputRef.current.value = '';
      }

      await addDoc(collection(db, `chats/${selectedChat.id}/messages`), {
        content: newMessage.trim() || null,
        imageUrl: imageUrl || null,
        senderId: user.uid,
        senderName: user.displayName,
        timestamp: serverTimestamp(),
        readBy: [user.uid]
      });

      await updateDoc(doc(db, 'chats', selectedChat.id), {
        lastMessage: newMessage.trim() || (imageUrl ? '📷 Image' : ''),
        lastMessageTimestamp: serverTimestamp(),
        unreadBy: selectedChat.participants.filter(id => id !== user.uid)
      });

      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Failed to send message');
    } finally {
      setSending(false);
    }
  };

  const handleShareLocation = async () => {
    if (!selectedChat || !user || sending) return;

    try {
      setSending(true);
      const position = await new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      await addDoc(collection(db, `chats/${selectedChat.id}/messages`), {
        location: {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        },
        senderId: user.uid,
        senderName: user.displayName,
        timestamp: serverTimestamp(),
        readBy: [user.uid]
      });

      await updateDoc(doc(db, 'chats', selectedChat.id), {
        lastMessage: '📍 Location shared',
        lastMessageTimestamp: serverTimestamp(),
        unreadBy: selectedChat.participants.filter(id => id !== user.uid)
      });

      toast.success('Location shared successfully');
    } catch (error) {
      console.error('Error sharing location:', error);
      toast.error('Failed to share location');
    } finally {
      setSending(false);
    }
  };

  const handleDeleteChat = async () => {
    if (!selectedChat || !user) return;

    const confirmDelete = window.confirm('Are you sure you want to delete this chat? This action cannot be undone.');
    if (!confirmDelete) return;

    try {
      const messagesRef = collection(db, `chats/${selectedChat.id}/messages`);
      const messagesSnapshot = await getDocs(messagesRef);
      await Promise.all(messagesSnapshot.docs.map(doc => deleteDoc(doc.ref)));

      try {
        const storageRef = ref(storage, `chats/${selectedChat.id}`);
        const files = await listAll(storageRef);
        await Promise.all(files.items.map(file => deleteObject(file)));
      } catch (error) {
        console.error('Error deleting media files:', error);
      }

      await deleteDoc(doc(db, 'chats', selectedChat.id));

      setSelectedChat(null);
      setSelectedUsername(null);
      toast.success('Chat deleted successfully');
    } catch (error) {
      console.error('Error deleting chat:', error);
      toast.error('Failed to delete chat');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-4">
            {selectedChat && window.innerWidth < 768 && (
              <button
                onClick={() => {
                  setSelectedChat(null);
                  setShowChatList(true);
                }}
                className="p-2 hover:bg-gray-100 rounded-full"
              >
                <ArrowLeft className="h-5 w-5" />
              </button>
            )}
            {!selectedChat && (
              <button
                onClick={() => setShowChatList(!showChatList)}
                className="md:hidden flex items-center space-x-2 px-3 py-2 bg-black text-white rounded-full hover:bg-gray-800"
                title={showChatList ? 'Hide chat list' : 'Show chat list'}
              >
                <Menu className="h-5 w-5" />
                <span className="text-sm">Messages</span>
                {unreadCount > 0 && (
                  <span className="bg-red-500 text-white text-xs rounded-full px-2 py-0.5 min-w-[1.25rem]">
                    {unreadCount}
                  </span>
                )}
                <ChevronRight className="h-4 w-4" />
              </button>
            )}
            <h1 className="text-xl font-bold">
              {selectedUsername ? `@${selectedUsername}` : 'Poosting'}
            </h1>
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={() => setShowInvite(true)}
              className="flex items-center space-x-1 px-3 py-1.5 bg-black text-white rounded-full hover:bg-gray-800 text-xs"
            >
              <Plus className="h-4 w-4" />
              <span>Chat</span>
            </button>
            {selectedChat && (
              <button
                onClick={handleDeleteChat}
                className="p-2 hover:bg-gray-100 rounded-full text-red-500"
                title="Delete chat"
              >
                <Trash2 className="h-5 w-5" />
              </button>
            )}
            <button
              onClick={() => auth.signOut()}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <LogOut className="h-5 w-5" />
            </button>
          </div>
        </div>
        <MainMenu />
      </header>

      <main className="max-w-2xl mx-auto bg-white border-x border-gray-200 min-h-screen flex">
        <div className={`${
          showChatList ? 'block' : 'hidden'
        } md:block ${selectedChat ? 'hidden md:block' : 'w-full md:w-80'} bg-white z-10 h-[calc(100vh-64px)] border-r border-gray-200`}>
          <ChatList
            selectedChat={selectedChat}
            onChatSelect={(chat) => {
              setSelectedChat(chat);
              if (window.innerWidth < 768) {
                setShowChatList(false);
              }
            }}
          />
        </div>

        {selectedChat ? (
          <div className="flex-1 flex flex-col h-[calc(100vh-64px)]">
            <div 
              ref={messageContainerRef}
              className="flex-1 overflow-y-auto p-4 space-y-4"
            >
              {messages.map((msg) => (
                <div
                  key={msg.id}
                  className={`flex ${
                    msg.senderId === user?.uid ? 'justify-end' : 'justify-start'
                  }`}
                >
                  <div
                    className={`max-w-[80%] md:max-w-[70%] rounded-lg ${
                      msg.senderId === user?.uid
                        ? 'bg-black text-white'
                        : 'bg-gray-100'
                    }`}
                  >
                    <div className="flex justify-between items-center px-4 py-2">
                      <p className="text-sm font-medium">{msg.senderName}</p>
                      <div className="flex items-center space-x-2">
                        {msg.senderId === user?.uid && (
                          <div className="flex items-center">
                            <Eye className={`h-4 w-4 ${
                              msg.readBy?.length > 1
                                ? 'text-blue-400'
                                : 'text-gray-400'
                            }`} />
                          </div>
                        )}
                      </div>
                    </div>

                    {msg.content && (
                      <p className="px-4 pb-3 break-words whitespace-pre-wrap">{msg.content}</p>
                    )}

                    {msg.imageUrl && (
                      <div className="px-4 pb-3">
                        <img
                          src={msg.imageUrl}
                          alt="Message attachment"
                          className="rounded-lg max-h-60 object-contain"
                          loading="lazy"
                        />
                      </div>
                    )}

                    {msg.location && (
                      <div className="px-4 pb-3">
                        <LocationMessage
                          latitude={msg.location.latitude}
                          longitude={msg.location.longitude}
                          senderName={msg.senderName}
                          senderPhotoURL={user?.photoURL || ''}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>

            <form onSubmit={handleSendMessage} className="p-4 border-t border-gray-200">
              <div className="flex items-center space-x-2">
                <button
                  type="button"
                  onClick={() => fileInputRef.current?.click()}
                  className="p-2 text-blue-500 hover:bg-gray-100 rounded-full"
                  title="Send image"
                >
                  <Image className="h-5 w-5" />
                </button>

                <button
                  type="button"
                  onClick={handleShareLocation}
                  className="p-2 text-blue-500 hover:bg-gray-100 rounded-full"
                  title="Share location"
                >
                  <MapPin className="h-5 w-5" />
                </button>

                <input
                  type="text"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  placeholder="Type a message..."
                  className="flex-1 px-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                />

                <button
                  type="submit"
                  disabled={(!newMessage.trim() && !fileInputRef.current?.files?.length) || sending}
                  className="p-2 text-blue-500 hover:bg-gray-100 rounded-full disabled:opacity-50"
                >
                  <Send className="h-5 w-5" />
                </button>
              </div>

              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                onChange={() => handleSendMessage({ preventDefault: () => {} } as any)}
                className="hidden"
              />
            </form>
          </div>
        ) : (
          <div className="flex-1 flex flex-col items-center justify-center p-4">
            <div className="text-center space-y-4">
              <MessageCircle className="h-12 w-12 text-gray-400 mx-auto" />
              <h2 className="text-xl font-semibold">Your Chats</h2>
              <p className="text-gray-500">Send private messages to other users</p>
            </div>
          </div>
        )}
      </main>

      {showInvite && (
        <ChatInvite
          onClose={() => setShowInvite(false)}
          onInviteAccepted={async (userId) => {
            try {
              const chatDoc = await addDoc(collection(db, 'chats'), {
                participants: [user?.uid, userId],
                createdAt: serverTimestamp(),
                lastMessageTimestamp: serverTimestamp()
              });
              setSelectedChat({ id: chatDoc.id, participants: [user?.uid, userId] });
              setShowInvite(false);
              if (window.innerWidth < 768) {
                setShowChatList(false);
              }
            } catch (error) {
              console.error('Error creating chat:', error);
              toast.error('Failed to create chat');
            }
          }}
        />
      )}
    </div>
  );
}