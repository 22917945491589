import { User } from 'firebase/auth';
import { doc, updateDoc, collection, query, where, getDocs, writeBatch, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage, auth } from '../lib/firebase';
import { updateProfile } from 'firebase/auth';

const MAX_BATCH_SIZE = 500;
const MAX_FILE_SIZE = 25 * 1024 * 1024; // 25MB
const MAX_IMAGE_DIMENSION = 2000; // Maximum dimension for optimization

export async function updateUserPhoto(blob: Blob, user: User): Promise<string> {
  try {
    if (blob.size > MAX_FILE_SIZE) {
      throw new Error('File too large. Maximum size is 25MB.');
    }

    const timestamp = Date.now();
    const randomString = Math.random().toString(36).substring(7);
    const filename = `${user.uid}_${timestamp}_${randomString}.jpg`;

    let photoURL: string;
    try {
      // Optimize image before upload
      const optimizedImage = await optimizeImage(blob);
      const storageRef = ref(storage, `avatars/${filename}`);
      await uploadBytes(storageRef, optimizedImage);
      photoURL = await getDownloadURL(storageRef);
    } catch (error) {
      console.error('First upload attempt failed, retrying:', error);
      // Fallback to original blob if optimization fails
      const retryFilename = `${user.uid}_${timestamp}_retry_${randomString}.jpg`;
      const retryStorageRef = ref(storage, `avatars/${retryFilename}`);
      await uploadBytes(retryStorageRef, blob);
      photoURL = await getDownloadURL(retryStorageRef);
    }

    // Create or update user document
    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDocs(query(collection(db, 'users'), where('uid', '==', user.uid)));
    
    if (userDoc.empty) {
      await setDoc(userRef, {
        uid: user.uid,
        name: user.displayName || '',
        username: user.displayName?.startsWith('@') 
          ? user.displayName.slice(1).toLowerCase() 
          : user.displayName?.toLowerCase() || '',
        photoURL,
        createdAt: new Date(),
      });
    } else {
      await updateDoc(userRef, { photoURL });
    }

    // Update auth profile
    const currentUser = auth.currentUser;
    if (currentUser) {
      await updateProfile(currentUser, { photoURL });
    }

    // Update all related documents in batches
    const batches: ReturnType<typeof writeBatch>[] = [writeBatch(db)];
    let operationCount = 0;
    let currentBatchIndex = 0;

    const addToBatch = (docRef: any, data: any) => {
      if (operationCount === MAX_BATCH_SIZE) {
        batches.push(writeBatch(db));
        currentBatchIndex++;
        operationCount = 0;
      }
      batches[currentBatchIndex].update(docRef, data);
      operationCount++;
    };

    // Get all documents that need updating
    const [postsSnapshot, commentsSnapshot, notificationsSnapshot] = await Promise.all([
      getDocs(query(collection(db, 'posts'), where('authorId', '==', user.uid))),
      getDocs(query(collection(db, 'comments'), where('authorId', '==', user.uid))),
      getDocs(query(collection(db, 'notifications'), where('senderId', '==', user.uid)))
    ]);

    // Update posts
    postsSnapshot.docs.forEach(doc => {
      addToBatch(doc.ref, { authorPhotoURL: photoURL });
    });

    // Update comments
    commentsSnapshot.docs.forEach(doc => {
      addToBatch(doc.ref, { authorPhotoURL: photoURL });
    });

    // Update notifications
    notificationsSnapshot.docs.forEach(doc => {
      addToBatch(doc.ref, { senderPhotoURL: photoURL });
    });

    // Commit all batches with retry mechanism
    await Promise.all(batches.map(async (batch) => {
      try {
        await batch.commit();
      } catch (error) {
        console.error('Batch commit failed, retrying:', error);
        await batch.commit();
      }
    }));

    return photoURL;
  } catch (error: any) {
    console.error('Photo update failed:', error);
    throw new Error(error.message || 'Failed to update photo. Please try again.');
  }
}

async function optimizeImage(blob: Blob): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(blob);
    
    img.onload = () => {
      URL.revokeObjectURL(img.src);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      
      if (!ctx) {
        reject(new Error('Failed to get canvas context'));
        return;
      }

      // Calculate new dimensions while maintaining aspect ratio
      let { width, height } = calculateOptimizedDimensions(img.width, img.height);

      // Set canvas dimensions
      canvas.width = width;
      canvas.height = height;

      // Optional: Add a white background for transparent images
      ctx.fillStyle = '#FFFFFF';
      ctx.fillRect(0, 0, width, height);

      // Draw image with smoothing
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';
      ctx.drawImage(img, 0, 0, width, height);

      // Convert to blob
      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Failed to optimize image'));
          }
        },
        'image/jpeg',
        0.95
      );
    };

    img.onerror = () => reject(new Error('Failed to load image'));
  });
}

function calculateOptimizedDimensions(width: number, height: number) {
  if (width <= MAX_IMAGE_DIMENSION && height <= MAX_IMAGE_DIMENSION) {
    return { width, height };
  }

  const aspectRatio = width / height;

  if (width > height) {
    width = MAX_IMAGE_DIMENSION;
    height = Math.round(width / aspectRatio);
  } else {
    height = MAX_IMAGE_DIMENSION;
    width = Math.round(height * aspectRatio);
  }

  return { width, height };
}