import { useState, useEffect } from 'react';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { Loader2, Users } from 'lucide-react';
import { Link } from 'react-router-dom';
import { VerificationBadge } from './VerificationBadge';
import toast from 'react-hot-toast';

interface User {
  uid: string;
  name: string;
  username: string;
  photoURL: string;
  allies?: string[];
  createdAt: Date;
}

export function RecentUsersList() {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRecentUsers = async () => {
      try {
        const usersRef = collection(db, 'users');
        const q = query(
          usersRef,
          orderBy('createdAt', 'desc'),
          limit(100)
        );
        
        const snapshot = await getDocs(q);
        const userData = snapshot.docs
          .map(doc => {
            const data = doc.data();
            return {
              uid: doc.id,
              name: data.name,
              username: data.username,
              photoURL: data.photoURL,
              allies: data.allies || [],
              createdAt: data.createdAt?.toDate() || new Date()
            } as User;
          })
          .filter(user => user.name && user.username); // Only include users with valid data

        setUsers(userData);
      } catch (error) {
        console.error('Error fetching recent users:', error);
        toast.error('Failed to load recent users');
      } finally {
        setLoading(false);
      }
    };

    fetchRecentUsers();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center py-4">
        <Loader2 className="h-6 w-6 animate-spin text-blue-500" />
      </div>
    );
  }

  if (users.length === 0) {
    return (
      <div className="text-center py-8">
        <Users className="h-12 w-12 mx-auto text-gray-400 mb-3" />
        <p className="text-gray-500">No users found</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold mb-4">Recent Users</h3>
      <div className="divide-y divide-gray-200">
        {users.map((user) => (
          <Link
            key={user.uid}
            to={`/profile/${user.username}`}
            className="flex items-center space-x-3 p-4 hover:bg-gray-50 transition-colors"
          >
            <img
              src={user.photoURL}
              alt={user.name}
              className="w-10 h-10 rounded-full"
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.src = `https://api.dicebear.com/7.x/avataaars/svg?seed=${user.uid}`;
              }}
            />
            <div className="flex-1 min-w-0">
              <div className="flex items-center space-x-2">
                <p className="font-medium text-gray-900 truncate">
                  {user.name}
                </p>
                <VerificationBadge alliesCount={user.allies?.length || 0} />
              </div>
              <p className="text-sm text-gray-500 truncate">
                @{user.username}
              </p>
              <p className="text-xs text-gray-400">
                Joined {user.createdAt.toLocaleDateString()}
              </p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}