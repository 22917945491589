import { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { LogOut, Loader2, MessageSquarePlus } from 'lucide-react';
import { auth } from '../lib/firebase';
import { Link } from 'react-router-dom';
import { CreateCommunity } from '../components/CreateCommunity';
import { MainMenu } from '../components/MainMenu';
import toast from 'react-hot-toast';

interface Community {
  id: string;
  title: string;
  question: string;
  imageUrl?: string;
  authorId: string;
  timestamp: Date;
  participants: string[];
}

export function Communities() {
  const [communities, setCommunities] = useState<Community[]>([]);
  const [loading, setLoading] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    const fetchCommunities = async () => {
      try {
        const communitiesRef = collection(db, 'communities');
        const q = query(communitiesRef, orderBy('lastResponseAt', 'desc'));
        const snapshot = await getDocs(q);

        const communitiesData = await Promise.all(snapshot.docs.map(async (doc) => {
          const data = doc.data();
          
          return {
            id: doc.id,
            ...data,
            timestamp: data.timestamp?.toDate(),
            lastResponseAt: data.lastResponseAt?.toDate() || data.timestamp?.toDate(),
            participants: data.participants || []
          };
        }));

        setCommunities(communitiesData as Community[]);
      } catch (error) {
        console.error('Error fetching communities:', error);
        toast.error('Failed to load communities');
      } finally {
        setLoading(false);
      }
    };

    fetchCommunities();
  }, []);

  const handleCreateCommunity = async (title: string, question: string, imageFile: File | null) => {
    if (!user) return;

    try {
      let imageUrl = '';
      
      if (imageFile) {
        const storageRef = ref(storage, `communities/${Date.now()}-${imageFile.name}`);
        await uploadBytes(storageRef, imageFile);
        imageUrl = await getDownloadURL(storageRef);
      }

      const newCommunity = {
        title: title.trim(),
        question: question.trim(),
        imageUrl,
        authorId: user.uid,
        authorName: user.displayName || '',
        authorUsername: user.displayName?.replace('@', '') || '',
        authorPhotoURL: user.photoURL,
        timestamp: serverTimestamp(),
        lastResponseAt: serverTimestamp(),
        participants: [user.uid]
      };

      const docRef = await addDoc(collection(db, 'communities'), newCommunity);
      
      setCommunities(prev => [{
        ...newCommunity,
        id: docRef.id,
        timestamp: new Date(),
        lastResponseAt: new Date(),
        participants: [user.uid]
      } as Community, ...prev]);

      setShowCreateModal(false);
      toast.success('Community created successfully!');
    } catch (error) {
      console.error('Error creating community:', error);
      toast.error('Failed to create community');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <h1 className="text-xl font-bold">Poosting</h1>
          <div className="flex items-center space-x-2">
            <button
              onClick={() => setShowCreateModal(true)}
              className="p-2 bg-black text-white rounded-full hover:bg-gray-800"
              title="Create new community"
            >
              <MessageSquarePlus className="h-5 w-5" />
            </button>
            <button
              onClick={() => auth.signOut()}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <LogOut className="h-5 w-5" />
            </button>
          </div>
        </div>
        <MainMenu />
      </header>

      <main className="max-w-2xl mx-auto bg-white border-x border-gray-200 min-h-screen">
        {loading ? (
          <div className="flex justify-center items-center p-8">
            <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
          </div>
        ) : communities.length > 0 ? (
          <div className="divide-y divide-gray-200">
            {communities.map((community) => (
              <Link
                key={community.id}
                to={`/communities/${community.id}`}
                className="block p-4 hover:bg-gray-50 transition-colors"
              >
                <div className="flex items-start space-x-4">
                  {community.imageUrl ? (
                    <img
                      src={community.imageUrl}
                      alt={community.title}
                      className="w-20 h-20 rounded-lg object-cover"
                    />
                  ) : (
                    <div className="w-20 h-20 bg-gray-100 rounded-lg flex items-center justify-center">
                      <MessageSquarePlus className="h-8 w-8 text-gray-400" />
                    </div>
                  )}
                  <div className="flex-1 min-w-0">
                    <div className="flex items-start justify-between">
                      <div>
                        <h2 className="text-lg font-semibold text-gray-900 mb-1">
                          {community.title}
                        </h2>
                        <p className="text-gray-600 line-clamp-2 mb-2">
                          {community.question}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4 text-sm text-gray-500">
                      <div className="flex items-center space-x-1">
                        <span>{community.participants.length} members</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <div className="p-8 text-center text-gray-500">
            <p className="text-lg mb-2">No communities yet</p>
            <p className="text-sm">Be the first to create a community!</p>
          </div>
        )}
      </main>

      {showCreateModal && (
        <CreateCommunity
          onClose={() => setShowCreateModal(false)}
          onCreate={handleCreateCommunity}
        />
      )}
    </div>
  );
}