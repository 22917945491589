import { auth, db, storage } from '../lib/firebase';
import { collection, query, where, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { ref, deleteObject, listAll } from 'firebase/storage';
import toast from 'react-hot-toast';

const ADMIN_USERNAME = 'afonsoalcantara';

export async function isAdmin(username: string): Promise<boolean> {
  return username.toLowerCase() === ADMIN_USERNAME.toLowerCase();
}

export async function updateAdminCoins() {
  try {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('username', '==', 'afonsoalcantaraweb'));
    const snapshot = await getDocs(q);

    if (!snapshot.empty) {
      const userDoc = snapshot.docs[0];
      await updateDoc(doc(db, 'users', userDoc.id), {
        coins: 100000
      });
      toast.success('Admin coins updated successfully');
    }
  } catch (error) {
    console.error('Error updating admin coins:', error);
    throw error;
  }
}

export async function deleteAnyPost(postId: string, currentUsername: string) {
  if (!await isAdmin(currentUsername)) {
    throw new Error('Unauthorized: Admin access required');
  }

  try {
    // Delete post media from storage
    const storageRef = ref(storage, `posts/${postId}`);
    try {
      const mediaFiles = await listAll(storageRef);
      await Promise.all(mediaFiles.items.map(file => deleteObject(file)));
    } catch (error) {
      console.error('Error deleting post media:', error);
    }

    // Delete post comments
    const commentsRef = collection(db, 'posts', postId, 'comments');
    const commentsSnapshot = await getDocs(commentsRef);
    await Promise.all(commentsSnapshot.docs.map(doc => deleteDoc(doc.ref)));

    // Delete post notifications
    const notificationsRef = collection(db, 'notifications');
    const notificationsQuery = query(notificationsRef, where('postId', '==', postId));
    const notificationsSnapshot = await getDocs(notificationsQuery);
    await Promise.all(notificationsSnapshot.docs.map(doc => deleteDoc(doc.ref)));

    // Delete the post
    await deleteDoc(doc(db, 'posts', postId));

    toast.success('Post deleted by admin');
  } catch (error) {
    console.error('Error deleting post:', error);
    throw error;
  }
}

export async function deleteAnyUser(userId: string, currentUsername: string) {
  if (!await isAdmin(currentUsername)) {
    throw new Error('Unauthorized: Admin access required');
  }

  try {
    // Delete user's posts
    const postsRef = collection(db, 'posts');
    const postsQuery = query(postsRef, where('authorId', '==', userId));
    const postsSnapshot = await getDocs(postsQuery);
    
    for (const postDoc of postsSnapshot.docs) {
      await deleteAnyPost(postDoc.id, currentUsername);
    }

    // Delete user's stories
    const storiesRef = collection(db, 'stories');
    const storiesQuery = query(storiesRef, where('authorId', '==', userId));
    const storiesSnapshot = await getDocs(storiesQuery);
    
    for (const storyDoc of storiesSnapshot.docs) {
      await deleteAnyStory(storyDoc.id, currentUsername);
    }

    // Delete user's notifications
    const notificationsRef = collection(db, 'notifications');
    const notificationsQuery = query(
      notificationsRef, 
      where('recipientId', '==', userId)
    );
    const notificationsSnapshot = await getDocs(notificationsQuery);
    await Promise.all(notificationsSnapshot.docs.map(doc => deleteDoc(doc.ref)));

    // Delete user's avatar
    try {
      const avatarRef = ref(storage, `avatars/${userId}`);
      await deleteObject(avatarRef);
    } catch (error) {
      console.error('Error deleting user avatar:', error);
    }

    // Delete user document
    await deleteDoc(doc(db, 'users', userId));

    toast.success('User deleted by admin');
  } catch (error) {
    console.error('Error deleting user:', error);
    throw error;
  }
}

export async function deleteAnyStory(storyId: string, currentUsername: string) {
  if (!await isAdmin(currentUsername)) {
    throw new Error('Unauthorized: Admin access required');
  }

  try {
    // Delete story media from storage
    const storageRef = ref(storage, `stories/${storyId}`);
    try {
      await deleteObject(storageRef);
    } catch (error) {
      console.error('Error deleting story media:', error);
    }

    // Delete the story document
    await deleteDoc(doc(db, 'stories', storyId));

    toast.success('Story deleted by admin');
  } catch (error) {
    console.error('Error deleting story:', error);
    throw error;
  }
}