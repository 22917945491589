import { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import { X, Loader2, Crosshair } from 'lucide-react';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import toast from 'react-hot-toast';

// Fix for default marker icons in react-leaflet
delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

interface LocationPickerProps {
  onLocationSelect: (coords: { latitude: number; longitude: number }) => void;
  onClose: () => void;
}

function LocationMarker({ 
  position, 
  onLocationSelect 
}: { 
  position: L.LatLng | null;
  onLocationSelect: (coords: { latitude: number; longitude: number }) => void;
}) {
  const map = useMapEvents({
    click(e) {
      onLocationSelect({ latitude: e.latlng.lat, longitude: e.latlng.lng });
    },
  });

  return position === null ? null : (
    <Marker position={position} />
  );
}

export function LocationPicker({ onLocationSelect, onClose }: LocationPickerProps) {
  const [loading, setLoading] = useState(true);
  const [position, setPosition] = useState<L.LatLng | null>(null);
  const [map, setMap] = useState<L.Map | null>(null);
  const [watchId, setWatchId] = useState<number | null>(null);

  const handleLocationUpdate = (coords: GeolocationCoordinates) => {
    const newPosition = new L.LatLng(coords.latitude, coords.longitude);
    setPosition(newPosition);
    map?.setView(newPosition, 15);
    onLocationSelect({ latitude: coords.latitude, longitude: coords.longitude });
  };

  const startWatchingLocation = () => {
    if ('geolocation' in navigator) {
      // Get initial position
      navigator.geolocation.getCurrentPosition(
        (position) => {
          handleLocationUpdate(position.coords);
          setLoading(false);
        },
        (error) => {
          console.error('Error getting location:', error);
          toast.error('Could not get your location');
          // Default to São Paulo
          const defaultPosition = new L.LatLng(-23.5505, -46.6333);
          setPosition(defaultPosition);
          setLoading(false);
        },
        { enableHighAccuracy: true }
      );

      // Start watching position
      const id = navigator.geolocation.watchPosition(
        (position) => handleLocationUpdate(position.coords),
        (error) => console.error('Error watching location:', error),
        { enableHighAccuracy: true }
      );
      setWatchId(id);
    } else {
      toast.error('Geolocation is not supported by your browser');
      setLoading(false);
    }
  };

  useEffect(() => {
    startWatchingLocation();
    return () => {
      if (watchId !== null) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, [map]);

  const handleMapClick = (e: L.LeafletMouseEvent) => {
    setPosition(e.latlng);
    onLocationSelect({ latitude: e.latlng.lat, longitude: e.latlng.lng });
  };

  const recenterMap = () => {
    if ('geolocation' in navigator) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          handleLocationUpdate(position.coords);
          setLoading(false);
        },
        (error) => {
          console.error('Error getting location:', error);
          toast.error('Could not get your location');
          setLoading(false);
        },
        { enableHighAccuracy: true }
      );
    }
  };

  if (loading) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-4 rounded-lg">
          <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-lg p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Share Location</h2>
          <div className="flex items-center space-x-2">
            <button
              onClick={recenterMap}
              className="p-2 hover:bg-gray-100 rounded-full text-blue-500"
              title="Use current location"
            >
              <Crosshair className="h-5 w-5" />
            </button>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>

        <div className="h-96 rounded-lg overflow-hidden">
          <MapContainer
            center={position?.toJSON() || [-23.5505, -46.6333]}
            zoom={15}
            style={{ height: '100%', width: '100%' }}
            whenCreated={setMap}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <LocationMarker position={position} onLocationSelect={onLocationSelect} />
          </MapContainer>
        </div>

        <div className="mt-4 text-sm text-gray-500 text-center space-y-2">
          <p>Your current location is automatically detected</p>
          <p>Tap anywhere on the map to change the location</p>
        </div>
      </div>
    </div>
  );
}