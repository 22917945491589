import { useRef, useState } from 'react';
import { Camera } from 'lucide-react';
import { ImageEditor } from './ImageEditor';

interface ImageUploadProps {
  imagePreview: string | null;
  onImageSelect: (file: File) => void;
}

export function ImageUpload({ imagePreview, onImageSelect }: ImageUploadProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showEditor, setShowEditor] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result as string);
        setShowEditor(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCroppedImage = async (blob: Blob) => {
    const file = new File([blob], 'profile-photo.jpg', { type: 'image/jpeg' });
    onImageSelect(file);
    setShowEditor(false);
    setSelectedImage(null);
  };

  return (
    <>
      <div className="flex justify-center mb-4">
        <div className="relative">
          <div
            onClick={() => fileInputRef.current?.click()}
            className="w-24 h-24 rounded-full bg-gray-200 flex items-center justify-center cursor-pointer overflow-hidden group"
          >
            {imagePreview ? (
              <img
                src={imagePreview}
                alt="Profile preview"
                className="w-full h-full object-cover"
              />
            ) : (
              <Camera className="h-8 w-8 text-gray-400 group-hover:text-gray-500" />
            )}
          </div>
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            onChange={handleImageSelect}
            className="hidden"
          />
        </div>
      </div>

      {showEditor && selectedImage && (
        <ImageEditor
          image={selectedImage}
          onSave={handleCroppedImage}
          onCancel={() => {
            setShowEditor(false);
            setSelectedImage(null);
          }}
          aspectRatio={1}
        />
      )}
    </>
  );
}