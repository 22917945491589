import { useState } from 'react';
import { Heart, Star, Trash2, Flame, Loader2 } from 'lucide-react';
import { doc, updateDoc, arrayUnion, arrayRemove, addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import toast from 'react-hot-toast';

interface ProfileVotesProps {
  profileId: string;
  currentUserId: string | undefined;
  initialVotes: {
    likes: string[];
    stars: string[];
    trash: string[];
    matches: string[];
  };
  profileName: string;
  profileUsername: string;
}

export function ProfileVotes({ 
  profileId, 
  currentUserId, 
  initialVotes,
  profileName,
  profileUsername
}: ProfileVotesProps) {
  const [votes, setVotes] = useState(initialVotes);
  const [isVoting, setIsVoting] = useState(false);

  const handleVote = async (type: 'likes' | 'stars' | 'trash' | 'matches') => {
    if (!currentUserId) {
      toast.error('Please sign in to vote');
      return;
    }

    if (currentUserId === profileId) {
      toast.error('You cannot vote on your own profile');
      return;
    }

    if (isVoting) return;

    try {
      setIsVoting(true);
      const userRef = doc(db, 'users', profileId);
      const hasVoted = votes[type].includes(currentUserId);

      // Remove vote from all categories first
      const updates: any = {};
      ['likes', 'stars', 'trash', 'matches'].forEach(voteType => {
        if (votes[voteType].includes(currentUserId)) {
          updates[voteType] = arrayRemove(currentUserId);
        }
      });

      // Add new vote if not removing
      if (!hasVoted) {
        updates[type] = arrayUnion(currentUserId);
      }

      await updateDoc(userRef, updates);

      // Create notification
      if (!hasVoted) {
        await addDoc(collection(db, 'notifications'), {
          type: `profile_${type.slice(0, -1)}`,
          recipientId: profileId,
          senderId: currentUserId,
          senderName: profileName,
          senderUsername: profileUsername,
          timestamp: serverTimestamp(),
          read: false
        });
      }

      // Update local state
      setVotes(prev => {
        const newVotes = {
          likes: prev.likes.filter(id => id !== currentUserId),
          stars: prev.stars.filter(id => id !== currentUserId),
          trash: prev.trash.filter(id => id !== currentUserId),
          matches: prev.matches.filter(id => id !== currentUserId)
        };
        if (!hasVoted) {
          newVotes[type] = [...newVotes[type], currentUserId];
        }
        return newVotes;
      });

      const voteTypes = {
        likes: 'liked',
        stars: 'starred',
        trash: 'marked as trash',
        matches: 'matched with'
      };

      if (!hasVoted) {
        toast.success(`Profile ${voteTypes[type]}`);
      } else {
        toast.success('Vote removed');
      }
    } catch (error) {
      console.error('Error voting:', error);
      toast.error('Failed to vote');
    } finally {
      setIsVoting(false);
    }
  };

  return (
    <div className="flex items-center justify-center space-x-6 py-4 border-t border-gray-200">
      <button
        onClick={() => handleVote('likes')}
        disabled={isVoting}
        className={`flex flex-col items-center space-y-1 ${
          votes.likes.includes(currentUserId || '')
            ? 'text-red-500'
            : 'text-gray-500 hover:text-red-500'
        } transition-colors disabled:opacity-50`}
        title="Like"
      >
        <Heart className={`h-6 w-6 ${
          votes.likes.includes(currentUserId || '') ? 'fill-current' : ''
        }`} />
        <span className="text-sm">{votes.likes.length}</span>
      </button>

      <button
        onClick={() => handleVote('stars')}
        disabled={isVoting}
        className={`flex flex-col items-center space-y-1 ${
          votes.stars.includes(currentUserId || '')
            ? 'text-yellow-500'
            : 'text-gray-500 hover:text-yellow-500'
        } transition-colors disabled:opacity-50`}
        title="Star"
      >
        <Star className={`h-6 w-6 ${
          votes.stars.includes(currentUserId || '') ? 'fill-current' : ''
        }`} />
        <span className="text-sm">{votes.stars.length}</span>
      </button>

      <button
        onClick={() => handleVote('trash')}
        disabled={isVoting}
        className={`flex flex-col items-center space-y-1 ${
          votes.trash.includes(currentUserId || '')
            ? 'text-red-500'
            : 'text-gray-500 hover:text-red-500'
        } transition-colors disabled:opacity-50`}
        title="Mark as trash"
      >
        <Trash2 className="h-6 w-6" />
        <span className="text-sm">{votes.trash.length}</span>
      </button>

      <button
        onClick={() => handleVote('matches')}
        disabled={isVoting}
        className={`flex flex-col items-center space-y-1 ${
          votes.matches.includes(currentUserId || '')
            ? 'text-red-500'
            : 'text-gray-500 hover:text-red-500'
        } transition-colors disabled:opacity-50`}
        title="Match"
      >
        <Flame className="h-6 w-6" />
        <span className="text-sm">{votes.matches.length}</span>
      </button>

      {isVoting && (
        <Loader2 className="h-4 w-4 animate-spin text-blue-500" />
      )}
    </div>
  );
}