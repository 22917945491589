import { doc, updateDoc, arrayUnion, arrayRemove, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import toast from 'react-hot-toast';

interface JoinCommunityResult {
  success: boolean;
  error?: string;
}

export async function joinCommunity(communityId: string, userId: string): Promise<JoinCommunityResult> {
  try {
    // Validate inputs
    if (!communityId || !userId) {
      throw new Error('Invalid community or user ID');
    }

    // Get community reference
    const communityRef = doc(db, 'communities', communityId);
    const communityDoc = await getDoc(communityRef);

    if (!communityDoc.exists()) {
      throw new Error('Community not found');
    }

    // Get current participants
    const currentParticipants = communityDoc.data().participants || [];

    // Check if user is already a participant
    if (currentParticipants.includes(userId)) {
      throw new Error('Already a member of this community');
    }

    // Add user to participants
    await updateDoc(communityRef, {
      participants: arrayUnion(userId)
    });

    return { success: true };
  } catch (error: any) {
    console.error('Error joining community:', error);
    return {
      success: false,
      error: error.message || 'Failed to join community'
    };
  }
}

export async function leaveCommunity(communityId: string, userId: string): Promise<JoinCommunityResult> {
  try {
    // Validate inputs
    if (!communityId || !userId) {
      throw new Error('Invalid community or user ID');
    }

    // Get community reference
    const communityRef = doc(db, 'communities', communityId);
    const communityDoc = await getDoc(communityRef);

    if (!communityDoc.exists()) {
      throw new Error('Community not found');
    }

    // Get current participants
    const currentParticipants = communityDoc.data().participants || [];

    // Check if user is a participant
    if (!currentParticipants.includes(userId)) {
      throw new Error('Not a member of this community');
    }

    // Remove user from participants
    await updateDoc(communityRef, {
      participants: arrayRemove(userId)
    });

    return { success: true };
  } catch (error: any) {
    console.error('Error leaving community:', error);
    return {
      success: false,
      error: error.message || 'Failed to leave community'
    };
  }
}