import { Link, useLocation } from 'react-router-dom';
import { Eye, User, Bell, Search, Settings, Film, Music, MessageSquarePlus, Users, Youtube, MapPin, Trophy } from 'lucide-react';
import { useAuth } from './AuthProvider';
import { NotificationBadge } from './NotificationBadge';
import { ChatBadge } from './ChatBadge';
import clsx from 'clsx';

export function MainMenu() {
  const location = useLocation();
  const { user } = useAuth();

  if (!user) return null;

  const menuItems = [
    { icon: Eye, path: '/', title: 'Feed World' },
    { icon: Film, path: '/stories', title: 'Stories' },
    { icon: Search, path: '/search', title: 'Search Users' },
    { icon: Users, path: '/communities', title: 'Communities' },
    { icon: Music, path: '/music', title: 'Music' },
    { icon: Youtube, path: '/videos', title: 'Videos' },
    { icon: MapPin, path: '/places', title: 'Places' },
    { icon: Trophy, path: '/ranking', title: 'Ranking' },
    { 
      icon: User, 
      path: `/profile/${user.displayName?.replace('@', '')}`,
      title: 'Profile'
    },
    { 
      icon: Bell, 
      path: '/notifications',
      badge: true,
      title: 'Notifications'
    },
    {
      component: ChatBadge,
      path: '/chat',
      title: 'Chat'
    },
    {
      icon: Settings,
      path: '/config',
      title: 'Settings'
    }
  ];

  return (
    <nav className="sticky top-[57px] z-40 bg-white border-b border-gray-200 shadow-sm">
      <div className="max-w-2xl mx-auto">
        <div className="flex items-center overflow-x-auto scrollbar-hide py-2 px-4">
          {menuItems.map(({ icon: Icon, component: Component, path, badge, title }) => {
            const isActive = location.pathname === path;
            return (
              <Link
                key={path}
                to={path}
                className={clsx(
                  'flex items-center justify-center px-4 py-2 whitespace-nowrap transition-colors',
                  isActive ? 'text-black font-medium' : 'text-gray-500 hover:text-black'
                )}
                title={title}
              >
                {Component ? (
                  <Component />
                ) : badge ? (
                  <NotificationBadge />
                ) : Icon && (
                  <Icon className="h-5 w-5" />
                )}
              </Link>
            );
          })}
        </div>
      </div>
    </nav>
  );
}