import toast from 'react-hot-toast';

const MAX_VIDEO_SIZE = 25 * 1024 * 1024; // 25MB
const TARGET_SIZE = 10 * 1024 * 1024; // 10MB target size for optimized videos
const MAX_DIMENSION = 1280; // Maximum dimension while maintaining aspect ratio
const MAX_DURATION = 30; // 30 seconds maximum duration

export async function optimizeVideo(file: File): Promise<File> {
  if (!file.type.startsWith('video/')) {
    throw new Error('Invalid file type. Please upload a video file.');
  }

  try {
    // Create video element to get metadata
    const videoElement = document.createElement('video');
    videoElement.muted = true;
    const videoUrl = URL.createObjectURL(file);
    
    // Wait for video metadata to load
    await new Promise((resolve, reject) => {
      videoElement.onloadedmetadata = resolve;
      videoElement.onerror = () => reject(new Error('Failed to load video metadata'));
      videoElement.src = videoUrl;
    });

    // Check duration
    if (videoElement.duration > MAX_DURATION) {
      URL.revokeObjectURL(videoUrl);
      throw new Error(`Video must be ${MAX_DURATION} seconds or less`);
    }

    // If file is already small enough, return it
    if (file.size <= TARGET_SIZE) {
      URL.revokeObjectURL(videoUrl);
      return file;
    }

    // Calculate new dimensions maintaining aspect ratio
    let width = videoElement.videoWidth;
    let height = videoElement.videoHeight;
    
    if (width > height) {
      if (width > MAX_DIMENSION) {
        height = Math.round((height * MAX_DIMENSION) / width);
        width = MAX_DIMENSION;
      }
    } else {
      if (height > MAX_DIMENSION) {
        width = Math.round((width * MAX_DIMENSION) / height);
        height = MAX_DIMENSION;
      }
    }

    // Create canvas for video frames
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    
    if (!ctx) {
      URL.revokeObjectURL(videoUrl);
      throw new Error('Could not get canvas context');
    }

    // Create MediaRecorder with optimized settings
    const stream = canvas.captureStream();
    const mediaRecorder = new MediaRecorder(stream, {
      mimeType: 'video/webm;codecs=vp9',
      videoBitsPerSecond: 2500000 // 2.5 Mbps
    });

    const chunks: Blob[] = [];
    mediaRecorder.ondataavailable = (e) => {
      if (e.data.size > 0) {
        chunks.push(e.data);
      }
    };

    // Start recording
    mediaRecorder.start(1000); // Capture in 1-second chunks

    // Process the video
    return new Promise((resolve, reject) => {
      let frameCount = 0;
      const processFrame = () => {
        if (videoElement.ended || videoElement.paused) {
          mediaRecorder.stop();
          return;
        }

        try {
          ctx.drawImage(videoElement, 0, 0, width, height);
          frameCount++;
          requestAnimationFrame(processFrame);
        } catch (error) {
          console.error('Frame processing error:', error);
          // Continue processing despite frame errors
        }
      };

      videoElement.onplay = () => {
        requestAnimationFrame(processFrame);
      };

      videoElement.onended = () => {
        mediaRecorder.stop();
      };

      mediaRecorder.onstop = async () => {
        try {
          stream.getTracks().forEach(track => track.stop());
          URL.revokeObjectURL(videoUrl);

          const blob = new Blob(chunks, { type: 'video/webm' });
          const optimizedFile = new File([blob], file.name.replace(/\.[^/.]+$/, '.webm'), {
            type: 'video/webm'
          });

          if (optimizedFile.size > MAX_VIDEO_SIZE) {
            reject(new Error('Video is too large even after optimization'));
            return;
          }

          resolve(optimizedFile);
        } catch (error) {
          reject(error);
        }
      };

      mediaRecorder.onerror = (event) => {
        reject(new Error('MediaRecorder error: ' + event.error));
      };

      // Start playing the video
      videoElement.play().catch(reject);
    });

  } catch (error: any) {
    console.error('Video optimization failed:', error);
    toast.error(error.message || 'Failed to process video. Please try again.');
    throw error;
  }
}