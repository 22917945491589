import { Trophy, Heart } from 'lucide-react';
import { Link } from 'react-router-dom';
import { RankingItem } from '../../utils/ranking/types';
import { VerificationBadge } from '../VerificationBadge';

interface RankingListProps {
  rankings: RankingItem[];
}

export function RankingList({ rankings }: RankingListProps) {
  const getMedalColor = (position: number) => {
    switch (position) {
      case 1:
        return 'text-yellow-500';
      case 2:
        return 'text-gray-400';
      case 3:
        return 'text-amber-600';
      default:
        return 'text-gray-400';
    }
  };

  return (
    <div className="divide-y divide-gray-200">
      {rankings.map((ranking, index) => (
        <Link
          key={ranking.userId}
          to={`/profile/${ranking.username}`}
          className="flex items-center p-4 hover:bg-gray-50 transition-colors"
        >
          <div className="flex items-center flex-1">
            {/* Position/Trophy */}
            <div className="w-8 text-center font-bold">
              {index + 1 <= 3 ? (
                <Trophy className={`h-6 w-6 ${getMedalColor(index + 1)}`} />
              ) : (
                <span className="text-gray-500">#{index + 1}</span>
              )}
            </div>
            
            {/* User Info */}
            <div className="flex items-center flex-1 min-w-0">
              <img
                src={ranking.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${ranking.userId}`}
                alt={ranking.name}
                className="w-12 h-12 rounded-full mx-4"
              />
              
              <div className="flex-1 min-w-0">
                <div className="flex items-center space-x-2">
                  <h3 className="font-medium text-gray-900 truncate">
                    {ranking.name}
                  </h3>
                  <VerificationBadge alliesCount={ranking.alliesCount} />
                </div>
                <p className="text-sm text-gray-500 truncate">
                  @{ranking.username}
                </p>
              </div>
            </div>
          </div>

          {/* Likes Count */}
          <div className="flex items-center space-x-1 text-red-500 ml-4">
            <Heart className="h-5 w-5 fill-current" />
            <span className="font-bold">{ranking.likesCount.toLocaleString()}</span>
          </div>
        </Link>
      ))}
    </div>
  );
}