import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: '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',
  dangerouslyAllowBrowser: true
});

export async function getChatCompletion(message: string): Promise<string> {
  try {
    const response = await openai.chat.completions.create({
      model: 'gpt-3.5-turbo',
      messages: [
        {
          role: 'system',
          content: 'You are a helpful assistant that provides clear and concise answers.'
        },
        { 
          role: 'user', 
          content: message 
        }
      ],
      temperature: 0.7,
      max_tokens: 500,
    });

    const aiResponse = response.choices[0]?.message?.content;
    if (!aiResponse) {
      throw new Error('No response generated');
    }

    return aiResponse;
  } catch (error: any) {
    console.error('OpenAI API error:', error);

    // Handle specific OpenAI error types
    if (error.error?.type === 'invalid_request_error') {
      throw new Error('Invalid request. Please try again with a different question.');
    }
    
    if (error.error?.type === 'billing_not_active') {
      throw new Error('AI service is temporarily unavailable. Please try again later.');
    }

    if (error.status === 429) {
      throw new Error('Too many requests. Please wait a moment before trying again.');
    }

    if (error.status === 401 || error.status === 403) {
      throw new Error('Authentication error. Please try again later.');
    }

    if (error.status >= 500) {
      throw new Error('AI service is experiencing issues. Please try again later.');
    }

    // Generic error fallback
    throw new Error('Failed to get AI response. Please try again.');
  }
}