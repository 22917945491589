import { useState, useEffect } from 'react';
import { LogOut, Loader2, Search, Youtube } from 'lucide-react';
import { auth } from '../lib/firebase';
import YouTube from 'react-youtube';
import { MainMenu } from '../components/MainMenu';
import axios from 'axios';
import toast from 'react-hot-toast';

interface Video {
  id: string | {
    kind: string;
    videoId: string;
  };
  snippet: {
    title: string;
    description: string;
    thumbnails: {
      high: {
        url: string;
      };
    };
    channelTitle: string;
    publishedAt: string;
  };
}

export function Videos() {
  const [videos, setVideos] = useState<Video[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);

  useEffect(() => {
    fetchTrendingVideos();
  }, []);

  const getVideoId = (video: Video): string => {
    return typeof video.id === 'string' ? video.id : video.id.videoId;
  };

  const fetchTrendingVideos = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/videos`,
        {
          params: {
            part: 'snippet',
            chart: 'mostPopular',
            maxResults: 50,
            regionCode: 'US',
            key: 'AIzaSyBuJT7El4mSrV6GjcknUnKvHbjrsSXuyf8'
          }
        }
      );

      setVideos(response.data.items);
    } catch (error) {
      console.error('Error fetching trending videos:', error);
      toast.error('Failed to load videos');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!searchQuery.trim()) return;

    try {
      setLoading(true);
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/search`,
        {
          params: {
            part: 'snippet',
            q: searchQuery,
            maxResults: 50,
            type: 'video',
            key: 'AIzaSyBuJT7El4mSrV6GjcknUnKvHbjrsSXuyf8'
          }
        }
      );

      setVideos(response.data.items);
    } catch (error) {
      console.error('Error searching videos:', error);
      toast.error('Failed to search videos');
    } finally {
      setLoading(false);
    }
  };

  const getSelectedVideo = () => {
    if (!selectedVideo) return null;
    return videos.find(video => getVideoId(video) === selectedVideo);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <Youtube className="h-6 w-6 text-red-500" />
            <h1 className="text-xl font-bold">Poosting Videos</h1>
          </div>
          <button
            onClick={() => auth.signOut()}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <LogOut className="h-5 w-5" />
          </button>
        </div>
        <div className="max-w-2xl mx-auto px-4 py-3">
          <form onSubmit={handleSearch}>
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search videos..."
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
          </form>
        </div>
        <MainMenu />
      </header>

      <main className="max-w-2xl mx-auto bg-white border-x border-gray-200 min-h-screen">
        {selectedVideo ? (
          <div className="p-4">
            <button
              onClick={() => setSelectedVideo(null)}
              className="mb-4 text-blue-500 hover:text-blue-600 flex items-center space-x-2"
            >
              <span>← Back to videos</span>
            </button>
            <div className="aspect-video mb-4">
              <YouTube
                videoId={selectedVideo}
                opts={{
                  width: '100%',
                  height: '100%',
                  playerVars: {
                    autoplay: 1,
                  },
                }}
                className="w-full h-full"
              />
            </div>
            {getSelectedVideo()?.snippet && (
              <div className="space-y-2">
                <h2 className="text-xl font-bold">
                  {getSelectedVideo()?.snippet.title}
                </h2>
                <p className="text-gray-600">
                  {getSelectedVideo()?.snippet.description}
                </p>
                <p className="text-sm text-gray-500">
                  {getSelectedVideo()?.snippet.channelTitle}
                </p>
              </div>
            )}
          </div>
        ) : loading ? (
          <div className="flex justify-center items-center p-8">
            <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-4">
            {videos.map((video) => (
              <div
                key={getVideoId(video)}
                className="cursor-pointer hover:bg-gray-50 rounded-lg overflow-hidden transition-colors"
                onClick={() => setSelectedVideo(getVideoId(video))}
              >
                <img
                  src={video.snippet.thumbnails.high.url}
                  alt={video.snippet.title}
                  className="w-full aspect-video object-cover"
                />
                <div className="p-3">
                  <h3 className="font-medium line-clamp-2">{video.snippet.title}</h3>
                  <p className="text-sm text-gray-500 mt-1">{video.snippet.channelTitle}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </main>
    </div>
  );
}