import { useState, useEffect, useRef } from 'react';
import { CreatePost } from '../components/CreatePost';
import { Post } from '../components/Post';
import { LogOut, Loader2 } from 'lucide-react';
import { auth } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { StoriesGallery } from '../components/StoriesGallery';
import { ContentFilter, FilterOption } from '../components/ContentFilter';
import { subscribeToFeed, fetchMorePosts, PostData } from '../utils/posts';
import toast from 'react-hot-toast';

const RSS_FEED_IDS = [
  'PabKA4pTOCrZvKu5', 'iFY9b0OPoBmW7hbt', 'GWzyraAYkkQqA2nf',
  '6FlIKxlhbABJb0AA', 'wdv35hxbF1qEt5tx', 'mkk0kMzACB4jvJfO',
  'cAtGP8wRrVSeKqrf', 'uPHICAWQ3qW7790Z', 'vSrfBCcgrJLncO9F',
  'LzUWww8p1lHVkIT3', 'qhHMVmZSEBROK8Dg', 'RIQ3k2l7k8bUG8Rd',
  'cNnSefd443HSoYeS', 'nmT9Vwd1rYcucpSK', 'polAJNqtHFb7NA57'
];

export function Home() {
  const [posts, setPosts] = useState<PostData[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [filterOption, setFilterOption] = useState<FilterOption>('all');
  const lastPostRef = useRef<any>(null);
  const observerRef = useRef<IntersectionObserver>();
  const loadingRef = useRef<HTMLDivElement>(null);
  const unsubscribeRef = useRef<() => void>();
  const { user } = useAuth();

  useEffect(() => {
    if (!user) return;

    setLoading(true);
    setPosts([]);
    lastPostRef.current = null;

    if (unsubscribeRef.current) {
      unsubscribeRef.current();
    }

    if (filterOption === 'news') {
      setLoading(false);
      return;
    }

    const unsubscribe = subscribeToFeed(
      user.uid,
      filterOption,
      (updatedPosts) => {
        const uniquePosts = Array.from(
          new Map(updatedPosts.map(post => [post.id, post])).values()
        );
        setPosts(uniquePosts);
        lastPostRef.current = uniquePosts[uniquePosts.length - 1] || null;
        setHasMore(uniquePosts.length === 10);
        setLoading(false);
      },
      (error) => {
        console.error('Feed subscription error:', error);
        toast.error('Failed to load posts');
        setLoading(false);
      }
    );

    unsubscribeRef.current = unsubscribe;

    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
      }
    };
  }, [user, filterOption]);

  const loadMorePosts = async () => {
    if (loadingMore || !hasMore || !lastPostRef.current || !user) return;

    try {
      setLoadingMore(true);

      const { posts: newPosts, lastDoc, hasMore: hasMorePosts } = 
        await fetchMorePosts(user.uid, filterOption, lastPostRef.current);

      setPosts(prevPosts => {
        const allPosts = [...prevPosts, ...newPosts];
        return Array.from(
          new Map(allPosts.map(post => [post.id, post])).values()
        );
      });
      
      lastPostRef.current = lastDoc;
      setHasMore(hasMorePosts);
    } catch (error) {
      console.error('Error loading more posts:', error);
      toast.error('Failed to load more posts');
    } finally {
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !loadingMore) {
          loadMorePosts();
        }
      },
      { threshold: 0.5 }
    );

    observerRef.current = observer;

    return () => observer.disconnect();
  }, [hasMore, loadingMore, filterOption]);

  useEffect(() => {
    const currentObserver = observerRef.current;
    const currentLoadingRef = loadingRef.current;

    if (currentLoadingRef && currentObserver) {
      currentObserver.observe(currentLoadingRef);
    }

    return () => {
      if (currentLoadingRef && currentObserver) {
        currentObserver.unobserve(currentLoadingRef);
      }
    };
  }, [posts]);

  const handleFilterChange = (option: FilterOption) => {
    setFilterOption(option);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto flex justify-between items-center px-4 py-3">
          <h1 
            className="text-xl font-bold cursor-pointer hover:opacity-80 transition-opacity"
            onClick={scrollToTop}
          >
            Poosting
          </h1>
          <div className="flex items-center space-x-4">
            <ContentFilter 
              onFilterChange={handleFilterChange}
              selectedOption={filterOption}
            />
            <button
              onClick={() => auth.signOut()}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <LogOut className="h-5 w-5" />
            </button>
          </div>
        </div>
      </header>

      <main className="max-w-2xl mx-auto bg-white border-x border-gray-200 min-h-screen">
        <StoriesGallery />
        <CreatePost />
        
        {filterOption === 'news' ? (
          <div className="divide-y divide-gray-200">
            {RSS_FEED_IDS.map((feedId) => (
              <div key={feedId} className="p-4">
                <rssapp-feed id={feedId}></rssapp-feed>
              </div>
            ))}
          </div>
        ) : (
          <div className="divide-y divide-gray-200">
            {posts.map((post) => (
              <Post key={post.id} {...post} />
            ))}
            {hasMore && (
              <div 
                ref={loadingRef}
                className="p-4 flex justify-center"
              >
                {loadingMore && (
                  <Loader2 className="h-6 w-6 animate-spin text-blue-500" />
                )}
              </div>
            )}
            {!hasMore && posts.length > 0 && (
              <div className="p-4 text-center text-gray-500">
                No more posts to load
              </div>
            )}
            {!hasMore && posts.length === 0 && (
              <div className="p-8 text-center text-gray-500">
                <p className="text-lg font-medium">
                  {filterOption === 'all' && 'No posts yet'}
                  {filterOption === 'mine' && 'You haven\'t created any posts yet'}
                </p>
                <p className="mt-1">
                  {filterOption === 'all' && 'Be the first to create a post!'}
                  {filterOption === 'mine' && 'Share something with the world!'}
                </p>
              </div>
            )}
          </div>
        )}
      </main>
    </div>
  );
}