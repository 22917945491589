import { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Fix for default marker icons in react-leaflet
delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

interface LocationMessageProps {
  latitude: number;
  longitude: number;
  senderName: string;
  senderPhotoURL: string;
  isFeed?: boolean;
}

export function LocationMessage({ latitude, longitude, senderName, senderPhotoURL, isFeed = false }: LocationMessageProps) {
  useEffect(() => {
    // Trigger a resize event after the map is mounted to ensure proper rendering
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);

    // Add custom popup styles
    const style = document.createElement('style');
    style.textContent = `
      .leaflet-popup {
        margin-bottom: 15px;
      }
      .leaflet-popup-content {
        margin: 0;
        min-width: 0 !important;
        width: auto !important;
      }
      .leaflet-popup-content-wrapper {
        padding: 0;
        border-radius: 12px;
        overflow: hidden;
        box-shadow: 0 2px 8px rgba(0,0,0,0.15);
      }
      .leaflet-popup-tip {
        box-shadow: 0 2px 8px rgba(0,0,0,0.15);
      }
      .location-popup {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        background: white;
        min-width: 150px;
        max-width: 200px;
      }
      .location-popup__photo {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        object-fit: cover;
        flex-shrink: 0;
        margin-right: 8px;
        border: 2px solid #fff;
        box-shadow: 0 1px 3px rgba(0,0,0,0.1);
      }
      .location-popup__name {
        font-size: 13px;
        font-weight: 500;
        color: #1a1a1a;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1;
      }
      .map-container {
        height: ${isFeed ? '300px' : '180px'} !important;
        width: 100% !important;
        min-width: ${isFeed ? '100%' : '200px'} !important;
        max-width: ${isFeed ? '100%' : '280px'} !important;
        border-radius: 12px;
        overflow: hidden;
        box-shadow: 0 2px 8px rgba(0,0,0,0.1);
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, [isFeed]);

  return (
    <div className="w-full rounded-lg overflow-hidden">
      <MapContainer
        center={[latitude, longitude]}
        zoom={14}
        className="map-container"
        zoomControl={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={[latitude, longitude]}>
          <Popup closeButton={false}>
            <div className="location-popup">
              <img
                src={senderPhotoURL}
                alt={senderName}
                className="location-popup__photo"
              />
              <span className="location-popup__name" title={senderName}>
                {senderName}
              </span>
            </div>
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
}