import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { collection, doc, getDoc, getDocs, addDoc, query, orderBy, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { LogOut, Loader2, ArrowLeft, Send, MessageSquare } from 'lucide-react';
import { auth } from '../lib/firebase';
import { formatDistanceToNow } from 'date-fns';
import { BottomNav } from '../components/BottomNav';
import ReactMarkdown from 'react-markdown';
import toast from 'react-hot-toast';

interface Topic {
  id: string;
  title: string;
  content: string;
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL: string;
  timestamp: Date;
  responseCount: number;
}

interface Response {
  id: string;
  content: string;
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL: string;
  timestamp: Date;
}

export function TopicDetails() {
  const { communityId, topicId } = useParams<{ communityId: string; topicId: string }>();
  const [topic, setTopic] = useState<Topic | null>(null);
  const [responses, setResponses] = useState<Response[]>([]);
  const [newResponse, setNewResponse] = useState('');
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    const fetchTopicAndResponses = async () => {
      if (!communityId || !topicId) return;

      try {
        setLoading(true);
        const topicDoc = await getDoc(doc(db, 'communities', communityId, 'topics', topicId));
        
        if (!topicDoc.exists()) {
          toast.error('Topic not found');
          return;
        }

        const topicData = {
          id: topicDoc.id,
          ...topicDoc.data(),
          timestamp: topicDoc.data().timestamp?.toDate(),
          responseCount: topicDoc.data().responseCount || 0
        } as Topic;

        setTopic(topicData);

        // Fetch responses
        const responsesRef = collection(db, 'communities', communityId, 'topics', topicId, 'responses');
        const q = query(responsesRef, orderBy('timestamp', 'asc'));
        const responsesSnapshot = await getDocs(q);

        const responsesData = responsesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate()
        })) as Response[];

        setResponses(responsesData);
      } catch (error) {
        console.error('Error fetching topic:', error);
        toast.error('Failed to load topic');
      } finally {
        setLoading(false);
      }
    };

    fetchTopicAndResponses();
  }, [communityId, topicId]);

  const handleSubmitResponse = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || !communityId || !topicId || !newResponse.trim() || submitting) return;

    const responseContent = newResponse.trim();
    setSubmitting(true);

    try {
      const topicRef = doc(db, 'communities', communityId, 'topics', topicId);
      const responseData = {
        content: responseContent,
        authorId: user.uid,
        authorName: user.displayName || '',
        authorUsername: user.displayName?.replace('@', '') || '',
        authorPhotoURL: user.photoURL,
        timestamp: serverTimestamp()
      };

      // Add response
      const responseRef = await addDoc(
        collection(db, 'communities', communityId, 'topics', topicId, 'responses'),
        responseData
      );

      // Update topic's lastResponseAt and responseCount
      await updateDoc(topicRef, {
        lastResponseAt: serverTimestamp(),
        responseCount: (topic?.responseCount || 0) + 1
      });

      // Update local state
      setResponses(prev => [...prev, {
        id: responseRef.id,
        ...responseData,
        timestamp: new Date()
      } as Response]);

      setTopic(prev => prev ? {
        ...prev,
        responseCount: (prev.responseCount || 0) + 1
      } : null);

      setNewResponse('');
      toast.success('Response added successfully');
    } catch (error) {
      console.error('Error adding response:', error);
      toast.error('Failed to add response. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  if (!topic) {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
        <h2 className="text-2xl font-bold mb-4">Topic not found</h2>
        <Link
          to={`/communities/${communityId}`}
          className="flex items-center space-x-2 text-blue-500 hover:text-blue-600"
        >
          <ArrowLeft className="h-5 w-5" />
          <span>Back to Community</span>
        </Link>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pb-16">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <Link
            to={`/communities/${communityId}`}
            className="flex items-center space-x-2"
          >
            <ArrowLeft className="h-5 w-5" />
            <span className="font-medium">Back</span>
          </Link>
          <button
            onClick={() => auth.signOut()}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <LogOut className="h-5 w-5" />
          </button>
        </div>
      </header>

      <main className="max-w-2xl mx-auto bg-white border-x border-gray-200 min-h-screen">
        <div className="p-4 border-b border-gray-200">
          <div className="flex items-start space-x-3 mb-4">
            <Link to={`/profile/${topic.authorUsername}`}>
              <img
                src={topic.authorPhotoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${topic.authorId}`}
                alt={topic.authorName}
                className="h-10 w-10 rounded-full"
              />
            </Link>
            <div>
              <Link 
                to={`/profile/${topic.authorUsername}`}
                className="font-medium hover:underline"
              >
                {topic.authorName}
              </Link>
              <p className="text-sm text-gray-500">
                {formatDistanceToNow(topic.timestamp, { addSuffix: true })}
              </p>
            </div>
          </div>

          <h1 className="text-2xl font-bold mb-4">{topic.title}</h1>
          <div className="prose max-w-none">
            <ReactMarkdown>{topic.content}</ReactMarkdown>
          </div>

          <div className="mt-4 flex items-center text-sm text-gray-500">
            <MessageSquare className="h-4 w-4 mr-1" />
            <span>{topic.responseCount} responses</span>
          </div>
        </div>

        <div className="divide-y divide-gray-200">
          {responses.map((response) => (
            <div key={response.id} className="p-4">
              <div className="flex items-start space-x-3">
                <Link to={`/profile/${response.authorUsername}`}>
                  <img
                    src={response.authorPhotoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${response.authorId}`}
                    alt={response.authorName}
                    className="h-8 w-8 rounded-full"
                  />
                </Link>
                <div className="flex-1 min-w-0">
                  <div className="flex items-baseline space-x-2">
                    <Link 
                      to={`/profile/${response.authorUsername}`}
                      className="font-medium hover:underline"
                    >
                      {response.authorName}
                    </Link>
                    <span className="text-sm text-gray-500">
                      {formatDistanceToNow(response.timestamp, { addSuffix: true })}
                    </span>
                  </div>
                  <p className="mt-1 text-gray-900 whitespace-pre-wrap break-words">
                    {response.content}
                  </p>
                </div>
              </div>
            </div>
          ))}

          {responses.length === 0 && (
            <div className="p-8 text-center text-gray-500">
              <MessageSquare className="h-12 w-12 mx-auto mb-3 text-gray-400" />
              <p>No responses yet</p>
              <p className="text-sm mt-1">Be the first to respond to this topic!</p>
            </div>
          )}
        </div>

        {user && (
          <form onSubmit={handleSubmitResponse} className="sticky bottom-0 p-4 bg-white border-t border-gray-200">
            <div className="flex items-center space-x-2">
              <input
                type="text"
                value={newResponse}
                onChange={(e) => setNewResponse(e.target.value)}
                placeholder="Write a response..."
                className="flex-1 px-4 py-2 border rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                maxLength={500}
              />
              <button
                type="submit"
                disabled={!newResponse.trim() || submitting}
                className="p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 disabled:opacity-50"
              >
                {submitting ? (
                  <Loader2 className="h-5 w-5 animate-spin" />
                ) : (
                  <Send className="h-5 w-5" />
                )}
              </button>
            </div>
          </form>
        )}
      </main>

      <BottomNav />
    </div>
  );
}