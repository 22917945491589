import { Users } from 'lucide-react';

interface EmptySearchStateProps {
  searchQuery?: string;
}

export function EmptySearchState({ searchQuery }: EmptySearchStateProps) {
  if (searchQuery) {
    return (
      <div className="p-8 text-center text-gray-500">
        No users found for "{searchQuery}"
      </div>
    );
  }

  return (
    <div className="p-8 text-center text-gray-500">
      <Users className="h-12 w-12 mx-auto mb-4 text-gray-400" />
      <p className="text-lg font-medium text-gray-900">Search Users</p>
      <p className="mt-1">
        Enter a name or username to search for users
      </p>
    </div>
  );
}