import { BadgeCheck } from 'lucide-react';

interface VerificationBadgeProps {
  alliesCount: number;
}

export function VerificationBadge({ alliesCount }: VerificationBadgeProps) {
  if (alliesCount < 10) return null;

  let badgeColor = '';
  let title = '';

  if (alliesCount >= 100000) {
    badgeColor = 'text-black';
    title = 'Elite Status (100,000+ Allies)';
  } else if (alliesCount >= 10000) {
    badgeColor = 'text-red-500';
    title = 'Legend Status (10,000+ Allies)';
  } else if (alliesCount >= 1000) {
    badgeColor = 'text-yellow-500';
    title = 'Champion Status (1,000+ Allies)';
  } else if (alliesCount >= 100) {
    badgeColor = 'text-blue-500';
    title = 'Pro Status (100+ Allies)';
  } else {
    badgeColor = 'text-gray-500';
    title = 'Verified Status (10+ Allies)';
  }

  return (
    <span title={title} className="inline-flex">
      <BadgeCheck className={`h-5 w-5 ${badgeColor}`} />
    </span>
  );
}