import { useState, useEffect } from 'react';
import { LogOut, Search, Loader2, MapPin } from 'lucide-react';
import { auth } from '../lib/firebase';
import { MainMenu } from '../components/MainMenu';
import { PlaceCard } from '../components/PlaceCard';
import { PlaceDetails } from '../components/PlaceDetails';
import { Map } from '../components/Map';
import { initializePlacesService, getUserLocation } from '../utils/maps';
import toast from 'react-hot-toast';

export function Places() {
  const [searchQuery, setSearchQuery] = useState('');
  const [places, setPlaces] = useState<google.maps.places.PlaceResult[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>(null);
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(null);
  const [userLocation, setUserLocation] = useState<{ lat: number; lng: number } | null>(null);
  const [locationError, setLocationError] = useState<string | null>(null);

  useEffect(() => {
    const initialize = async () => {
      try {
        const service = await initializePlacesService();
        setPlacesService(service);

        const location = await getUserLocation();
        setUserLocation(location);

        // Search for nearby places automatically
        if (service) {
          const request: google.maps.places.PlacesSearchRequest = {
            location: new google.maps.LatLng(location.lat, location.lng),
            radius: 1000, // 1km radius
            type: 'establishment'
          };

          service.nearbySearch(request, (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK && results) {
              // Get detailed information for each place
              const detailedPlaces = results.map(place => 
                new Promise<google.maps.places.PlaceResult>((resolve) => {
                  if (!place.place_id) {
                    resolve(place);
                    return;
                  }

                  service.getDetails(
                    {
                      placeId: place.place_id,
                      fields: [
                        'name',
                        'formatted_address',
                        'photos',
                        'rating',
                        'user_ratings_total',
                        'formatted_phone_number',
                        'website',
                        'opening_hours',
                        'reviews',
                        'geometry'
                      ]
                    },
                    (detailedPlace, detailedStatus) => {
                      if (detailedStatus === google.maps.places.PlacesServiceStatus.OK && detailedPlace) {
                        resolve(detailedPlace);
                      } else {
                        resolve(place);
                      }
                    }
                  );
                })
              );

              Promise.all(detailedPlaces).then(places => {
                setPlaces(places);
              });
            }
          });
        }
      } catch (error) {
        console.error('Initialization error:', error);
        toast.error('Could not initialize maps service');
      }
    };

    initialize();
  }, []);

  const searchPlaces = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!searchQuery.trim() || !placesService || !userLocation) return;

    setLoading(true);
    try {
      const request: google.maps.places.TextSearchRequest = {
        query: searchQuery,
        location: new google.maps.LatLng(userLocation.lat, userLocation.lng),
        radius: 5000 // 5km radius
      };

      placesService.textSearch(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && results) {
          // Get detailed information for each place
          const detailedPlaces = results.map(place => 
            new Promise<google.maps.places.PlaceResult>((resolve) => {
              if (!place.place_id) {
                resolve(place);
                return;
              }

              placesService.getDetails(
                {
                  placeId: place.place_id,
                  fields: [
                    'name',
                    'formatted_address',
                    'photos',
                    'rating',
                    'user_ratings_total',
                    'formatted_phone_number',
                    'website',
                    'opening_hours',
                    'reviews',
                    'geometry'
                  ]
                },
                (detailedPlace, detailedStatus) => {
                  if (detailedStatus === google.maps.places.PlacesServiceStatus.OK && detailedPlace) {
                    resolve(detailedPlace);
                  } else {
                    resolve(place);
                  }
                }
              );
            })
          );

          Promise.all(detailedPlaces).then(places => {
            setPlaces(places);
            setLoading(false);
          });
        } else {
          setPlaces([]);
          setLoading(false);
          if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
            toast.error('No places found');
          } else {
            toast.error('Error searching places');
          }
        }
      });
    } catch (error) {
      console.error('Error searching places:', error);
      toast.error('Failed to search places');
      setLoading(false);
    }
  };

  if (locationError) {
    return (
      <div className="min-h-screen bg-gray-50">
        <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
          <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
            <h1 className="text-xl font-bold">Poosting Places</h1>
            <button
              onClick={() => auth.signOut()}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <LogOut className="h-5 w-5" />
            </button>
          </div>
          <MainMenu />
        </header>

        <main className="max-w-2xl mx-auto bg-white border-x border-gray-200 min-h-screen p-4">
          <div className="flex flex-col items-center justify-center h-64 text-center">
            <MapPin className="h-12 w-12 text-red-500 mb-4" />
            <p className="text-gray-600">{locationError}</p>
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <MapPin className="h-6 w-6 text-blue-500" />
            <h1 className="text-xl font-bold">Poosting Places</h1>
          </div>
          <button
            onClick={() => auth.signOut()}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <LogOut className="h-5 w-5" />
          </button>
        </div>
        <div className="max-w-2xl mx-auto px-4 py-3">
          <form onSubmit={searchPlaces}>
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search for restaurants, hotels, etc..."
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
          </form>
        </div>
        <MainMenu />
      </header>

      <main className="max-w-2xl mx-auto bg-white border-x border-gray-200 min-h-screen">
        {userLocation && (
          <div className="p-4">
            <Map
              center={userLocation}
              places={places}
              onPlaceSelect={setSelectedPlace}
            />
          </div>
        )}

        <div className="p-4">
          {loading ? (
            <div className="flex justify-center items-center p-8">
              <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
            </div>
          ) : places.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {places.map((place, index) => (
                <PlaceCard
                  key={place.place_id || index}
                  place={place}
                  onClick={() => setSelectedPlace(place)}
                />
              ))}
            </div>
          ) : (
            <div className="text-center py-12">
              <MapPin className="h-12 w-12 text-gray-400 mx-auto mb-4" />
              <p className="text-gray-500">
                {searchQuery ? 'No places found' : 'Search for places nearby'}
              </p>
            </div>
          )}
        </div>
      </main>

      {selectedPlace && (
        <PlaceDetails
          place={selectedPlace}
          onClose={() => setSelectedPlace(null)}
        />
      )}
    </div>
  );
}