import { useState, useEffect } from 'react';
import { LogOut, Loader2 } from 'lucide-react';
import { auth } from '../lib/firebase';
import { MainMenu } from '../components/MainMenu';
import toast from 'react-hot-toast';

export function News() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;

    const loadRssWidget = async () => {
      try {
        const script = document.createElement('script');
        script.src = 'https://widget.rss.app/v1/feed.js';
        script.async = true;

        script.onload = () => {
          if (mounted) {
            setLoading(false);
          }
        };

        script.onerror = () => {
          if (mounted) {
            setLoading(false);
            toast.error('Failed to load news feed');
          }
        };

        document.body.appendChild(script);

        return () => {
          document.body.removeChild(script);
        };
      } catch (error) {
        if (mounted) {
          setLoading(false);
          toast.error('Failed to load news feed');
        }
      }
    };

    loadRssWidget();

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <h1 className="text-xl font-bold">Poosting News</h1>
          <button
            onClick={() => auth.signOut()}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <LogOut className="h-5 w-5" />
          </button>
        </div>
        <MainMenu />
      </header>

      <main className="max-w-2xl mx-auto bg-white border-x border-gray-200 min-h-screen">
        {loading ? (
          <div className="flex justify-center items-center p-8">
            <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
          </div>
        ) : (
          <div className="space-y-8 p-4">
            <div className="rss-widget bg-white rounded-lg shadow-sm space-y-8">
              <rssapp-feed id="PabKA4pTOCrZvKu5"></rssapp-feed>
              <rssapp-feed id="iFY9b0OPoBmW7hbt"></rssapp-feed>
              <rssapp-feed id="GWzyraAYkkQqA2nf"></rssapp-feed>
              <rssapp-feed id="6FlIKxlhbABJb0AA"></rssapp-feed>
              <rssapp-feed id="wdv35hxbF1qEt5tx"></rssapp-feed>
              <rssapp-feed id="mkk0kMzACB4jvJfO"></rssapp-feed>
              <rssapp-feed id="cAtGP8wRrVSeKqrf"></rssapp-feed>
              <rssapp-feed id="uPHICAWQ3qW7790Z"></rssapp-feed>
              <rssapp-feed id="vSrfBCcgrJLncO9F"></rssapp-feed>
              <rssapp-feed id="LzUWww8p1lHVkIT3"></rssapp-feed>
              <rssapp-feed id="qhHMVmZSEBROK8Dg"></rssapp-feed>
              <rssapp-feed id="RIQ3k2l7k8bUG8Rd"></rssapp-feed>
              <rssapp-feed id="cNnSefd443HSoYeS"></rssapp-feed>
              <rssapp-feed id="nmT9Vwd1rYcucpSK"></rssapp-feed>
              <rssapp-feed id="polAJNqtHFb7NA57"></rssapp-feed>
            </div>
          </div>
        )}
      </main>
    </div>
  );
}