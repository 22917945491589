import { useState, useEffect } from 'react';
import { LogOut, Music as MusicIcon, Loader2, Search, Play, Pause } from 'lucide-react';
import { auth } from '../lib/firebase';
import { TrackList } from '../components/TrackList';
import { MusicHeader } from '../components/MusicHeader';
import { MainMenu } from '../components/MainMenu';
import { spotify } from '../lib/spotify';
import toast from 'react-hot-toast';

interface Track {
  id: string;
  name: string;
  artists: string[];
  album: {
    name: string;
    images: { url: string }[];
  };
  external_urls: {
    spotify: string;
  };
  preview_url?: string;
}

export function Music() {
  const [tracks, setTracks] = useState<Track[]>([]);
  const [searchTracks, setSearchTracks] = useState<Track[]>([]);
  const [loading, setLoading] = useState(true);
  const [searching, setSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentlyPlayingId, setCurrentlyPlayingId] = useState<string | null>(null);
  const [audioElement] = useState(new Audio());

  useEffect(() => {
    const fetchTracks = async () => {
      try {
        setLoading(true);
        const response = await spotify.playlists.getPlaylist('37i9dQZEVXbMDoHDwVN2tF');
        const trackData = response.tracks.items.map(item => ({
          id: item.track.id,
          name: item.track.name,
          artists: item.track.artists.map(artist => artist.name),
          album: {
            name: item.track.album.name,
            images: item.track.album.images
          },
          external_urls: item.track.external_urls,
          preview_url: item.track.preview_url
        }));

        setTracks(trackData);
      } catch (error) {
        console.error('Error fetching tracks:', error);
        toast.error('Failed to load tracks');
      } finally {
        setLoading(false);
      }
    };

    fetchTracks();

    return () => {
      audioElement.pause();
      audioElement.src = '';
    };
  }, []);

  useEffect(() => {
    const searchSpotify = async () => {
      if (!searchQuery.trim()) {
        setSearchTracks([]);
        return;
      }

      try {
        setSearching(true);
        const response = await spotify.search(searchQuery, ['track'], undefined, 10);
        const searchResults = response.tracks.items.map(track => ({
          id: track.id,
          name: track.name,
          artists: track.artists.map(artist => artist.name),
          album: {
            name: track.album.name,
            images: track.album.images
          },
          external_urls: track.external_urls,
          preview_url: track.preview_url
        }));

        setSearchTracks(searchResults);
      } catch (error) {
        console.error('Error searching tracks:', error);
        toast.error('Failed to search tracks');
      } finally {
        setSearching(false);
      }
    };

    const debounceTimer = setTimeout(() => {
      if (searchQuery) {
        searchSpotify();
      }
    }, 500);

    return () => clearTimeout(debounceTimer);
  }, [searchQuery]);

  const handlePlayPause = (track: Track) => {
    if (!track.preview_url) {
      window.open(track.external_urls.spotify, '_blank');
      return;
    }

    if (currentlyPlayingId === track.id) {
      audioElement.pause();
      setCurrentlyPlayingId(null);
    } else {
      audioElement.pause();
      audioElement.src = track.preview_url;
      audioElement.play().catch(error => {
        console.error('Error playing audio:', error);
        toast.error('Failed to play preview');
      });
      setCurrentlyPlayingId(track.id);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <MusicIcon className="h-6 w-6 text-green-500" />
            <h1 className="text-xl font-bold">Poosting Music</h1>
          </div>
          <button
            onClick={() => auth.signOut()}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <LogOut className="h-5 w-5" />
          </button>
        </div>
        <div className="max-w-2xl mx-auto px-4 py-3">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search for songs..."
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent"
            />
          </div>
        </div>
        <MainMenu />
      </header>

      <main className="max-w-2xl mx-auto bg-white border-x border-gray-200 min-h-screen">
        {searching ? (
          <div className="flex justify-center items-center p-8">
            <Loader2 className="h-8 w-8 animate-spin text-green-500" />
          </div>
        ) : searchTracks.length > 0 ? (
          <div className="p-4">
            <h2 className="text-lg font-semibold mb-4">Search Results</h2>
            <TrackList
              tracks={searchTracks}
              currentlyPlayingId={currentlyPlayingId}
              onPlayPause={handlePlayPause}
            />
          </div>
        ) : searchQuery ? (
          <div className="p-8 text-center text-gray-500">
            No songs found for "{searchQuery}"
          </div>
        ) : null}

        <MusicHeader />
        
        {loading ? (
          <div className="flex justify-center items-center p-8">
            <Loader2 className="h-8 w-8 animate-spin text-green-500" />
          </div>
        ) : (
          <TrackList
            tracks={tracks}
            currentlyPlayingId={currentlyPlayingId}
            onPlayPause={handlePlayPause}
          />
        )}
      </main>
    </div>
  );
}