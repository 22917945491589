import { Link } from 'react-router-dom';
import { VerificationBadge } from './VerificationBadge';
import type { User } from '../types/user';

interface UserListProps {
  users: User[];
}

export function UserList({ users }: UserListProps) {
  return (
    <div className="divide-y divide-gray-200">
      {users.map((user) => (
        <Link
          key={user.uid}
          to={`/profile/${user.username}`}
          className="block p-4 hover:bg-gray-50 transition-colors"
        >
          <div className="flex items-center space-x-4">
            <img
              src={user.photoURL}
              alt={user.name}
              className="h-12 w-12 rounded-full"
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.src = `https://api.dicebear.com/7.x/avataaars/svg?seed=${user.uid}`;
              }}
            />
            <div className="flex-1 min-w-0">
              <div className="flex items-center space-x-2">
                <p className="text-sm font-medium text-gray-900 truncate">
                  {user.name}
                </p>
                {user.allies && user.allies.length > 0 && (
                  <VerificationBadge alliesCount={user.allies.length} />
                )}
              </div>
              <p className="text-sm text-gray-500 truncate">
                @{user.username}
              </p>
              {user.bio && (
                <p className="text-sm text-gray-600 truncate mt-1">
                  {user.bio}
                </p>
              )}
              <p className="text-xs text-gray-400 mt-1">
                Joined {user.createdAt.toLocaleDateString()}
              </p>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}