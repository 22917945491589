import { useState } from 'react';
import { Users, Loader2, LogOut } from 'lucide-react';
import { joinCommunity, leaveCommunity } from '../utils/community';
import toast from 'react-hot-toast';

interface CommunityParticipantsProps {
  participants: any[];
  onJoinRequest: () => Promise<void>;
  onLeaveRequest: () => Promise<void>;
  isParticipant: boolean;
  isRequesting: boolean;
  communityId: string;
  userId: string;
}

export function CommunityParticipants({ 
  participants, 
  isParticipant,
  isRequesting,
  communityId,
  userId
}: CommunityParticipantsProps) {
  const [loading, setLoading] = useState(false);

  const handleJoinRequest = async () => {
    if (loading || !userId) return;

    setLoading(true);
    const result = await joinCommunity(communityId, userId);
    
    if (result.success) {
      toast.success('Successfully joined community');
    } else {
      toast.error(result.error || 'Failed to join community');
    }
    setLoading(false);
  };

  const handleLeaveRequest = async () => {
    if (loading || !userId) return;

    setLoading(true);
    const result = await leaveCommunity(communityId, userId);
    
    if (result.success) {
      toast.success('Successfully left community');
    } else {
      toast.error(result.error || 'Failed to leave community');
    }
    setLoading(false);
  };

  return (
    <div className="p-4 bg-gray-50 rounded-lg">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <Users className="h-5 w-5 text-gray-600" />
          <h3 className="font-semibold">
            {participants.length} {participants.length === 1 ? 'Participant' : 'Participants'}
          </h3>
        </div>
        {isParticipant ? (
          <button
            onClick={handleLeaveRequest}
            disabled={loading}
            className="px-4 py-2 bg-red-500 text-white rounded-full text-sm font-medium hover:bg-red-600 disabled:opacity-50 flex items-center space-x-2"
          >
            {loading ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Leaving...</span>
              </>
            ) : (
              <>
                <LogOut className="h-4 w-4" />
                <span>Leave Community</span>
              </>
            )}
          </button>
        ) : (
          <button
            onClick={handleJoinRequest}
            disabled={loading}
            className="px-4 py-2 bg-black text-white rounded-full text-sm font-medium hover:bg-gray-800 disabled:opacity-50 flex items-center space-x-2"
          >
            {loading ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin" />
                <span>Joining...</span>
              </>
            ) : (
              <span>Join Community</span>
            )}
          </button>
        )}
      </div>
    </div>
  );
}