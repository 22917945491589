import { LogOut } from 'lucide-react';
import { auth } from '../lib/firebase';
import { BottomNav } from '../components/BottomNav';

export function WhatIsPoosting() {
  return (
    <div className="min-h-screen bg-gray-50 pb-16">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <h1 className="text-xl font-bold">Poosting</h1>
          <button
            onClick={() => auth.signOut()}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <LogOut className="h-5 w-5" />
          </button>
        </div>
      </header>

      <main className="max-w-2xl mx-auto bg-white border-x border-gray-200 min-h-screen p-6">
        <article className="prose prose-sm max-w-none">
          <h1 className="text-2xl font-bold mb-4">A Social Network Like No Other</h1>

          <div className="space-y-6">
            <p>
              Poosting is a social network unlike anything you've ever experienced. Here, every post on the feed has the potential to reach the entire world, without barriers or geographical limits. Your experience goes beyond simply sharing content, offering dynamic and impactful interactions. You can vote on other users' posts with a like, a star, or, if you don't like it, mark it with a trash icon. These votes not only reflect your opinion but also help shape the relevance of content within the community.
            </p>

            <p>
              One of Poosting's most innovative features is that nothing can be deleted. Once something is posted, it remains on the platform, fostering a sense of responsibility and authenticity with each publication. Additionally, on Poosting, you have a daily posting limit, making every post even more special. The idea is to carefully choose what you want to share, ensuring that the content truly matters to you and your audience. This approach encourages reflection and authenticity, ensuring each post has impact and meaning.
            </p>

            <p>
              If you like a post, you can go beyond simply interacting with it. On Poosting, you can become an Ally of the author of that post. This means following the person and building a closer connection within the platform. If you change your mind, you can easily remove the alliance. This dynamic transforms followers into allies, creating more meaningful and engaged connections between users.
            </p>

            <p>
              Profiles on Poosting are also interactive. You can visit other users' profiles and vote on them in the same way you do with posts: with a like, a star, or a trash icon, depending on your perception. This feature brings an innovative aspect of social evaluation, allowing for feedback at various levels, from content to the personality represented on profiles.
            </p>

            <p>
              Another incredible feature is the real-time notification system, which keeps you updated on everything happening with your account. From interactions on your posts to new allies and votes on your profile, you'll always be informed about your presence on Poosting. This constant connection makes the platform dynamic and engaging.
            </p>

            <p>
              Security is one of Poosting's top priorities. Your account is unique and non-transferable. Once created, you cannot change the email or password linked to it, eliminating the risk of invasions or unwanted alterations. Even if someone discovers your password, they won't be able to change it. Therefore, keep your credentials safe, as the platform ensures your account remains protected.
            </p>

            <p>
              Unlike many social networks, Poosting does not allow the deletion of posts or accounts. This reinforces our vision of a transparent and secure network for everyone. We aim to create an environment where every action has a lasting impact, encouraging authenticity and responsibility. This approach establishes a truly unique social network.
            </p>

            <p>
              Moreover, Poosting is accessible from any internet-connected device. Whether on your computer, mobile phone, smartwatch, gaming console, or tablet, you can interact with the platform wherever you are. This flexibility eliminates technological barriers and provides users with a seamless and unique experience.
            </p>

            <p>
              A significant differentiator is that Poosting does not have apps. We do not want to depend on big corporations and their app stores, which impose numerous rules and restrict market access. Our platform is free, accessible directly through your browser, ensuring no one is subjected to the limitations of big techs.
            </p>

            <div className="bg-gray-50 p-6 rounded-lg">
              <h2 className="text-xl font-semibold mb-4">Verification Badges</h2>
              <p className="mb-4">
                On Poosting, achieving milestones with your network of Allies Followers is uniquely and visually rewarded. Every user has the opportunity to earn verification badges, which highlight their relevance and engagement on the platform. These badges appear next to the username and change color as you gain more allies:
              </p>
              <ul className="list-none space-y-2">
                <li className="flex items-center">
                  <span className="w-4 h-4 rounded-full bg-gray-500 mr-2"></span>
                  <span>10 Allies Followers: Gray badge - Beginning of your verified journey</span>
                </li>
                <li className="flex items-center">
                  <span className="w-4 h-4 rounded-full bg-blue-500 mr-2"></span>
                  <span>100 Allies Followers: Blue badge - Active and influential member</span>
                </li>
                <li className="flex items-center">
                  <span className="w-4 h-4 rounded-full bg-yellow-500 mr-2"></span>
                  <span>1,000 Allies Followers: Yellow badge - Growing popularity</span>
                </li>
                <li className="flex items-center">
                  <span className="w-4 h-4 rounded-full bg-red-500 mr-2"></span>
                  <span>10,000 Allies Followers: Red badge - Prominent status</span>
                </li>
                <li className="flex items-center">
                  <span className="w-4 h-4 rounded-full bg-black mr-2"></span>
                  <span>100,000 Allies Followers: Black badge - Maximum recognition</span>
                </li>
              </ul>
            </div>

            <p>
              Finally, Poosting stands out as a free and uncensored social network, offering an open space for creativity and expression. Here, you can be authentic and interact without restrictions, always respecting other users. Our commitment is to create an innovative, secure, and truly global environment, redefining what it means to stay connected. Join Poosting and be part of this digital revolution!
            </p>
          </div>
        </article>
      </main>

      <BottomNav />
    </div>
  );
}