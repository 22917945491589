import { LogOut } from 'lucide-react';
import { auth } from '../lib/firebase';
import { BottomNav } from '../components/BottomNav';

export function CookiePolicy() {
  return (
    <div className="min-h-screen bg-gray-50 pb-16">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <h1 className="text-xl font-bold">Poosting</h1>
          <button
            onClick={() => auth.signOut()}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <LogOut className="h-5 w-5" />
          </button>
        </div>
      </header>

      <main className="max-w-2xl mx-auto bg-white border-x border-gray-200 min-h-screen p-6">
        <article className="prose prose-sm max-w-none">
          <h1 className="text-2xl font-bold mb-4">Poosting Cookie Policy</h1>
          <p className="text-sm text-gray-500 mb-6">Last Updated: 15.11.24</p>

          <h2 className="text-xl font-semibold mt-6">About Cookies</h2>
          <p>We use cookies to enhance your experience on Poosting. This policy explains what cookies are, how we use them, and your choices regarding cookies.</p>

          <h2 className="text-xl font-semibold mt-6">1. What Are Cookies?</h2>
          <p>Cookies are small text files stored on your device that help improve the website's functionality, personalize your experience, and gather analytics data.</p>

          <h2 className="text-xl font-semibold mt-6">2. Types of Cookies We Use</h2>
          <h3 className="text-lg font-medium mt-4">2.1 Essential Cookies</h3>
          <p>Necessary for the basic operation of the platform, such as logging in and maintaining account security.</p>

          <h3 className="text-lg font-medium mt-4">2.2 Performance Cookies</h3>
          <p>Gather information on user interactions to optimize platform functionality.</p>

          <h3 className="text-lg font-medium mt-4">2.3 Functional Cookies</h3>
          <p>Enhance your experience by remembering preferences and settings.</p>

          <h3 className="text-lg font-medium mt-4">2.4 Advertising Cookies</h3>
          <p>Display personalized ads based on your activity and interactions on Poosting.</p>

          <h2 className="text-xl font-semibold mt-6">3. How We Use Cookies</h2>
          <ul className="list-disc pl-6 mt-2">
            <li>To authenticate users and maintain sessions.</li>
            <li>To personalize your feed and suggest content.</li>
            <li>To measure traffic, engagement, and improve services.</li>
            <li>To support targeted advertising campaigns.</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">4. Managing Your Cookie Preferences</h2>
          <p>You can manage or disable cookies through your browser settings. However, disabling cookies may impact the functionality of certain features on Poosting.</p>

          <h2 className="text-xl font-semibold mt-6">5. Third-Party Cookies</h2>
          <p>We may allow third-party services to place cookies on your device for analytics and advertising purposes. These services are governed by their own cookie policies.</p>

          <h2 className="text-xl font-semibold mt-6">6. Changes to This Policy</h2>
          <p>We reserve the right to modify this Cookie Policy at any time. Users will be notified of significant updates, and continued use of Poosting implies acceptance of the updated policy.</p>

          <h2 className="text-xl font-semibold mt-6">7. Contact Us</h2>
          <p>If you have questions about this Cookie Policy, contact us at <a href="mailto:contact@poosting.com" className="text-blue-500 hover:text-blue-600">contact@poosting.com</a>.</p>
        </article>
      </main>

      <BottomNav />
    </div>
  );
}