import { useState, useRef } from 'react';
import { Camera, Loader2, Check, X } from 'lucide-react';
import { useAuth } from '../components/AuthProvider';
import { usePhoto } from '../contexts/PhotoContext';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { ImageEditor } from './ImageEditor';
import { updateUserPhoto } from '../utils/photo';
import { VerificationBadge } from './VerificationBadge';
import { ProfileProgress } from './ProfileProgress';
import toast from 'react-hot-toast';

interface EditableProfileHeaderProps {
  profile: {
    uid: string;
    name: string;
    username: string;
    photoURL: string;
    bio?: string;
    allies?: string[];
  };
}

export function EditableProfileHeader({ profile }: EditableProfileHeaderProps) {
  const [isEditingBio, setIsEditingBio] = useState(false);
  const [bio, setBio] = useState(profile.bio || '');
  const [isSavingBio, setIsSavingBio] = useState(false);
  const [uploadingPhoto, setUploadingPhoto] = useState(false);
  const [showPhotoEditor, setShowPhotoEditor] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { user } = useAuth();
  const { updateUserPhoto: updateContextPhoto } = usePhoto();

  const handlePhotoSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result as string);
        setShowPhotoEditor(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCroppedImage = async (blob: Blob) => {
    if (!user) return;

    const loadingToast = toast.loading('Updating your photo everywhere...');
    setUploadingPhoto(true);

    try {
      const photoURL = await updateUserPhoto(blob, user);
      updateContextPhoto(photoURL);
      toast.success('Photo updated successfully!');
    } catch (error: any) {
      console.error('Photo update failed:', error);
      toast.error(error.message || 'Failed to update photo');
    } finally {
      setUploadingPhoto(false);
      setShowPhotoEditor(false);
      setSelectedImage(null);
      toast.dismiss(loadingToast);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleBioSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    try {
      setIsSavingBio(true);
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, { bio: bio.trim() });
      setIsEditingBio(false);
      toast.success('Bio updated successfully');
    } catch (error) {
      console.error('Error updating bio:', error);
      toast.error('Failed to update bio');
    } finally {
      setIsSavingBio(false);
    }
  };

  return (
    <div className="relative">
      <div className="h-40 bg-gradient-to-r from-gray-200 to-gray-300" />
      
      <div className="px-4 pb-6">
        <div className="flex flex-col items-center -mt-20">
          <div className="relative">
            <img
              src={profile.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${profile.uid}`}
              alt={profile.name}
              className="h-40 w-40 rounded-full border-4 border-white object-cover bg-white shadow-lg"
            />
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handlePhotoSelect}
              className="hidden"
            />
            <button
              onClick={() => fileInputRef.current?.click()}
              disabled={uploadingPhoto}
              className="absolute bottom-2 right-2 p-2 bg-white rounded-full shadow-lg hover:bg-gray-100 transition-colors"
              title="Change profile photo"
            >
              {uploadingPhoto ? (
                <Loader2 className="h-5 w-5 animate-spin text-blue-500" />
              ) : (
                <Camera className="h-5 w-5 text-blue-500" />
              )}
            </button>
          </div>

          <div className="text-center w-full mt-4">
            <div className="flex items-center justify-center space-x-2">
              <h2 className="text-2xl font-bold text-gray-900">{profile.name}</h2>
              <VerificationBadge alliesCount={profile.allies?.length || 0} />
            </div>
            <p className="text-gray-500">@{profile.username}</p>

            <div className="mt-4 relative max-w-md mx-auto">
              {isEditingBio ? (
                <div className="space-y-2">
                  <textarea
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                    placeholder="Tell us about yourself"
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
                    rows={3}
                    maxLength={120}
                  />
                  <div className="flex justify-center space-x-2">
                    <button
                      onClick={handleBioSubmit}
                      disabled={isSavingBio}
                      className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 disabled:opacity-50"
                    >
                      {isSavingBio ? (
                        <Loader2 className="h-5 w-5 animate-spin" />
                      ) : (
                        <Check className="h-5 w-5" />
                      )}
                    </button>
                    <button
                      onClick={() => {
                        setIsEditingBio(false);
                        setBio(profile.bio || '');
                      }}
                      className="px-4 py-2 bg-gray-500 text-white rounded-full hover:bg-gray-600"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  onClick={() => setIsEditingBio(true)}
                  className="cursor-pointer hover:bg-gray-50 rounded-lg p-2"
                >
                  <p className="text-gray-700 whitespace-pre-wrap break-words">
                    {bio || 'Add a bio...'}
                  </p>
                </div>
              )}
            </div>

            <ProfileProgress alliesCount={profile.allies?.length || 0} />
          </div>
        </div>
      </div>

      {showPhotoEditor && selectedImage && (
        <ImageEditor
          image={selectedImage}
          onSave={handleCroppedImage}
          onCancel={() => {
            setShowPhotoEditor(false);
            setSelectedImage(null);
          }}
          aspectRatio={1}
        />
      )}
    </div>
  );
}