import { useState } from 'react';
import { Heart, Star, Trash2, Loader2 } from 'lucide-react';
import { doc, updateDoc, arrayUnion, arrayRemove, getDoc, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from './AuthProvider';
import toast from 'react-hot-toast';

interface PostVotesProps {
  postId: string;
  authorId: string;
  initialVotes: {
    likes: string[];
    stars: string[];
    trash: string[];
  };
}

export function PostVotes({ postId, authorId, initialVotes }: PostVotesProps) {
  const { user } = useAuth();
  const [votes, setVotes] = useState({
    likes: initialVotes.likes || [],
    stars: initialVotes.stars || [],
    trash: initialVotes.trash || []
  });
  const [loading, setLoading] = useState(false);

  const handleVote = async (type: 'likes' | 'stars' | 'trash') => {
    if (!user) {
      toast.error('Please sign in to vote');
      return;
    }

    if (loading) return;

    try {
      setLoading(true);
      const postRef = doc(db, 'posts', postId);
      const postDoc = await getDoc(postRef);

      if (!postDoc.exists()) {
        toast.error('Post not found');
        return;
      }

      const currentVotes = postDoc.data()[type] || [];
      const hasVotedThis = currentVotes.includes(user.uid);

      if (hasVotedThis) {
        await updateDoc(postRef, {
          [type]: arrayRemove(user.uid)
        });

        setVotes(prev => ({
          ...prev,
          [type]: prev[type].filter(id => id !== user.uid)
        }));

        toast.success('Vote removed');
      } else {
        // Remove votes from other categories first
        const updates: any = {};
        ['likes', 'stars', 'trash'].forEach(voteType => {
          if (postDoc.data()[voteType]?.includes(user.uid)) {
            updates[voteType] = arrayRemove(user.uid);
          }
        });

        // Add new vote
        updates[type] = arrayUnion(user.uid);
        await updateDoc(postRef, updates);

        setVotes(prev => {
          const newVotes = {
            likes: prev.likes.filter(id => id !== user.uid),
            stars: prev.stars.filter(id => id !== user.uid),
            trash: prev.trash.filter(id => id !== user.uid)
          };
          newVotes[type] = [...newVotes[type], user.uid];
          return newVotes;
        });

        // Create notification for the vote
        if (user.uid !== authorId) {
          await addDoc(collection(db, 'notifications'), {
            type: type === 'likes' ? 'like' : type === 'stars' ? 'star' : 'trash',
            postId,
            recipientId: authorId,
            senderId: user.uid,
            senderName: user.displayName || '',
            senderUsername: user.displayName?.startsWith('@') 
              ? user.displayName.slice(1) 
              : user.displayName || '',
            senderPhotoURL: user.photoURL,
            timestamp: serverTimestamp(),
            read: false
          });
        }

        const voteTypes = {
          likes: 'liked',
          stars: 'starred',
          trash: 'marked as trash'
        };
        toast.success(`Post ${voteTypes[type]}`);
      }
    } catch (error) {
      console.error('Error voting:', error);
      toast.error('Failed to vote');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center space-x-4">
      <button
        onClick={() => handleVote('likes')}
        disabled={loading}
        className={`flex items-center space-x-1 ${
          votes.likes.includes(user?.uid || '')
            ? 'text-red-500'
            : 'text-gray-500 hover:text-red-500'
        } transition-colors disabled:opacity-50`}
        title="Like"
      >
        <Heart className={`h-5 w-5 ${
          votes.likes.includes(user?.uid || '') ? 'fill-current' : ''
        }`} />
        <span className="text-sm">{votes.likes.length}</span>
      </button>

      <button
        onClick={() => handleVote('stars')}
        disabled={loading}
        className={`flex items-center space-x-1 ${
          votes.stars.includes(user?.uid || '')
            ? 'text-yellow-500'
            : 'text-gray-500 hover:text-yellow-500'
        } transition-colors disabled:opacity-50`}
        title="Star"
      >
        <Star className={`h-5 w-5 ${
          votes.stars.includes(user?.uid || '') ? 'fill-current' : ''
        }`} />
        <span className="text-sm">{votes.stars.length}</span>
      </button>

      <button
        onClick={() => handleVote('trash')}
        disabled={loading}
        className={`flex items-center space-x-1 ${
          votes.trash.includes(user?.uid || '')
            ? 'text-red-500'
            : 'text-gray-500 hover:text-red-500'
        } transition-colors disabled:opacity-50`}
        title="Mark as trash"
      >
        <Trash2 className="h-5 w-5" />
        <span className="text-sm">{votes.trash.length}</span>
      </button>

      {loading && (
        <Loader2 className="h-4 w-4 animate-spin text-gray-500" />
      )}
    </div>
  );
}