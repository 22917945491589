import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { AuthProvider } from './components/AuthProvider';
import { PhotoProvider } from './contexts/PhotoContext';
import { Layout } from './components/Layout';
import { Home } from './pages/Home';
import { Auth } from './pages/Auth';
import { Profile } from './pages/Profile';
import { Config } from './pages/Config';
import { Notifications } from './pages/Notifications';
import { Search } from './pages/Search';
import { Chat } from './pages/Chat';
import { Stories } from './pages/Stories';
import { News } from './pages/News';
import { Music } from './pages/Music';
import { Videos } from './pages/Videos';
import { Places } from './pages/Places';
import { Communities } from './pages/Communities';
import { CommunityDetails } from './pages/CommunityDetails';
import { TopicDetails } from './pages/TopicDetails';
import { CookiePolicy } from './pages/CookiePolicy';
import { TermsOfUse } from './pages/TermsOfUse';
import { WhatIsPoosting } from './pages/WhatIsPoosting';
import { AIChat } from './pages/AIChat';
import { NotFound } from './pages/NotFound';
import { Ranking } from './pages/Ranking';
import { useAuth } from './components/AuthProvider';

function PrivateRoute({ children }: { children: React.ReactNode }) {
  const { user, loading } = useAuth();

  if (loading) {
    return <div className="flex items-center justify-center min-h-screen">Loading...</div>;
  }

  return user ? <Layout>{children}</Layout> : <Navigate to="/auth" replace />;
}

function PublicRoute({ children }: { children: React.ReactNode }) {
  const { user, loading } = useAuth();

  if (loading) {
    return <div className="flex items-center justify-center min-h-screen">Loading...</div>;
  }

  return user ? <Navigate to="/" replace /> : <>{children}</>;
}

export function App() {
  return (
    <Router>
      <AuthProvider>
        <PhotoProvider>
          <Routes>
            <Route 
              path="/auth" 
              element={
                <PublicRoute>
                  <Auth />
                </PublicRoute>
              } 
            />
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route
              path="/ranking"
              element={
                <PrivateRoute>
                  <Ranking />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile/:username"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route
              path="/config"
              element={
                <PrivateRoute>
                  <Config />
                </PrivateRoute>
              }
            />
            <Route
              path="/notifications"
              element={
                <PrivateRoute>
                  <Notifications />
                </PrivateRoute>
              }
            />
            <Route
              path="/search"
              element={
                <PrivateRoute>
                  <Search />
                </PrivateRoute>
              }
            />
            <Route
              path="/chat/*"
              element={
                <PrivateRoute>
                  <Chat />
                </PrivateRoute>
              }
            />
            <Route
              path="/stories"
              element={
                <PrivateRoute>
                  <Stories />
                </PrivateRoute>
              }
            />
            <Route
              path="/news"
              element={
                <PrivateRoute>
                  <News />
                </PrivateRoute>
              }
            />
            <Route
              path="/music"
              element={
                <PrivateRoute>
                  <Music />
                </PrivateRoute>
              }
            />
            <Route
              path="/videos"
              element={
                <PrivateRoute>
                  <Videos />
                </PrivateRoute>
              }
            />
            <Route
              path="/places"
              element={
                <PrivateRoute>
                  <Places />
                </PrivateRoute>
              }
            />
            <Route
              path="/communities"
              element={
                <PrivateRoute>
                  <Communities />
                </PrivateRoute>
              }
            />
            <Route
              path="/communities/:id"
              element={
                <PrivateRoute>
                  <CommunityDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/communities/:communityId/topics/:topicId"
              element={
                <PrivateRoute>
                  <TopicDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/cookie-policy"
              element={
                <PrivateRoute>
                  <CookiePolicy />
                </PrivateRoute>
              }
            />
            <Route
              path="/terms-of-use"
              element={
                <PrivateRoute>
                  <TermsOfUse />
                </PrivateRoute>
              }
            />
            <Route
              path="/what-is-poosting"
              element={
                <PrivateRoute>
                  <WhatIsPoosting />
                </PrivateRoute>
              }
            />
            <Route
              path="/ai-chat"
              element={
                <PrivateRoute>
                  <AIChat />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Toaster />
        </PhotoProvider>
      </AuthProvider>
    </Router>
  );
}