import { useState, useEffect } from 'react';
import { collection, query, where, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { RankingItem } from '../utils/ranking/types';
import toast from 'react-hot-toast';

export function useRankingData() {
  const [rankings, setRankings] = useState<RankingItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchRankings = async () => {
      try {
        setLoading(true);
        setError(null);

        // Query posts from the last 7 days
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

        const postsRef = collection(db, 'posts');
        const q = query(
          postsRef,
          where('timestamp', '>=', sevenDaysAgo),
          orderBy('timestamp', 'desc')
        );

        const snapshot = await getDocs(q);
        
        // Aggregate likes by user
        const userLikes = new Map<string, {
          userId: string;
          name: string;
          username: string;
          photoURL: string | null;
          alliesCount: number;
          likesCount: number;
        }>();

        snapshot.docs.forEach(doc => {
          const data = doc.data();
          const likes = data.likes?.length || 0;
          const current = userLikes.get(data.authorId) || {
            userId: data.authorId,
            name: data.authorName,
            username: data.authorUsername,
            photoURL: data.authorPhotoURL,
            alliesCount: data.allies?.length || 0,
            likesCount: 0
          };
          
          userLikes.set(data.authorId, {
            ...current,
            likesCount: current.likesCount + likes
          });
        });

        // Convert to array and sort
        const rankingsList = Array.from(userLikes.values())
          .sort((a, b) => b.likesCount - a.likesCount)
          .slice(0, 100);

        setRankings(rankingsList);
      } catch (error) {
        console.error('Error fetching rankings:', error);
        setError('Failed to load rankings');
        toast.error('Failed to load rankings');
      } finally {
        setLoading(false);
      }
    };

    fetchRankings();

    // Refresh rankings every 5 minutes
    const interval = setInterval(fetchRankings, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  return { rankings, loading, error };
}