import { useState, useEffect, useRef } from 'react';
import { X, ChevronLeft, ChevronRight, Loader2, Trash2 } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from '../components/AuthProvider';
import { deleteAnyStory } from '../utils/admin';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';

interface Story {
  id: string;
  mediaUrl: string;
  mediaType: 'image' | 'video';
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL: string;
  timestamp: Date;
  views: string[];
}

interface StoryViewerProps {
  stories: Story[];
  initialIndex: number;
  onClose: () => void;
}

export function StoryViewer({ stories, initialIndex, onClose }: StoryViewerProps) {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isMediaReady, setIsMediaReady] = useState(false);
  const [videoDuration, setVideoDuration] = useState<number | null>(null);
  const { user } = useAuth();
  const videoRef = useRef<HTMLVideoElement>(null);

  const currentStory = stories[currentIndex];
  const storyDuration = currentStory?.mediaType === 'video' ? (videoDuration || 30) * 1000 : 5000;

  useEffect(() => {
    const markAsViewed = async () => {
      if (!user || !currentStory || currentStory.views.includes(user.uid)) return;

      try {
        const storyRef = doc(db, 'stories', currentStory.id);
        await updateDoc(storyRef, {
          views: arrayUnion(user.uid)
        });
      } catch (error) {
        console.error('Error marking story as viewed:', error);
      }
    };

    markAsViewed();
  }, [currentStory, user]);

  useEffect(() => {
    if (!isMediaReady) return;

    const timer = setInterval(() => {
      setProgress(prev => {
        if (prev >= 100) {
          if (currentIndex < stories.length - 1) {
            setCurrentIndex(prev => prev + 1);
            setIsLoading(true);
            setIsMediaReady(false);
            return 0;
          } else {
            clearInterval(timer);
            onClose();
            return prev;
          }
        }
        return prev + (100 / (storyDuration / 100));
      });
    }, 100);

    return () => clearInterval(timer);
  }, [currentIndex, stories.length, storyDuration, onClose, isMediaReady]);

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(prev => prev - 1);
      setProgress(0);
      setIsLoading(true);
      setIsMediaReady(false);
      setVideoDuration(null);
    }
  };

  const handleNext = () => {
    if (currentIndex < stories.length - 1) {
      setCurrentIndex(prev => prev + 1);
      setProgress(0);
      setIsLoading(true);
      setIsMediaReady(false);
      setVideoDuration(null);
    } else {
      onClose();
    }
  };

  const handleMediaLoad = () => {
    setIsLoading(false);
    setIsMediaReady(true);
    setProgress(0);

    if (currentStory.mediaType === 'video' && videoRef.current) {
      setVideoDuration(videoRef.current.duration);
      // Unmute video when it's loaded
      videoRef.current.muted = false;
      // Set volume to 50%
      videoRef.current.volume = 0.5;
      // Start playing
      videoRef.current.play().catch(error => {
        console.error('Error playing video:', error);
      });
    }
  };

  const handleAdminDelete = async () => {
    if (!user?.displayName || !currentStory) return;

    try {
      await deleteAnyStory(currentStory.id, user.displayName.replace('@', ''));
      handleNext();
    } catch (error: any) {
      console.error('Error deleting story:', error);
      toast.error(error.message || 'Failed to delete story');
    }
  };

  if (!currentStory) return null;

  return (
    <div className="fixed inset-0 bg-black z-50 flex items-center justify-center">
      <div className="absolute top-0 left-0 right-0 h-1 bg-gray-800">
        {isMediaReady && (
          <div
            className="h-full bg-white transition-all duration-100 ease-linear"
            style={{ width: `${progress}%` }}
          />
        )}
      </div>

      <div className="absolute top-4 left-4 right-4 flex items-center justify-between text-white z-10">
        <div className="flex items-center space-x-3">
          <img
            src={currentStory.authorPhotoURL}
            alt={currentStory.authorName}
            className="w-8 h-8 rounded-full border border-white"
          />
          <div>
            <Link 
              to={`/profile/${currentStory.authorUsername}`}
              className="font-semibold hover:text-gray-200"
              onClick={onClose}
            >
              {currentStory.authorName}
            </Link>
            <p className="text-sm opacity-75">
              {formatDistanceToNow(currentStory.timestamp, { addSuffix: true })}
            </p>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          {user?.displayName?.toLowerCase().replace('@', '') === 'afonsoalcantara' && (
            <button
              onClick={handleAdminDelete}
              className="p-2 text-red-500 hover:bg-white/10 rounded-full"
              title="Admin: Delete Story"
            >
              <Trash2 className="h-6 w-6" />
            </button>
          )}
          <button
            onClick={onClose}
            className="p-2 hover:bg-white/10 rounded-full"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
      </div>

      <div className="relative w-full h-full">
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black/50">
            <Loader2 className="h-12 w-12 animate-spin text-white" />
          </div>
        )}
        
        {currentStory.mediaType === 'image' ? (
          <img
            src={currentStory.mediaUrl}
            alt="Story"
            className="w-full h-full object-contain"
            onLoad={handleMediaLoad}
          />
        ) : (
          <video
            ref={videoRef}
            src={currentStory.mediaUrl}
            className="w-full h-full object-contain"
            autoPlay
            playsInline
            controls={false}
            onLoadedData={handleMediaLoad}
            onWaiting={() => setIsLoading(true)}
            onPlaying={() => setIsLoading(false)}
          />
        )}
      </div>

      <button
        onClick={handlePrevious}
        className="absolute left-4 top-1/2 -translate-y-1/2 p-2 text-white hover:bg-white/10 rounded-full disabled:opacity-50"
        disabled={currentIndex === 0}
      >
        <ChevronLeft className="h-8 w-8" />
      </button>

      <button
        onClick={handleNext}
        className="absolute right-4 top-1/2 -translate-y-1/2 p-2 text-white hover:bg-white/10 rounded-full"
      >
        <ChevronRight className="h-8 w-8" />
      </button>
    </div>
  );
}