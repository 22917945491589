import { formatDistanceToNow } from 'date-fns';
import { Link } from 'react-router-dom';
import { PostVotes } from './PostVotes';
import { PostMatch } from './PostMatch';
import { Comments } from './Comments';
import { Eye, Trash2, Music, Play, Pause, Youtube } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { doc, updateDoc, increment } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { VerificationBadge } from './VerificationBadge';
import { usePhoto } from '../contexts/PhotoContext';
import { deleteAnyPost } from '../utils/admin';
import { useAuth } from './AuthProvider';
import { LocationMessage } from './LocationMessage';
import YouTube from 'react-youtube';
import toast from 'react-hot-toast';

interface PostProps {
  id: string;
  content: string;
  imageUrl?: string;
  videoUrl?: string;
  audioUrl?: string;
  location?: {
    latitude: number;
    longitude: number;
  };
  spotifyTrack?: {
    id: string;
    name: string;
    artists: string[];
    albumName: string;
    albumImage: string;
    previewUrl?: string;
    spotifyUrl: string;
  };
  youtubeVideo?: {
    id: string;
    title: string;
    channelTitle: string;
    thumbnailUrl: string;
  };
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL?: string;
  timestamp: Date;
  likes?: string[];
  stars?: string[];
  trash?: string[];
  matches?: string[];
  views?: number;
  allies?: string[];
}

export function Post({
  id,
  content,
  imageUrl,
  videoUrl,
  audioUrl,
  location,
  spotifyTrack,
  youtubeVideo,
  authorId,
  authorName,
  authorUsername,
  authorPhotoURL,
  timestamp,
  likes = [],
  stars = [],
  trash = [],
  matches = [],
  views = 0,
  allies = [],
}: PostProps) {
  const { userPhoto } = usePhoto();
  const videoRef = useRef<HTMLVideoElement>(null);
  const { user } = useAuth();
  const postRef = useRef<HTMLElement>(null);
  const [hasViewed, setHasViewed] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (!postRef.current || hasViewed) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !hasViewed) {
            updateDoc(doc(db, 'posts', id), {
              views: increment(1)
            }).catch(error => {
              console.error('Error updating view count:', error);
            });
            setHasViewed(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.5 }
    );

    observer.observe(postRef.current);

    return () => {
      observer.disconnect();
    };
  }, [id, hasViewed]);

  useEffect(() => {
    if (videoRef.current && videoUrl) {
      videoRef.current.muted = false;

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              videoRef.current?.play().catch(error => {
                console.error('Error playing video:', error);
              });
            } else {
              if (videoRef.current) {
                videoRef.current.pause();
                videoRef.current.currentTime = 0;
              }
            }
          });
        },
        { threshold: 0.5 }
      );

      observer.observe(videoRef.current);

      return () => {
        if (videoRef.current) {
          observer.unobserve(videoRef.current);
        }
      };
    }
  }, [videoUrl]);

  const handleAdminDelete = async () => {
    if (!user?.displayName) return;

    try {
      await deleteAnyPost(id, user.displayName.replace('@', ''));
    } catch (error: any) {
      console.error('Error deleting post:', error);
      toast.error(error.message || 'Failed to delete post');
    }
  };

  const togglePlayPreview = () => {
    if (!spotifyTrack?.previewUrl) {
      window.open(spotifyTrack?.spotifyUrl, '_blank');
      return;
    }

    if (!audioRef.current) {
      audioRef.current = new Audio(spotifyTrack.previewUrl);
      audioRef.current.volume = 0.5;
    }

    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);

    audioRef.current.onended = () => {
      setIsPlaying(false);
    };
  };

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  const displayPhoto = authorPhotoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${authorId}`;

  return (
    <article ref={postRef} className="bg-white">
      <div className="p-4">
        <div className="flex items-start space-x-3 mb-2">
          <Link to={`/profile/${authorUsername}`}>
            <img
              src={displayPhoto}
              alt={authorName}
              className="h-10 w-10 rounded-full"
            />
          </Link>
          <div className="flex-1">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <Link 
                  to={`/profile/${authorUsername}`}
                  className="font-semibold hover:underline"
                >
                  {authorName}
                </Link>
                {allies && allies.length > 0 && (
                  <VerificationBadge alliesCount={allies.length} />
                )}
              </div>
              {user?.displayName?.toLowerCase().replace('@', '') === 'afonsoalcantara' && (
                <button
                  onClick={handleAdminDelete}
                  className="p-2 text-red-500 hover:bg-red-50 rounded-full transition-colors"
                  title="Admin: Delete Post"
                >
                  <Trash2 className="h-5 w-5" />
                </button>
              )}
            </div>
            <p className="text-sm text-gray-500">
              {formatDistanceToNow(timestamp, { addSuffix: true })}
            </p>
          </div>
        </div>

        <p className="text-gray-900 whitespace-pre-wrap break-words mb-3">{content}</p>
        
        {imageUrl && (
          <div className="relative w-full aspect-square mb-3">
            <img
              src={imageUrl}
              alt="Post content"
              className="absolute inset-0 w-full h-full object-cover bg-black/5 rounded-lg"
              loading="lazy"
            />
          </div>
        )}

        {videoUrl && (
          <div className="relative w-full aspect-square rounded-lg overflow-hidden mb-3">
            <video
              ref={videoRef}
              src={videoUrl}
              className="absolute inset-0 w-full h-full object-cover bg-black/5"
              controls
              playsInline
              preload="metadata"
            />
          </div>
        )}

        {audioUrl && (
          <div className="w-full mt-2 mb-3 bg-gray-50 rounded-lg p-3">
            <audio
              src={audioUrl}
              className="w-full"
              controls
              preload="metadata"
            />
          </div>
        )}

        {spotifyTrack && (
          <div className="mb-3">
            <div className="relative w-full aspect-square rounded-lg overflow-hidden group">
              <img
                src={spotifyTrack.albumImage}
                alt={spotifyTrack.name}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col items-center justify-center p-4 text-white">
                <h3 className="text-2xl font-bold text-center mb-2">{spotifyTrack.name}</h3>
                <p className="text-lg text-center mb-4">{spotifyTrack.artists.join(', ')}</p>
                <button
                  onClick={togglePlayPreview}
                  className="p-4 bg-green-500 rounded-full hover:bg-green-600 transition-colors"
                >
                  {isPlaying ? (
                    <Pause className="h-8 w-8" />
                  ) : (
                    <Play className="h-8 w-8" />
                  )}
                </button>
                {!spotifyTrack.previewUrl && (
                  <p className="mt-2 text-sm opacity-75">No preview available - Click to open in Spotify</p>
                )}
              </div>
            </div>
          </div>
        )}

        {youtubeVideo && (
          <div className="mb-3">
            <div className="relative w-full aspect-video rounded-lg overflow-hidden">
              <YouTube
                videoId={youtubeVideo.id}
                opts={{
                  width: '100%',
                  height: '100%',
                  playerVars: {
                    modestbranding: 1,
                    rel: 0
                  }
                }}
                className="absolute inset-0"
              />
            </div>
            <div className="mt-2">
              <h3 className="font-semibold text-gray-900">{youtubeVideo.title}</h3>
              <p className="text-sm text-gray-500">{youtubeVideo.channelTitle}</p>
            </div>
          </div>
        )}

        {location && (
          <div className="mb-3">
            <LocationMessage
              latitude={location.latitude}
              longitude={location.longitude}
              senderName={authorName}
              senderPhotoURL={displayPhoto}
              isFeed={true}
            />
          </div>
        )}

        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <PostVotes
              postId={id}
              authorId={authorId}
              initialVotes={{ likes, stars, trash }}
            />
            <PostMatch
              postId={id}
              authorId={authorId}
              matches={matches}
            />
          </div>
          <div className="flex items-center text-gray-500">
            <Eye className="h-4 w-4 mr-1" />
            <span className="text-sm">{views.toLocaleString()}</span>
          </div>
        </div>
      </div>

      <Comments postId={id} authorId={authorId} />
    </article>
  );
}