import { collection, query, where, orderBy, limit, startAfter, getDocs, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { FilterOption } from '../components/ContentFilter';

const POSTS_PER_PAGE = 10;

export interface PostData {
  id: string;
  content: string;
  authorId: string;
  timestamp: Date;
  [key: string]: any;
}

export function subscribeToFeed(
  userId: string,
  filterOption: FilterOption,
  onUpdate: (posts: PostData[]) => void,
  onError: (error: Error) => void
) {
  const postsRef = collection(db, 'posts');
  let q;

  if (filterOption === 'all') {
    q = query(
      postsRef,
      orderBy('timestamp', 'desc'),
      limit(POSTS_PER_PAGE)
    );
  } else {
    q = query(
      postsRef,
      where('authorId', '==', userId),
      orderBy('timestamp', 'desc'),
      limit(POSTS_PER_PAGE)
    );
  }

  return onSnapshot(
    q,
    (snapshot) => {
      // Create a Set to track unique post IDs
      const uniquePosts = new Map<string, PostData>();
      
      snapshot.docs.forEach(doc => {
        // Only add if we haven't seen this ID before
        if (!uniquePosts.has(doc.id)) {
          uniquePosts.set(doc.id, {
            id: doc.id,
            ...doc.data(),
            timestamp: doc.data().timestamp?.toDate() || new Date()
          } as PostData);
        }
      });

      // Convert Map values back to array
      const posts = Array.from(uniquePosts.values());
      onUpdate(posts);
    },
    (error) => {
      console.error('Error in feed subscription:', error);
      onError(error);
    }
  );
}

export async function fetchMorePosts(
  userId: string,
  filterOption: FilterOption,
  lastDoc: any
): Promise<{ posts: PostData[]; lastDoc: any; hasMore: boolean }> {
  try {
    const postsRef = collection(db, 'posts');
    let q;

    if (filterOption === 'all') {
      q = query(
        postsRef,
        orderBy('timestamp', 'desc'),
        startAfter(lastDoc),
        limit(POSTS_PER_PAGE)
      );
    } else {
      q = query(
        postsRef,
        where('authorId', '==', userId),
        orderBy('timestamp', 'desc'),
        startAfter(lastDoc),
        limit(POSTS_PER_PAGE)
      );
    }

    const snapshot = await getDocs(q);
    
    // Create a Map to ensure unique posts
    const uniquePosts = new Map<string, PostData>();
    
    snapshot.docs.forEach(doc => {
      if (!uniquePosts.has(doc.id)) {
        uniquePosts.set(doc.id, {
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate() || new Date()
        } as PostData);
      }
    });

    const posts = Array.from(uniquePosts.values());

    return {
      posts,
      lastDoc: snapshot.docs[snapshot.docs.length - 1] || null,
      hasMore: posts.length === POSTS_PER_PAGE
    };
  } catch (error) {
    console.error('Error fetching more posts:', error);
    throw error;
  }
}