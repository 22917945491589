import { Eye, User, Bell, Search, Settings, Film, Music, Newspaper, MessageSquarePlus, Users, Youtube, MapPin } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { NotificationBadge } from './NotificationBadge';
import { ChatBadge } from './ChatBadge';

export function BottomNav() {
  const location = useLocation();
  const { user } = useAuth();

  if (!user) return null;

  const navItems = [
    { icon: Eye, path: '/', title: 'Feed World' },
    { icon: Film, path: '/stories', title: 'Stories' },
    { icon: Search, path: '/search', title: 'Search Users' },
    { icon: Users, path: '/communities', title: 'Communities' },
    { icon: Music, path: '/music', title: 'Music' },
    { icon: Youtube, path: '/videos', title: 'Videos' },
    { icon: MapPin, path: '/places', title: 'Places' },
    { icon: Newspaper, path: '/news', title: 'News' },
    { 
      icon: User, 
      path: `/profile/${user.displayName?.replace('@', '')}`,
      title: 'Profile'
    },
    { 
      icon: Bell, 
      path: '/notifications',
      badge: true,
      title: 'Notifications'
    },
    {
      component: ChatBadge,
      path: '/chat',
      title: 'Chat'
    },
    {
      icon: Settings,
      path: '/config',
      title: 'Settings'
    }
  ];

  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 z-50">
      <div className="max-w-2xl mx-auto">
        <div className="flex justify-around items-center h-16">
          {navItems.map(({ icon: Icon, component: Component, path, badge, title }) => {
            const isActive = location.pathname === path;
            return (
              <Link
                key={path}
                to={path}
                className={`flex items-center justify-center flex-1 h-full relative ${
                  isActive
                    ? 'text-black'
                    : 'text-gray-500 hover:text-black'
                }`}
                title={title}
              >
                {Component ? (
                  <Component />
                ) : badge ? (
                  <NotificationBadge />
                ) : Icon && (
                  <Icon className="h-6 w-6" />
                )}
              </Link>
            );
          })}
        </div>
      </div>
    </nav>
  );
}