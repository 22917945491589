import { formatDistanceToNow } from 'date-fns';
import { MessageSquare } from 'lucide-react';
import { Link } from 'react-router-dom';

interface Topic {
  id: string;
  title: string;
  content: string;
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL: string;
  timestamp: Date;
  responseCount: number;
  lastResponseAt?: Date;
}

interface TopicListProps {
  communityId: string;
  topics: Topic[];
}

export function TopicList({ communityId, topics }: TopicListProps) {
  if (topics.length === 0) {
    return (
      <div className="text-center py-8">
        <MessageSquare className="h-12 w-12 mx-auto text-gray-400 mb-3" />
        <p className="text-gray-500">No topics yet</p>
        <p className="text-sm text-gray-400 mt-1">Be the first to start a discussion!</p>
      </div>
    );
  }

  return (
    <div className="divide-y divide-gray-200">
      {topics.map((topic) => (
        <Link
          key={topic.id}
          to={`/communities/${communityId}/topics/${topic.id}`}
          className="block p-4 hover:bg-gray-50 transition-colors"
        >
          <div className="flex items-start space-x-3">
            <div className="flex-1 min-w-0">
              <h3 className="text-lg font-semibold text-gray-900 mb-1">
                {topic.title}
              </h3>
              <p className="text-gray-600 line-clamp-2 mb-2">
                {topic.content}
              </p>
              <div className="flex flex-wrap items-center gap-2 text-xs text-gray-500">
                <div className="flex items-center space-x-1">
                  <MessageSquare className="h-3.5 w-3.5" />
                  <span>{topic.responseCount} responses</span>
                </div>
                <span>•</span>
                <span>{formatDistanceToNow(topic.timestamp, { addSuffix: true })}</span>
                {topic.lastResponseAt && topic.responseCount > 0 && (
                  <>
                    <span>•</span>
                    <span className="text-gray-400">
                      Last response {formatDistanceToNow(topic.lastResponseAt, { addSuffix: true })}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}