import { useState, useEffect } from 'react';
import { MessageCircle } from 'lucide-react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuth } from './AuthProvider';

export function ChatBadge() {
  const [unreadCount, setUnreadCount] = useState(0);
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      setUnreadCount(0);
      return;
    }

    // Query chats where the user is a participant
    const q = query(
      collection(db, 'chats'),
      where('participants', 'array-contains', user.uid)
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        // Count documents where unreadBy array contains the user's ID
        const count = snapshot.docs.filter(doc => 
          doc.data().unreadBy?.includes(user.uid)
        ).length;
        setUnreadCount(count);
      },
      (error) => {
        console.error('Error fetching unread chats:', error);
        setUnreadCount(0);
      }
    );

    return () => unsubscribe();
  }, [user]);

  return (
    <div className="relative">
      <MessageCircle className="h-6 w-6" />
      {unreadCount > 0 && (
        <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full min-w-[1.25rem] h-5 flex items-center justify-center px-1">
          {unreadCount > 99 ? '99+' : unreadCount}
        </span>
      )}
    </div>
  );
}