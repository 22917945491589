import { BadgeCheck } from 'lucide-react';

interface ProfileProgressProps {
  alliesCount: number;
}

const BADGE_THRESHOLDS = [
  { count: 10, color: 'gray', label: 'Verified' },
  { count: 100, color: 'blue', label: 'Pro' },
  { count: 1000, color: 'yellow', label: 'Champion' },
  { count: 10000, color: 'red', label: 'Legend' },
  { count: 100000, color: 'black', label: 'Elite' }
];

export function ProfileProgress({ alliesCount }: ProfileProgressProps) {
  const getNextBadgeThreshold = () => {
    for (const threshold of BADGE_THRESHOLDS) {
      if (alliesCount < threshold.count) {
        return threshold;
      }
    }
    return BADGE_THRESHOLDS[BADGE_THRESHOLDS.length - 1];
  };

  const getCurrentBadgeThreshold = () => {
    for (let i = BADGE_THRESHOLDS.length - 1; i >= 0; i--) {
      if (alliesCount >= BADGE_THRESHOLDS[i].count) {
        return BADGE_THRESHOLDS[i];
      }
    }
    return null;
  };

  const calculateProgress = () => {
    const nextThreshold = getNextBadgeThreshold();
    const prevThreshold = BADGE_THRESHOLDS[BADGE_THRESHOLDS.indexOf(nextThreshold) - 1];
    const start = prevThreshold ? prevThreshold.count : 0;
    const range = nextThreshold.count - start;
    const progress = ((alliesCount - start) / range) * 100;
    return Math.min(Math.max(progress, 0), 100);
  };

  const currentBadge = getCurrentBadgeThreshold();
  const nextBadge = getNextBadgeThreshold();
  const progress = calculateProgress();

  if (alliesCount >= 100000) {
    return (
      <div className="bg-gray-50 rounded-lg p-4 mt-4">
        <div className="flex items-center justify-center space-x-2">
          <BadgeCheck className="h-6 w-6 text-black" />
          <span className="font-medium">Maximum Elite Status Achieved!</span>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-50 rounded-lg p-4 mt-4">
      <div className="space-y-2">
        <div className="flex justify-between text-sm">
          <span className="font-medium">
            {currentBadge ? (
              <>
                Current: {currentBadge.label}{' '}
                <BadgeCheck className={`inline-block h-4 w-4 text-${currentBadge.color}-500`} />
              </>
            ) : (
              'Not verified yet'
            )}
          </span>
          <span className="font-medium">
            Next: {nextBadge.label}{' '}
            <BadgeCheck className={`inline-block h-4 w-4 text-${nextBadge.color}-500`} />
          </span>
        </div>
        
        <div className="relative w-full h-2 bg-gray-200 rounded-full overflow-hidden">
          <div 
            className="absolute left-0 top-0 h-full bg-gradient-to-r from-blue-500 to-blue-600 rounded-full transition-all duration-500"
            style={{ width: `${progress}%` }}
          />
        </div>

        <div className="flex justify-between text-xs text-gray-500">
          <span>{alliesCount.toLocaleString()} allies</span>
          <span>{nextBadge.count.toLocaleString()} needed</span>
        </div>

        <p className="text-center text-sm text-gray-600 mt-2">
          {progress.toFixed(1)}% progress to {nextBadge.label} Status
        </p>
      </div>
    </div>
  );
}