import { useState, useRef, useEffect } from 'react';
import { Camera, Loader2, LogOut } from 'lucide-react';
import { useAuth } from '../components/AuthProvider';
import { usePhoto } from '../contexts/PhotoContext';
import { db, storage, auth } from '../lib/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc, collection, query, where, getDocs, writeBatch, setDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { ImageEditor } from '../components/ImageEditor';
import { updateUserPhoto } from '../utils/photo';
import { updateAdminCoins } from '../utils/admin';
import { MainMenu } from '../components/MainMenu';
import { RecentUsersList } from '../components/RecentUsersList';
import { openChatSupport } from '../utils/chat';
import toast from 'react-hot-toast';

export function Config() {
  const { user } = useAuth();
  const { userPhoto, updateUserPhoto: updateContextPhoto } = usePhoto();
  const [uploadingPhoto, setUploadingPhoto] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showPhotoEditor, setShowPhotoEditor] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  useEffect(() => {
    if (user?.displayName?.toLowerCase().includes('afonsoalcantaraweb')) {
      updateAdminCoins().catch(console.error);
    }
  }, [user]);

  const handlePhotoSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result as string);
        setShowPhotoEditor(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCroppedImage = async (blob: Blob) => {
    if (!user) return;

    const loadingToast = toast.loading('Updating your photo everywhere...');
    setUploadingPhoto(true);

    try {
      const photoURL = await updateUserPhoto(blob, user);
      updateContextPhoto(photoURL);
      toast.success('Photo updated successfully!');
    } catch (error: any) {
      console.error('Photo update failed:', error);
      toast.error(error.message || 'Failed to update photo');
    } finally {
      setUploadingPhoto(false);
      setShowPhotoEditor(false);
      setSelectedImage(null);
      toast.dismiss(loadingToast);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-2xl mx-auto px-4 py-3 flex justify-between items-center">
          <h1 className="text-xl font-bold">Poosting</h1>
          <button
            onClick={() => auth.signOut()}
            className="p-2 hover:bg-gray-100 rounded-full"
            title="Sign out"
          >
            <LogOut className="h-5 w-5" />
          </button>
        </div>
        <MainMenu />
      </header>

      <main className="max-w-2xl mx-auto bg-white border-x border-gray-200 min-h-screen p-4">
        <div className="space-y-6">
          {/* Profile Photo Section */}
          <div>
            <h2 className="text-lg font-semibold mb-4">Profile Photo</h2>
            <div className="flex items-center justify-center">
              <div className="relative">
                <img
                  src={userPhoto || user?.photoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${user?.uid}`}
                  alt="Profile"
                  className="h-32 w-32 rounded-full object-cover"
                />
                <button
                  onClick={() => fileInputRef.current?.click()}
                  disabled={uploadingPhoto}
                  className="absolute bottom-0 right-0 p-2 bg-white rounded-full shadow-lg hover:bg-gray-100 transition-colors disabled:opacity-50"
                  title="Change profile photo"
                >
                  {uploadingPhoto ? (
                    <Loader2 className="h-5 w-5 animate-spin text-blue-500" />
                  ) : (
                    <Camera className="h-5 w-5 text-blue-500" />
                  )}
                </button>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  onChange={handlePhotoSelect}
                  className="hidden"
                />
              </div>
            </div>
          </div>

          {/* Account Info Section */}
          <div>
            <h2 className="text-lg font-semibold mb-4">Account Information</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Name</label>
                <input
                  type="text"
                  value={user?.displayName || ''}
                  disabled
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md bg-gray-100 text-gray-600"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  value={user?.email || ''}
                  disabled
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md bg-gray-100 text-gray-600"
                />
              </div>
            </div>
          </div>

          <div className="border-t border-gray-200 pt-6">
            <Link
              to="/what-is-poosting"
              className="block w-full px-6 py-3 bg-black text-white font-bold rounded-lg hover:bg-gray-900 transition-colors text-center mb-4"
            >
              What Is Poosting?
            </Link>

            <button
              onClick={openChatSupport}
              className="block w-full px-6 py-3 bg-blue-500 text-white font-bold rounded-lg hover:bg-blue-600 transition-colors text-center mb-6"
            >
              Chat Support
            </button>

            <p className="text-red-500 text-sm text-center">
              Your account is unique and non-transferable. We want a secure social network. We do not allow password, email and username changes.
            </p>
          </div>

          <div className="border-t border-gray-200 pt-6 px-4">
            <p className="text-gray-600 text-sm text-center">
              If you need support or tell us something, please send an email to:{' '}
              <a 
                href="mailto:contact@poosting.com" 
                className="text-blue-500 hover:text-blue-600"
              >
                contact@poosting.com
              </a>
            </p>
          </div>

          <div className="border-t border-gray-200 pt-6 px-4">
            <div className="flex flex-col items-center space-y-2">
              <Link 
                to="/cookie-policy"
                className="text-blue-500 hover:text-blue-600 text-sm"
              >
                Cookie Policy
              </Link>
              <Link 
                to="/terms-of-use"
                className="text-blue-500 hover:text-blue-600 text-sm"
              >
                Terms of Use
              </Link>
            </div>
          </div>

          <div className="border-t border-gray-200 pt-6">
            <RecentUsersList />
          </div>
        </div>
      </main>

      {showPhotoEditor && selectedImage && (
        <ImageEditor
          image={selectedImage}
          onSave={handleCroppedImage}
          onCancel={() => {
            setShowPhotoEditor(false);
            setSelectedImage(null);
          }}
          aspectRatio={1}
        />
      )}
    </div>
  );
}