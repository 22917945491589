import { useState, useRef, useEffect } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { MoreHorizontal, Loader2 } from 'lucide-react';
import { Link } from 'react-router-dom';
import { auth, db } from '../lib/firebase';
import { doc, deleteDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { usePhoto } from '../contexts/PhotoContext';
import { VerificationBadge } from './VerificationBadge';
import toast from 'react-hot-toast';

interface CommentProps {
  id: string;
  postId: string;
  content: string;
  authorId: string;
  authorName: string;
  authorUsername: string;
  authorPhotoURL?: string;
  timestamp: Date;
  onDelete: () => void;
  allies?: string[];
}

export function Comment({
  id,
  postId,
  content,
  authorId,
  authorName,
  authorUsername,
  authorPhotoURL,
  timestamp,
  onDelete,
  allies = [],
}: CommentProps) {
  const [showMenu, setShowMenu] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const currentUser = auth.currentUser;
  const { userPhoto } = usePhoto();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleDelete = async () => {
    if (!currentUser || currentUser.uid !== authorId || deleting) return;

    try {
      setDeleting(true);

      // Delete the comment
      await deleteDoc(doc(db, 'comments', id));

      // Delete associated notification if it exists
      const notificationsRef = collection(db, 'notifications');
      const q = query(
        notificationsRef,
        where('commentId', '==', id),
        where('type', '==', 'comment')
      );
      
      const notificationDocs = await getDocs(q);
      await Promise.all(
        notificationDocs.docs.map(doc => deleteDoc(doc.ref))
      );

      onDelete();
      setShowMenu(false);
      toast.success('Comment deleted');
    } catch (error) {
      console.error('Error deleting comment:', error);
      toast.error('Failed to delete comment');
    } finally {
      setDeleting(false);
    }
  };

  // Use userPhoto if the comment is from the current user
  const displayPhoto = currentUser?.uid === authorId && userPhoto ? userPhoto : (authorPhotoURL || `https://api.dicebear.com/7.x/avataaars/svg?seed=${authorId}`);

  return (
    <div className="px-4 py-3 bg-white hover:bg-gray-50 transition-colors">
      <div className="flex items-start space-x-3">
        <Link to={`/profile/${authorUsername}`}>
          <img
            src={displayPhoto}
            alt={authorName}
            className="h-8 w-8 rounded-full"
          />
        </Link>
        <div className="flex-1 min-w-0">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <Link 
                to={`/profile/${authorUsername}`}
                className="font-medium text-sm hover:underline"
              >
                {authorName}
              </Link>
              <VerificationBadge alliesCount={allies.length} />
              <span className="text-gray-500 text-sm">·</span>
              <span className="text-gray-500 text-sm">
                {formatDistanceToNow(timestamp, { addSuffix: true })}
              </span>
            </div>
            {currentUser?.uid === authorId && (
              <div className="relative" ref={menuRef}>
                <button
                  onClick={() => setShowMenu(!showMenu)}
                  className="p-1 hover:bg-gray-100 rounded-full transition-colors"
                  disabled={deleting}
                >
                  {deleting ? (
                    <Loader2 className="h-4 w-4 animate-spin text-gray-500" />
                  ) : (
                    <MoreHorizontal className="h-4 w-4 text-gray-500" />
                  )}
                </button>
                {showMenu && (
                  <div className="absolute right-0 mt-1 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                    <button
                      onClick={handleDelete}
                      disabled={deleting}
                      className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-50 disabled:opacity-50"
                    >
                      Delete comment
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          <p className="text-sm text-gray-900 break-words whitespace-pre-wrap">
            {content}
          </p>
        </div>
      </div>
    </div>
  );
}